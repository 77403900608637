import React, {Fragment, useState, useEffect, useRef} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Select, Alert, Upload, AutoComplete, Spin, Divider, Radio, Image } from 'antd';
import 'antd/dist/antd.css';
import './RebateRegistrationContent.css';
import { BsCaretDownFill } from "react-icons/bs";
import {Icon, InboxOutlined} from '@ant-design/icons';
import axios from 'axios';
import PopupForm from './PopupForm';
import LoadingOverlay from 'react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader';
import { useHistory } from 'react-router-dom';
import { css } from "@emotion/react";
import ConfirmRebateRegistrationContent from './ConfirmRebateRegistrationContent';
import Collapsible from 'react-collapsible';

const { Option } = Select;
const { Dragger } = Upload;

const layout = {
    labelCol: {
        span: 24,
        },
        wrapperCol: {
        span: 24,
        },
    };

  const tailLayout = {
    wrapperCol: {
      span: 16,
      offset: 11,
    },
  };

const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const RebateRegistrationContent = () => {

    const [form] = Form.useForm();
    const history = useHistory();
    const myRef = useRef(null)
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
    const executeScroll = () => scrollToRef(myRef)
    const [section1, setSection1] = useState(true);
    const [section2, setSection2] = useState(false);
    const [section3, setSection3] = useState(false);
    const [section4, setSection4] = useState(false);
    const [section7, setSection7] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Next');
    const [buttonDisable, setButtonDisable] = useState(false);
    const [token, setToken] = useState('');
    const [moduleType, setModuleType] = useState('');
    const [state, setState] = useState('');
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [purchaseRebateAmt, setPurchaseRebateAmt] = useState(0);
    const [leaseRebateAmt, setLeaseRebateAmt] = useState(0);
    const [loanRebateAmt, setLoanRebateAmt] = useState(0);
    const [dealerCode, setDealerCode] = useState('');
    const [promoValue, setPromoValue] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalOkText, setModalOkText] = useState('');
    const [modalFieldValues, setModalFieldValues] = useState({empfname: false, emplname: false, empemail: false, empphone: false,
        milesId: false, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '',
        spwrExcepAmountFlag: false
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [systemSelection, setSystemSelection] = useState('');
    const [moduleFlag, setModuleFlag] = useState(true);
    const [showConfirmPage, setShowConfirmPage] = useState(false);
    const [options, setOptions] = useState([]);
    const [addressOptions, setAddressOptions] = useState([]);
    const employeeInfo = ['friends & family lease', 'friends & family purchase', 'spwr friends & family', 'sunpower friends & family'];
    const airlinesList = ['alaska air', 'hawaiian airlines miles', 'hawaiian airlines'];
    const promoArr = ['springsix', 'DRTV 6 Months', 'Web 6 Months'];
    const promoTitle = {'springsix': 'Spring Six Promotion', 'DRTV 6 Months': 'DRTV 6 Months', 'Web 6 Months': 'Web 6 Months'};
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [spinnerActive2, setSpinnerActive2] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading your content...');
    const [loadingText2, setLoadingText2] = useState('Submitting your Rebate code details...');
    const [cardType, setCardType] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [formValues, setFormValues] = useState({
        fname: '', lname: '', address1: '', address2: '', city: '', state: '', zip: '', email: '', phone: '', mailingaddress1: '',
        mailingaddress2: '', mailingcity: '', mailingstate: '', mailingzip: '', invdate: '', contractDate: '', module: '', system: '',
        saleoption: '', how: '', have: '', where: '', refFname: '', refLname: '', refEmail: '', refPhone: '', refAddress1: '', refAddress2: '',
        refCity: '', refState: '', refZip: '', reward: '', dealer: '', milesId: '', promocode: '', empfname: '', emplname: '',
        empemail: '', empphone: '', certification: '', certFname: '', certLname: '', splPromoAmount: '', trackingNo: '', disclaimer_accept: '', 
        uploadFiles: [], dealerCode: '', rebateAmt: 0, userUploadedFiles: {}, dealerName: ''
    });
    const [rebateAmt, setRebateAmt] = useState(0);
    const [rebateCode, setRebateCode] = useState('');
    const [userUploadedFiles, setUserUploadedFiles] = useState({});
    const [rebateSubmitted, setRebateSubmitted] = useState(false);
    const [rebateFileList, setRebateFileList] = useState([]);
    const certoptions = [
        { label: 'System Solar Panels', value: 'System Solar Panels' },
        { label: 'SunVault Battery Storage', value: 'SunVault Battery Storage' },
        { label: 'Solar Panels Plus Battery Storage', value: 'Solar Panels Plus Battery Storage' },
      ];

    const moduleRebateCodeList = ['springsolar23', 'marchmania23'];
    const [validationMessage, setValidationMessage] = useState('');
    const [isApptRebateCode, setIsApptRebateCode] = useState(false);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [highlightFields10, setHighlightFields10] = useState(false);
    const [highlightFields11, setHighlightFields11] = useState(false);
    const [highlightFields12, setHighlightFields12] = useState(false);
    
    const formatDate = () => {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        const hr = d.getHours();
        const min = d.getMinutes();
        const sec = d.getSeconds();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day, hr, min, sec].join('');
    }

    useEffect(() => {
        if (token.length == 0) {
            axios.get(process.env.REACT_APP_BACKEND_URL+'/createJWTToken.php')
            .then(res => {
                //console.log(res.data);
                setToken(res.data);
            })
        }
        
    });

    const onFinish = (values) => {
        if (isApptRebateCode && uploadFiles.length == 0) {
            setValidationMessage('');
            setErrorMessage('You have not uploaded any Copy of Quote for the rebate');
        } else {
            setValidationMessage('');
            //console.log(values);
            if (isApptRebateCode) {
                values['reward'] = cardType;
            }
            values['dealerCode'] = dealerCode;
            values['uploadFiles'] = uploadFiles;
            setFormValues(values);
            validateRebateForRestrictions(values);
        }
        
    };
    
    const handleRebateEdit = () => {
        let rebateFileListCopy = [];
        let ctr = 1;
        uploadFiles.map(item => {
            rebateFileListCopy.push({
                uid: ctr,
                name: userUploadedFiles[item],
                status: 'done',
            });
            ctr++;
        })
        setRebateFileList(rebateFileListCopy);
        setShowConfirmPage(false);
    }

    const handleRebateSubmission = () => {
        if (!rebateSubmitted) {
            setSpinnerActive2(true);
            setLoadingText2('Submitting your Rebate code details...');
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/rebatereservationsave.php`,{formValues}, {
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }})
                .then(response => {
                    setRebateSubmitted(true);
                    //console.log(response.data);
                    setSpinnerActive2(false);
                    setLoadingText2('');
                    //history.push("/rebateregistrationsuccess");
                    history.push({
                        pathname: '/rebateregistrationsuccess',
                        state: { referralMsg: response.data.referralMsg }
                    })
                })
                .catch(error => {
                    console.log(error);
                    //alert(JSON.stringify(error));
                })
        }
    }

    const validateInstallDate = (rule, value, callback) => {
        let row = {};
        row['promocode'] = form.getFieldValue('promoCode');
        row['installDate'] = value;
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validateinstallDate.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            console.log(response.data);
            if (!response.data) {
                console.log('error');
                callback('You can submit a rebate within 290 days of install date only');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validateSunpowerEmail = (rule, value, callback) => {
        let row = {};
        row['promocode'] = form.getFieldValue('promoCode');
        row['email'] = value;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validateSunpowerEmail.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            if (!response.data.status) {
                console.log('error');
                callback(response.data.errorMsg);
                //return Promise.reject('The email needs to end in @sunpower.com');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validateContractDate = (rule, value, callback) => {
        let row = {};
        row['promocode'] = form.getFieldValue('promoCode');
        row['contractDate'] = value;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validatecontractdate.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            console.log(response.data);
            if (!response.data) {
                console.log('error');
                callback('Rebate code is invalid based on contract signed date. Contact the dealer who sold/leased you your system.');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validatePromo = (rule, value, callback) => {
        let row = {};
        row['promocode'] = value;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validatepromo.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            console.log(response.data);
            if (!response.data) {
                console.log('error');
                callback('Please enter a valid Promotional Code');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validateDealer = (rule, value, callback) => {
        if (dealerCode.length > 1) {
            let row = {};
            row['dealercode'] = dealerCode;
            
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/validatedealer.php`,{row}, {
                headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(response => {
                console.log(response.data);
                if (!response.data) {
                    console.log('error');
                    callback('Please enter a valid Dealer Name');
                }
                return callback();
            })
            .catch(error => {
                console.log(error);
                //alert(JSON.stringify(error));
            })
        } else {
            return callback('Please enter a valid Dealer Name');
        }
        
    }

    const onSearch = val => {
        if (val && val.length > 4) {
            axios.get(process.env.REACT_APP_BACKEND_URL+'/dealerlist.php')
            .then(res => {
                let dataSource = res.data;
                //console.log(dataSource);
                let filtered = dataSource.filter(
                    obj =>
                      obj.key !== 0 &&
                      obj.value
                        .toString()
                        .toLowerCase()
                        .includes(val.toLowerCase())
                  );
                setOptions(filtered);
            })
        }
    };

    const onSubjectChange = (value) => {
        //console.log(e.target.value);
        console.log(value);
    }

    const onStateChange = (value) => {
        setState(value);
    }

    const onModuleChange = (value) => {
        setModuleType(value);
    }

    const onSelect = (val, option) => {
        console.log(val)
        console.log(option);
        setDealerCode(option.key);
        form.setFieldsValue({dealerName: option.value});
        let formValuesCopy = formValues;
        formValuesCopy['dealerName'] = option.value;
        setFormValues(formValuesCopy);
    };

    const handleEnter = (e, sectionNo) => {
        if (e.keyCode == 13) {
            e.preventDefault();
            switch(sectionNo) {
                case 2:
                    setSection2(true);
                    break;
                case 3:
                    setSection3(true);
                    break;
                case 4:
                    setSection4(true);
                    break;
                default:
                    break;
            }
        }
    }

    const validateRebateForRestrictions = (values) => {
        //console.log(values);
        values['reward'] = cardType;
        setSpinnerActive(true);
        setLoadingText('Validating Rebate code details...');
        let row = {};
        row['promocode'] = promoValue
        row['dealer'] = dealerCode;
        row['address1'] = form.getFieldValue('address1');
        row['city'] = form.getFieldValue('city');
        row['state'] = form.getFieldValue('state');
        row['zip'] = form.getFieldValue('zip');
        row['mailingaddress1'] = form.getFieldValue('address1');
        row['mailingcity'] = form.getFieldValue('city');
        row['mailingstate'] = form.getFieldValue('state');
        row['mailingzip'] = form.getFieldValue('zip');
        row['promocode'] = promoValue;
        row['systemSize'] = form.getFieldValue('systemSize');
        row['contractDate'] = form.getFieldValue('contractDate');
        row['trackingNo'] = 0;
        const path = (isApptRebateCode) ? 'validateApptRebateSmarty' : 'validateRebateSmarty';
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/${path}`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            console.log(response.data);
            if (response.data.error) {
                /*if (response.data.errorMsg == 'This address and dealer combination has already submitted the number of rebates allowed.') {
                    setErrorMessage('This address and dealer combination has already been submitted for a rebate registration.');
                } else {
                    setErrorMessage(response.data.errorMsg);
                }*/
                setErrorMessage(response.data.errorMsg);
                
                setSpinnerActive(false);
                setLoadingText('');
            } else {
                //console.log(values);
                //const formValues = {certification_confirm: 'SunPower Admin', showPhysical: true};
                let formValuesCopy = formValues;
                console.log(formValues);
                const module = (values['moduleType']) ? values['moduleType'] : '';
                const certFname = (values['certFname']) ? values['certFname'] : '';
                const certLname = (values['certLname']) ? values['certLname'] : '';
                
                let formValueDup = formValues;
                formValuesCopy = {fname: values['fname'], lname: values['lname'], address1: values['address1'], address2: values['address2'], city: values['city'], 
                state: values['state'], zip: values['zip'], email: values['email'], phone: values['phone'], 
                invdate: values['installDate'], contractDate: values['contractDate'], module, system: values['systemSize'],
                dealer: dealerCode, promocode: promoValue, trackingNo: 0, dealerName: formValueDup['dealerName'],
                splPromoAmount: formValueDup['splPromoAmount'], milesId: formValueDup['milesId'], empfname: formValueDup['empfname'],
                emplname: formValueDup['emplname'], empemail: formValueDup['empemail'], empphone: formValueDup['empphone'], 
                spwrExcepAmount: formValueDup['spwrExcepAmount'], disclaimer_accept: formValueDup['disclaimer_accept'], systemSelection: systemSelection,
                saleoption: values['saleoption'], rebateAmt, userUploadedFiles, reward: cardType
                };
                setFormValues(formValuesCopy);
                setSpinnerActive(false);
                setLoadingText('');
                setErrorMessage('');
                setShowConfirmPage(true);
                setValidationMessage('');
                executeScroll();
                
            }
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const onSelectAddress = (val, option) => {
        //console.log(val)
        //console.log(option);
        const addressParts = val.split(",");
        form.setFieldsValue({
            address1: addressParts[0],
        });
        form.setFieldsValue({
            city: addressParts[1],
        });
        form.setFieldsValue({
            state: addressParts[2],
        });
        form.setFieldsValue({
            zip: addressParts[3],
        });
        
    }

    const onSearchAddress = val => {
        if (val && val.length > 2) {
            setAutoCompleteLoading(true);
            console.log(rebateCode);
            axios.get(process.env.REACT_APP_BACKEND_URL+"/api/addressautocompletesmarty/"+encodeURI(window.btoa(val))+"/"+rebateCode, {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                }})
            .then(response => {
                setAutoCompleteLoading(false);
                let dataSource = response.data;
                console.log(dataSource);
                let filtered = dataSource.filter(
                    obj =>
                      obj.value
                        .toString()
                        .toLowerCase()
                        .includes(val.toLowerCase())
                  );
                setAddressOptions(filtered);
                //console.log(response);
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        setToken('');
                    }
                  }
            });
        }
    }

    const isInArray = (value, array) => {
        return array.indexOf(value) > -1;
    }

    const handleRebateCode = (e) => {
        if (e.target && e.target.value && e.target.value.trim().toLowerCase() == 'summerloan22') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        if (e.target && e.target.value && e.target.value.trim().toLowerCase() == '75appt') {
            setIsApptRebateCode(true);
        } else {
            setIsApptRebateCode(false);
        }
        
        setRebateCode(e.target.value.trim());
        setPromoValue(e.target.value.trim());
        setSpinnerActive(true);
        setLoadingText('Retrieving your Rebate code details...');
        let row = {};
        row['promo'] = e.target.value.trim()
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/promodisclaimer.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setSpinnerActive(false);
            setLoadingText('');
            //console.log(response.data);
            let disclaimer = response;
            let discstr = '';
            //console.log(disclaimer);
            if (disclaimer.data.present == 1) {
                
                disclaimer.data.data.forEach(function (item, index) {
                    //console.log(item);
                    //console.log(index);
                    item = item.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    item = item.replace(/^["'](.+(?=["']$))["']$/, '$1');
                    discstr = (discstr.length > 0 ) ? discstr + '<p>'+item+'</p>' : '<p>'+item+'</p>';
                });
                //console.log(discstr);
                if (!isInArray(e.target.value.trim(), promoArr) && e.target.value.trim().toLowerCase() != 'springsix') {
                    setModalTitle(promoTitle[e.target.value.trim()]);
                    setModalOkText('Save changes');
                    
                    setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                        milesId: false, monthlyPaymentFlag: false, promoDisclaimer: true, disclaimer: discstr, 
                        spwrExcepAmountFlag: false
                    });
                    setModalVisible(true);
                } 
            }
            //console.log(discstr);
            if (isInArray(e.target.value.trim(), promoArr) || e.target.value.trim().toLowerCase() == 'springsix') {
                //if (e.target.value.toLowerCase() == 'springsix' || e.target.value.toLowerCase() == 'drtv6months') {
                setModalTitle(promoTitle[e.target.value.trim()]);
                setModalOkText('Save changes');
                setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                    milesId: false, monthlyPaymentFlag: true, promoDisclaimer: true, disclaimer: discstr, 
                    spwrExcepAmountFlag: false
                });
                setModalVisible(true);
            }
            if (airlinesList.indexOf(e.target.value.toLowerCase()) > -1) {
                setModalTitle('Please give your miles Id');
                setModalOkText('Save changes');
                setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                    milesId: true, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '', 
                    spwrExcepAmountFlag: false
                });
                setModalVisible(true);
            }
            if (employeeInfo.indexOf(e.target.value.trim().toLowerCase()) > -1) {
                setModalTitle('SunPower Employee Information');
                setModalOkText('Save changes');
                setModalFieldValues({empfname: true, emplname: true, empemail: true, empphone: true,
                    milesId: false, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '', 
                    spwrExcepAmountFlag: false
                });
                setModalVisible(true);
            }
            if (e.target.value.trim().toLowerCase() == 'spwr admin exception') {
                setModalTitle('SPWR Admin Exception');
                setModalOkText('Save changes');
                setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                    milesId: false, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '', 
                    spwrExcepAmountFlag: true
                });
                setModalVisible(true);
            }
                
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
        if (moduleRebateCodeList.includes(e.target.value.trim().toLowerCase())) {
            setModuleFlag(true);
        } else {
            setModuleFlag(false);
        }
        //e.stopPropagation();
        //console.log(e.target.value);
        
        /*if (!isInArray(e.target.value.trim(), promoArr) && e.target.value.trim().toLowerCase() != 'springsix' && e.target.value.trim().toLowerCase() != 'spwr admin exception') {
            //if (e.target.value.toLowerCase() != 'springsix' && e.target.value.toLowerCase() != 'drtv6months') {
                $.post("promo.php", data, function(result){
                    var promoval = JSON.parse(result);
                    purchaseAmount = promoval.purchaseAmount;
                    leaseAmount = promoval.leaseAmount;
                    loanAmount = promoval.loanAmount;
                    
                    
                });
        } */
    
    }

    const handleModalPopUp = (values, disclaimerCheck) => {
        //console.log(values);
        //console.log(disclaimerCheck)
        setModalVisible(false);
        let formValuesCopy = formValues;
        if (isInArray(promoValue.trim(), promoArr) || promoValue.trim().toLowerCase() == 'springsix') {
            formValuesCopy['splPromoAmount'] = 6*parseFloat(values['monthlyPayment']);
        }
        if (airlinesList.indexOf(promoValue.toLowerCase()) > -1) {
            formValuesCopy['milesId'] = values['milesId'];
        }
        if (employeeInfo.indexOf(promoValue.trim().toLowerCase()) > -1) {
            formValuesCopy['empfname'] = values['empfname'];
            formValuesCopy['emplname'] = values['emplname'];
            formValuesCopy['empemail'] = values['empemail'];
            formValuesCopy['empphone'] = values['empphone'];
        }
        if (promoValue.trim().toLowerCase() == 'spwr admin exception') {
            formValuesCopy['spwrExcepAmount'] = values['spwrExcepAmount'];
        }
        formValuesCopy['disclaimer_accept'] = (disclaimerCheck) ? disclaimerCheck : 0;
        setFormValues(formValuesCopy);
    }

    const onCancel = () => {
        setModalVisible(false);
    }

    const tabToNextSection = (e, funcCall) => {
        funcCall(true);
    }

    const handleOnFinishFailed = ({values, errorFields, outOfDate}) => {
        let reqdFields = [];
        if (isApptRebateCode) {
            reqdFields = [['promoCode'], 
                ['fname', 'lname', 'address1', 'city', 'state', 'zip', 'email', 'phone']
            ];
        } else {
            reqdFields = [['promoCode'], ['dealerName', 'contractDate', 'installDate', 'moduleType', 'systemSize'], 
                ['fname', 'lname', 'address1', 'city', 'state', 'zip', 'email', 'phone']
            ];
        }
        const fieldCollapse = {0 : setSection1, 1: setSection2, 2: setSection3, 
        };
        for (let i = 0; i < reqdFields.length; i++) {
            for (let j = 0; j < reqdFields[i].length; j++) {
                if (reqdFields[i][j] != 'ignore' && !values[reqdFields[i][j]]) {
                    //console.log(i);
                    fieldCollapse[i](true);
                    break;
                }
            }
        }
        setValidationMessage('All the required fields highlighted below need to be filled out.');
        executeScroll();
    }

    const onChangeSystemSelect = (checkedValues) => {
        //console.log('checked = ', checkedValues);
        setSystemSelection(checkedValues.target.value);
        const match = (checkedValues.target.value == 'SunVault Battery Storage') ? false : true;
        if (moduleRebateCodeList.includes(promoValue.trim().toLowerCase()) && match) {
            setModuleFlag(true);
        } else {
            setModuleFlag(false);
        }
        //setModuleFlag(match);
    }

    const addressPasteHandle = (val, type) => {
        //console.log(val);
        //console.log(typeof val);
        if (typeof val == 'string') {
            onSearchAddress(val);
        }
    }

    const getRebateAmountInfo = (data, saleType) => {
        let amt = 0;
        /*if (formValues['splPromoAmount'] != 0) {
            amt = formValues['splPromoAmount'];
        } else if (formValues['spwrExcepAmount'] != 0) {
            amt = formValues['spwrExcepAmount'];
        }*/
        if (amt != 0) {
            setRebateAmt(amt);
            if (saleType == 'Purchased') {
                setPurchaseRebateAmt(amt);
                setLeaseRebateAmt('');
                setLoanRebateAmt('');
            } else if (saleType == 'Leased') {
                setLeaseRebateAmt(amt);
                setPurchaseRebateAmt('');
                setLoanRebateAmt('');
            } else {
                setLoanRebateAmt(amt);
                setPurchaseRebateAmt('');
                setLeaseRebateAmt('');
            }
            
        } else {
            let row = {};
            row['dealercode'] = dealerCode;
            row['address1'] = data;
            row['promocode'] = promoValue
            row['state'] = state;
            row['moduleType'] = moduleType;
            let rebateTrackingNo = 0;
            row['rebateTrackingNo'] = rebateTrackingNo;
            
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getPromoAmounts`,{row}, {
                headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(response => {
                console.log(response.data);
                if (saleType == 'Purchased') {
                    setPurchaseRebateAmt(response.data.purchaseAmount);
                    setLeaseRebateAmt('');
                    setLoanRebateAmt('');
                    setRebateAmt(response.data.purchaseAmount);
                    
                } else if (saleType == 'Leased') {
                    setLeaseRebateAmt(response.data.leaseAmount);
                    setPurchaseRebateAmt('');
                    setLoanRebateAmt('');
                    setRebateAmt(response.data.leaseAmount);
                    
                } else {
                    setLoanRebateAmt(response.data.loanAmount);
                    setPurchaseRebateAmt('');
                    setLeaseRebateAmt('');
                    setRebateAmt(response.data.loanAmount);
                    
                }
            })
            .catch(error => {
                console.log(error);
                //alert(JSON.stringify(error));
            })
        }
        
    }

    const captureSaleType = (e) => {
        //console.log(e.target.value);
        getRebateAmountInfo(form.getFieldValue('address1'), e.target.value);
        
    }

    const validateSaleType =  (rule, value, callback) => {
        const dealerNameValue = form.getFieldValue('dealerName');
        if (!dealerNameValue) {
            //console.log('address1 error');
            callback('Dealership Name must be entered before selecting the Sale Type');
        }
        return callback();
    }

    const handleFileRemove = (file) => {
        //console.log(file.name);
        let uploadFilesCopy = uploadFiles;
        //console.log(uploadFilesCopy);
        const index = uploadFilesCopy.findIndex(element => element.includes(file.name));
        if (index > -1) {
            uploadFilesCopy.splice(index, 1);
        }
        //console.log(uploadFilesCopy);
        setUploadFiles(uploadFilesCopy);
    }

    const propsUpload = {
        multiple: true,
        onRemove: handleFileRemove,
        defaultFileList: rebateFileList,
        listType: "picture",
        customRequest({
          action,
          data,
          file,
          filename,
          headers,
          onError,
          onProgress,
          onSuccess,
          withCredentials
        }) {
          
          setButtonLabel('Please wait..');
          setButtonDisable(true);
          
          let cleanedFileName =  file.name.replace(/[^a-zA-Z0-9\.]/g, '');
          //console.log(cleanedFileName);
          let finalFileName = formatDate()+'_'+cleanedFileName;
          let uploadFilesCopy = uploadFiles;
          uploadFilesCopy.push(finalFileName)
          let userUploadedFilesCopy = userUploadedFiles;
          userUploadedFilesCopy[finalFileName] = file.name;
          
            const formData = new FormData();
            formData.append('finalFileName', finalFileName);
            formData.append('contract', file);
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/contractUploadConsumer`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': "multipart/form-data; charset=utf-8",
                'X-Requested-With': 'XMLHttpRequest'
                //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
            }
            })
            .then(result => {
                console.log('response: ', result);
                if (result) {
                    onSuccess(result.status, file);
                    setUploadFiles(uploadFilesCopy);
                    setButtonLabel('Next');
                    setButtonDisable(false);
                    //tabToNextSection(null, setSection7)
                    setUserUploadedFiles(userUploadedFilesCopy);
                    setHighlightFields10(false);
                    setSection7(true);
                    /*
                    if (highlightFields11 || highlightFields12) {
                        setSection7(true);
                    } else if (highlightFields13) {
                        setSection9(true);
                    }*/
                }
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })
        }
    };

    const captureCardType = (e) => {
        setCardType(e.target.value);
        setHighlightFields11(false);
        setHighlightFields12(false);
    }

    return (
        <>
        {showConfirmPage ? 
            <ConfirmRebateRegistrationContent 
            formValues={formValues} 
            onFinalSubmit={handleRebateSubmission}
            handleEdit={handleRebateEdit}
            myRef={myRef}
            isApptRebateCode={isApptRebateCode}
            uploadFiles={uploadFiles}
            /> 
            : 
        <LoadingOverlay
            active={spinnerActive || spinnerActive2}
            text={loadingText || loadingText2}
            spinner={<ClockLoader color="#ffffff" css={cssoverride} />}
          >
        <Container className="text-start py-5">
            <Row>
                <Col className="text-center">
                    <h4 className="display-6">Rebate Registration</h4>
                    <p>Start the rebate process now, and return when your system has been installed.</p>
                    {
                        errorMessage.length > 0 ?
                        <Alert message={errorMessage} type="error" />
                        : null
                    }
                    <div ref={myRef}>
                    {
                        validationMessage.length > 0 ?
                        <Alert message={validationMessage} type="error" />
                        : null
                    }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} requiredMark={'optional'} onFinishFailed={handleOnFinishFailed}>
                    <Collapsible trigger={[ <BsCaretDownFill />, "  Rebate Information"]}  open={section1} onTriggerClosing={() => {setSection1(false)}}>
                        <Form.Item
                            name="promoCode"
                            label="Rebate Code"
                            tooltip="If you do not know your rebate code contact the dealer who sold/leased you your system"
                            rules={[
                            {
                                required: true,
                                message: 'Rebate code is required'
                            },
                            {
                                validator: validatePromo
                            },
                            ]}
                        >
                            <Input onKeyDown={(e) => handleEnter(e, 2)} onBlur={(e) => {handleRebateCode(e); setSection2(true)}} />
                            
                        </Form.Item>
                        </Collapsible>
                        {!isApptRebateCode ? 
                        <Collapsible trigger={[ <BsCaretDownFill />, '  System Information']}  open={section2} onTriggerClosing={() => {setSection2(false)}}>
                        <Form.Item
                            name="systemSelection"
                            label=""
                            rules={[
                            {
                                required: true,
                                message: 'You need to select one of the options'
                            },
                            ]}
                        >
                            <Radio.Group options={certoptions} onChange={onChangeSystemSelect} />
                        </Form.Item>
                        <Form.Item
                            name="dealerName"
                            label="Dealer Name"
                            rules={[
                            {
                                required: true,
                                message: 'Dealer Name is required'
                            },
                            {
                                validator: validateDealer
                            },
                            ]}
                        >
                            <AutoComplete
                            options={options}
                            onSelect={(val, option) => onSelect(val, option)}
                            onSearch={onSearch}
                            
                        >
                            <Input />
                        </AutoComplete>
                        </Form.Item>
                            <Row>
                                <Col lg={6}>
                                    <Form.Item
                                        name="moduleType"
                                        label="Module Type"
                                        rules={[
                                            {
                                                required: moduleFlag ,
                                            },
                                        ]}
                                        style={moduleFlag  ? { display: "block" } : { display: "none" }}
                                    >
                                        <Select
                                        showSearch
                                        onChange={onModuleChange}
                                        allowClear
                                        optionFilterProp="children"
                                            filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                        <Option value="A-Series">A-Series</Option>
                                        <Option value="X-Series">X-Series</Option>
                                        <Option value="E-Series">E-Series</Option>
                                        <Option value="P-Series">P-Series</Option>
                                        <Option value="M-Series">M-Series</Option>
                                        <Option value="U-Series">U-Series</Option>
                                        <Option value="Other">Other</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        name="systemSize"
                                        label="System Size (Watts)"
                                        rules={[
                                        {
                                            required: true,
                                            message: "System Size is required"
                                        },
                                        ]}
                                    >
                                        <Input onKeyDown={(e) => handleEnter(e, 3)} onBlur={(e) => tabToNextSection(e, setSection3)} />
                                    </Form.Item>
                            </Col>
                        </Row>
                        </Collapsible>
                        : null }
                        <Collapsible trigger={[ <BsCaretDownFill />, '  HomeOwner Information']}  open={section3} onTriggerClosing={() => {setSection3(false)}}>
                        <p className="fs-6 fw-normal">{isApptRebateCode ? `Must be the address for the proposed solar system` : `Must be address solar system was installed`}</p>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="fname"
                                    label="First Name"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'First Name is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="lname"
                                    label="Last Name"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Last Name is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="address1"
                                    label="Address 1"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Address 1 is required'
                                    },
                                    ]}
                                >
                                    <AutoComplete
                                        options={addressOptions}
                                        onSelect={(val, option) => onSelectAddress(val, option)}
                                        onSearch={onSearchAddress}
                                        notFoundContent={autoCompleteLoading ? <Spin /> : 'No matches'}
                                        onChange={(e) => addressPasteHandle(e, 'install')}
                                    >
                                        <Input />
                                    </AutoComplete>
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="address2"
                                    label="Address 2"
                                    rules={[
                                    {
                                        required: false,
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'City is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'State is required'
                                    },
                                    ]}
                                >
                                    <Select
                                    showSearch
                                    onChange={onStateChange}
                                    allowClear
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        <Option value="AL">Alabama</Option>
                                        <Option value="AK">Alaska</Option>
                                        <Option value="AZ">Arizona</Option>
                                        <Option value="AR">Arkansas</Option>
                                        <Option value="CA">California</Option>
                                        <Option value="CO">Colorado</Option>
                                        <Option value="CT">Connecticut</Option>
                                        <Option value="DE">Delaware</Option>
                                        <Option value="DC">District of Columbia</Option>
                                        <Option value="FL">Florida</Option>
                                        <Option value="GA">Georgia</Option>
                                        <Option value="HI">Hawaii</Option>
                                        <Option value="ID">Idaho</Option>
                                        <Option value="IL">Illinois</Option>
                                        <Option value="IN">Indiana</Option>
                                        <Option value="IA">Iowa</Option>
                                        <Option value="KS">Kansas</Option>
                                        <Option value="KY">Kentucky</Option>
                                        <Option value="LA">Louisiana</Option>
                                        <Option value="ME">Maine</Option>
                                        <Option value="MD">Maryland</Option>
                                        <Option value="MA">Massachusetts</Option>
                                        <Option value="MI">Michigan</Option>
                                        <Option value="MN">Minnesota</Option>
                                        <Option value="MS">Mississippi</Option>
                                        <Option value="MO">Missouri</Option>
                                        <Option value="MT">Montana</Option>
                                        <Option value="NE">Nebaraska</Option>
                                        <Option value="NV">Nevada</Option>
                                        <Option value="NH">New Hampshire</Option>
                                        <Option value="NJ">New Jersey</Option>
                                        <Option value="NM">New Mexico</Option>
                                        <Option value="NY">New York</Option>
                                        <Option value="NC">North Carolina</Option>
                                        <Option value="ND">North Dakota</Option>
                                        <Option value="OH">Ohio</Option>
                                        <Option value="OK">Oklahoma</Option>
                                        <Option value="OR">Oregon</Option>
                                        <Option value="PA">Pennsylvania</Option>
                                        <Option value="RI">Rhode Island</Option>
                                        <Option value="SC">South Carolina</Option>
                                        <Option value="SD">South Dakota</Option>
                                        <Option value="TN">Tennessee</Option>
                                        <Option value="TX">Texas</Option>
                                        <Option value="UT">Utah</Option>
                                        <Option value="VT">Vermont</Option>
                                        <Option value="VA">Virginia</Option>
                                        <Option value="WA">Washington</Option>
                                        <Option value="WV">West Virginia</Option>
                                        <Option value="WI">Wisconsin</Option>
                                        <Option value="WY">Wyoming</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="zip"
                                    label="Zip"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Zip is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: "Email is required" },
                                        {
                                            pattern: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}\b$/i,
                                            message: "Email is not valid"
                                        },
                                        {
                                            validator: validateSunpowerEmail
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[
                                    {
                                        required: true,
                                        min: 10,
                                        max: 15,
                                        message: 'Phone is required'
                                    },
                                    ]}
                                >
                                    <Input onKeyDown={(e) => handleEnter(e, 4)} onBlur={(e) => tabToNextSection(e, setSection4)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        </Collapsible>
                        {!isApptRebateCode ? 
                        <Collapsible trigger={[ <BsCaretDownFill />, '  This System was']}  open={section4} onTriggerClosing={() => {setSection4(false)}}>
                            <Form.Item name="saleoption" label=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'System sale type is required'
                                    },
                                    {
                                        validator: validateSaleType
                                    },
                                    ]}>
                                <Radio.Group
                                    size="large"
                                    buttonStyle="solid"
                                    className="ant-row ant-row-space-between"
                                    onChange={captureSaleType}
                                >
                                    <Radio.Button value="Purchased" style={disabled ? { display: 'none'} : {display: 'block'}} className="text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0">
                                            <span>
                                                <h5 className="border-bottom py-4">Purchased <span className="float-end">${purchaseRebateAmt}</span></h5>
                                                <p className="mb-0 lh-base fw-normal">Documents required for verification:</p>
                                            <ul className="border-bottom pb-4 lh-sm fw-light">
                                                <li>
                                                    Dealer Installation Agreement/ Contract
                                                </li>
                                                <li>
                                                    Invoice not accepted
                                                </li>
                                            </ul>
                                            <p className="mb-0 fw-normal">Sample Documents</p>
                                            <Image.PreviewGroup>
                                                <Image width={100} src="/images/Purchase Agreement Pg 1.png" className="sample-img" />
                                                <Image width={100} src="/images/Purchase Agreement Pg 2.png" className="sample-img" />
                                            </Image.PreviewGroup>
                                            </span>
                                    </Radio.Button>
                                    <Radio.Button value="Leased" style={disabled ? { display: 'none'} : {display: 'block'}} className="text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0">
                                            <span>
                                                <h5 className="border-bottom py-4">SunPower Lease <span className="float-end">${leaseRebateAmt}</span></h5>
                                                <p className="mb-0 fw-normal lh-base">Documents required for verification:</p>
                                            <ul className="border-bottom pb-4 lh-sm fw-light">
                                                <li>
                                                    Page 1 of lease agreement
                                                </li>
                                                <li>
                                                    Exhibit D of lease agreement
                                                </li>
                                            </ul>
                                            <p className="mb-0 fw-normal">Sample Documents</p>
                                            <Image.PreviewGroup>
                                                <Image width={100} src="/images/Lease Page 1.png" className="sample-img" />
                                                <Image width={100} src="/images/Lease, Exhibit D.png" className="sample-img" />
                                            </Image.PreviewGroup>
                                            </span>
                                    </Radio.Button>
                                    <Radio.Button value="Loaned" className="text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0">
                                            <span>
                                                <h5 className="border-bottom py-4">SunPower Loan <span className="float-end">${loanRebateAmt}</span></h5>
                                                <p className="mb-0 lh-base fw-normal">Documents required for verification:</p>
                                            <ul className="border-bottom pb-4 lh-sm fw-light">
                                                <li>
                                                Page 1, 2 & customer signature page of Solar Energy System Home Improvement Contract
                                                </li>
                                            </ul>
                                            <p className="mb-0 fw-normal">Sample Documents</p>
                                            <Image.PreviewGroup>
                                                <Image width={100} src="/images/Loan PNG 1.png" className="sample-img" />
                                                <Image width={100} src="/images/Loan PNG 2.png" className="sample-img" />
                                            </Image.PreviewGroup>
                                            </span>
                                    </Radio.Button>                            
                                </Radio.Group>
                            </Form.Item>
                        </Collapsible>
                        : 
                        <Collapsible trigger={[ <BsCaretDownFill />, '  Upload Copy of Quote']}  open={section4} onTriggerClosing={() => {setSection4(false)}}>
                            <p className="fs-6 fw-normal">Acceptable image formats are: jpg, png, pdf. Size must be less than 10MB. If you are unable to upload your Copy of Quote, <a href="/contactus"  target="_blank" className="blue-link">Contact Us</a> for assistance.</p>
                            <Dragger {...propsUpload}>
                                
                                    <p className="ant-upload-drag-icon" >
                                    <InboxOutlined />
                                    </p>
                                    <p className={highlightFields10 ? 'highlight-class ant-upload-text' : 'ant-upload-text'} >Click or drag Copy of Quote to this area to upload</p>
                            </Dragger>
                        </Collapsible>
                        }
                        {isApptRebateCode ? 
                        <Collapsible trigger={[ <BsCaretDownFill />, '  Select your Payment Method']}  open={section7} onTriggerClosing={() => {setSection7(false)}}>
                            <Form.Item name="reward" label=""
                                rules={[
                                    {
                                        required: isApptRebateCode,
                                        message: 'Card selection is required'
                                    },
                                    
                                    ]}>
                                
                                    
                                        <Radio.Group
                                        size="large"
                                        buttonStyle="solid"
                                        className="ant-row ant-row-space-between"
                                        onChange={captureCardType}
                                        >
                                            <Radio.Button value="visareward" className={highlightFields12 ? "highlight-class text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4" : "text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4"}>
                                                <span className="ant-row ant-row-space-between">
                                                    <span className="ant-col ant-col-17">
                                                        <h5>SunPower Visa Rewards Card**</h5>
                                                        <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                                <li>
                                                                    Receive a physical card by mail
                                                                </li>
                                                            </ul>
                                                            </span>
                                                            <span className="ant-col ant-col-6">
                                                        <img src="/images/SP-CardRedesignG.png" className="img-fluid" />
                                                    </span>
                                                </span>
                                            </Radio.Button>
                                            
                                            <Radio.Button value="virtualvisa" className={highlightFields11 ? "highlight-class text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4" : "text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4"}>
                                                <span className="ant-row ant-row-space-between">
                                                    <span className="ant-col ant-col-17">
                                                        <h5>SunPower Virtual Visa Rewards Card**</h5>
                                                            <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                                <li>
                                                                    Receive e-code by email
                                                                </li>
                                                                <li>
                                                                    For online purchases only
                                                                </li>
                                                            </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                            <img src="/images/SP-CardRedesignB.png" className="img-fluid" />
                                                    </span>
                                                </span>
                                            </Radio.Button>
                                               
                                            
                                            
                                        </Radio.Group>
                                    
                            </Form.Item>
                        </Collapsible>
                        : null }
                <Row>
                    <Col className="p-4">
                        <p>Have questions about this rebate form? Check out the <a href="/faq" target="_blank"  className="blue-link">FAQs</a>, or <a href="/contactus" target="_blank" className="blue-link">Contact Us</a> for assistance.</p>

                        <p><span className="fw-bold">Note:</span> This rebate cannot be combined with other SunPower offers.</p>

                        <p><span className="fw-bold">Eligibility Requirements:</span> Must own your home, have your system installed, and submit this Rebate Form according to Rebate Terms below.</p>

                        <p><span className="fw-bold">* Rebate Terms:</span> Before rebate costs will vary, depending on system specifications. Check with your SunPower installation contractor to confirm participation with this offer. Only available to customers who purchase a new, complete SunPower system, excluding customers who purchase a new home with existing solar installed. Rebate may not be applied to quotes on existing SunPower proposals or past purchases. Rebate form must be completed and submitted to SunPower within 90 days of the installation date, along with required documents. Allow 8-10 weeks for processing. Late submissions and those submitted without proper documentation and signatures will be subject to delay or cancellation. Void where prohibited.</p>

                        <p><span className="fw-bold">** Reward Card:</span> Card/Virtual card is issued by Pathward, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Card can be used everywhere Visa debit cards are accepted. Virtual card can be used everywhere Visa debit cards are accepted online, or phone/mail orders. Card/Virtual card valid for up to 6 months; unused funds will forfeit after the valid thru date. Go to <a href="https://bpscardaccount.com" target="_blank" className="blue-link">https://bpscardaccount.com</a> to activate your card and to check your card balance. For lost or stolen cards, please contact VISA customer support at 1-866-849-4838. Other Terms and conditions apply. </p>
                    </Col>
                </Row>
                    <Divider />
                    <Row>
                        <Col className="p-4">
                            <p>By clicking "SUBMIT," I consent to SunPower contacting me to provide information on my SunPower rebate. SunPower can call or text me (including SMS or MMS) (including via prerecorded messages and/or automated technology belonging to or hosted by third parties) at the telephone number I provided above even if it is on a state or Federal Do Not Call List. My consent is not a condition of purchase. Please see our <a href="https://global.sunpower.com/privacy-policy" target="_blank" className="blue-link">Privacy Statement</a> and <a href="https://global.sunpower.com/sunpower-terms-use-agreement" target="_blank" className="blue-link">Terms of Use</a>.</p>
                        </Col>
                    </Row>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" className="btn-orange mt-5">
                            Next
                            </Button>
                        </Form.Item>
                    </Form>
                    {modalVisible ? 
                    <PopupForm
                    visible={true}
                    onSaveResend={handleModalPopUp}
                    onCancel={onCancel}
                    fieldValues={modalFieldValues}
                    title={modalTitle}
                    okText={modalOkText}
                    /> : null
                }
                </Col>
            </Row>
        </Container>
        </LoadingOverlay>
        }
        </>
    );
}

export default RebateRegistrationContent;