import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container} from 'react-bootstrap';
import { Collapse, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import './FAQContent.css';
import { useHistory } from 'react-router-dom';
  
const FAQContent = () => {

    const { Panel } = Collapse;
    const history = useHistory();

    const onCheckClick = () => {
        history.push({
            pathname: '/startrebate',
            state: { cardType: 'check' }
          })
    }

    return (
        <Container className="text-start py-5">
            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
                className="site-collapse-custom-collapse bg-white"
            >
                <Panel header="How do I claim my rebate?" key="1" className="site-collapse-custom-panel">
                <p>Please <a href="/startrebate" className="blue-link">click here</a> to submit your rebate.</p>
                </Panel>
                <Panel header="How do I submit my rebate if I do not have a copy of my Signed Contract?" key="2" className="site-collapse-custom-panel">
                <p>Please contact your installing SunPower Dealer to get a copy of your Signed Contract.</p>
                </Panel>
                <Panel header="I can't upload my signed contract. How can I submit my rebate?" key="3" className="site-collapse-custom-panel">
                <p>Please contact <a href="/contactus" className="blue-link">Customer Service</a> for assistance.</p>
                </Panel>
                <Panel header="I forgot to submit my rebate and now it's expired. Can I still get a reward or is it too late?" key="4" className="site-collapse-custom-panel">
                <p>Please contact <a href="/contactus" className="blue-link">Customer Service</a> to file your extension with SunPower.</p>
                </Panel>
                <Panel header="How do I check the status of my rebate?" key="5" className="site-collapse-custom-panel">
                <p>You can check the status of your rebate's approval process anytime on the <a href="/trackrebate" className="blue-link">Track My Rebate</a> link within this website by entering your rebate status tracking number.</p>
                </Panel>
                <Panel header="How long will it take to get my reward?" key="6" className="site-collapse-custom-panel">
                <p>Please allow up to 8-10 weeks from the date your rebate was approved to receive your SunPower Rebate.</p>
                </Panel>
                <Panel header="How do I check my available balance on my SunPower Prepaid Visa Reward Card?" key="7" className="site-collapse-custom-panel">
                <p>Please visit the <a href="/myvisacard" className="blue-link">My Visa Card</a> link within this website to view your account balance.</p>
                </Panel>
                <Panel header="How do I submit my rebate for a check?" key="8" className="site-collapse-custom-panel">
                <p>Please <a href="#" onClick={onCheckClick} className="blue-link">click here</a> to submit your check rebate.</p>
                </Panel>
                <Panel header="When does my card expire?" key="9" className="site-collapse-custom-panel">
                <p>Customers' cards will expire after midnight EST on the last day of the month of the valid thru date printed on the front of the card. Six months from the date of issuance. After card expiration, if you have not activated we can reissue a new card. Cards that have been activated may not be reissued.</p>
                </Panel>
                <Panel header="How do I find out my rebate code?" key="10" className="site-collapse-custom-panel">
                <p>Your dealer whom you signed your contract with can provide your rebate code.</p>
                </Panel>
                <Panel header="How do I find my Rebate Status Tracking Number?" key="11" className="site-collapse-custom-panel">
                <p>Please contact <a href="/contactus" className="blue-link">Customer Service</a> directly. They will be able to provide you with your Rebate Status Tracking Number for your SunPower Rebate submission.</p>
                </Panel>
                
            </Collapse>
        </Container>
    );
}

export default FAQContent;