//import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Select, Radio, Image, Checkbox, DatePicker, message, Divider } from 'antd';
import { PaperClipOutlined  } from '@ant-design/icons';
import LoadingOverlay from 'react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader';
import 'antd/dist/antd.css';
import './ConfirmRebateContent.css';
import { css } from "@emotion/react";

const { Option } = Select;

const layout = {
    labelCol: {
        span: 24,
        },
        wrapperCol: {
        span: 24,
        },
    };

  const tailLayout = {
    wrapperCol: {
      span: 16,
      offset: 11,
    },
  };

  const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;
  
const ConfirmRebateContent = ({formValues, onFinalSubmit, handleEdit, uploadFiles, myRef, confirmSpinnerActive, confirmLoadingText}) => {

    const [form] = Form.useForm();
    const [moduleFlag, setModuleFlag] = useState(true);
    //console.log(formValues);
    //console.log(uploadFiles);
    useEffect(() => {
        for (const key in formValues) {
            form.setFieldsValue({
                [key]: formValues[key],
            });
        }
        const match = (formValues['systemSelection'] == 'SunVault Battery Storage') ? false : true;
        setModuleFlag(match);
        //console.log(formValues.empfname);
    })

    const onFinishConfirm = (values) => {
        //console.log(values);
      };

    const certoptions = [
        { label: 'System Solar Panels', value: 'System Solar Panels' },
        { label: 'SunVault Battery Storage', value: 'SunVault Battery Storage' },
        { label: 'Solar Panels Plus Battery Storage', value: 'Solar Panels Plus Battery Storage' },
      ];

    return (
        <LoadingOverlay
        active={confirmSpinnerActive}
        text={confirmLoadingText}
        spinner={<ClockLoader color="#ffffff" css={cssoverride} />}
      >
        <Container className="text-start py-5" ref={myRef}>
            <Row>
                <Col className="text-center">
                    <h4 className="display-6">Confirm and submit</h4>
                    <p>Review the form and submit your rebate.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinalSubmit} requiredMark={'optional'}>
                        <h5>Rebate Information</h5>
                        <Form.Item
                            name="promocode"
                            label="Rebate Code"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                        <h5>System Information</h5>
                        <Form.Item
                            name="systemSelection"
                            label=""
                            rules={[
                            {
                                required: true,
                                message: 'You need to select one of the options'
                            },
                            ]}
                        >
                            <Radio.Group options={certoptions} disabled />
                        </Form.Item>
                        <Form.Item
                            name="dealerName"
                            label="Dealer Name"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                            <Row className="finish-form">
                                <Col lg={6}>
                                    <Form.Item 
                                        name="contractDate"
                                        label="Contract Signed Date"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                        >
                                        <DatePicker placeholder="" className="input-w-100" disabled />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item 
                                        name="invdate"
                                        label="Install Date"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                        >
                                        <DatePicker placeholder="" className="input-w-100" disabled />
                                    </Form.Item>
                            </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Item
                                        name="module"
                                        label="Module Type"
                                        rules={[
                                        {
                                            required: false,
                                        },
                                        ]}
                                        style={moduleFlag  ? { display: "block" } : { display: "none" }}
                                    >
                                        <Select
                                        showSearch
                                        allowClear
                                        disabled
                                        optionFilterProp="children"
                                            filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                        <Option value="A-Series">A-Series</Option>
                                        <Option value="X-Series">X-Series</Option>
                                        <Option value="E-Series">E-Series</Option>
                                        <Option value="P-Series">P-Series</Option>
                                        <Option value="M-Series">M-Series</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        name="system"
                                        label="System Size (Watts)"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        {formValues.empfname && formValues.empfname.length > 0 ?
                            <div>
                            <h5>SunPower Employee Information</h5>
                            <Row>
                                <Col lg={6}>
                                    <Form.Item
                                        name="empfname"
                                        label="Sunpower Employee First Name"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        name="emplname"
                                        label="Sunpower Employee Last Name"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Item
                                        name="empemail"
                                        label="Sunpower Employee Email"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        name="empphone"
                                        label="Sunpower Employee Phone"
                                        rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            </div>
                        : null
                        }
                        <h5>Homeowner Information</h5>
                        <p className="fs-6 fw-normal">Must be address solar system was installed</p>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="fname"
                                    label="First Name"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="lname"
                                    label="Last Name"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="address1"
                                    label="Address 1"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="address2"
                                    label="Address 2"
                                    rules={[
                                    {
                                        required: false,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Select
                                    showSearch
                                    allowClear
                                    disabled
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        <Option value="AL">Alabama</Option>
                                        <Option value="AK">Alaska</Option>
                                        <Option value="AZ">Arizona</Option>
                                        <Option value="AR">Arkansas</Option>
                                        <Option value="CA">California</Option>
                                        <Option value="CO">Colorado</Option>
                                        <Option value="CT">Connecticut</Option>
                                        <Option value="DE">Delaware</Option>
                                        <Option value="DC">District of Columbia</Option>
                                        <Option value="FL">Florida</Option>
                                        <Option value="GA">Georgia</Option>
                                        <Option value="HI">Hawaii</Option>
                                        <Option value="ID">Idaho</Option>
                                        <Option value="IL">Illinois</Option>
                                        <Option value="IN">Indiana</Option>
                                        <Option value="IA">Iowa</Option>
                                        <Option value="KS">Kansas</Option>
                                        <Option value="KY">Kentucky</Option>
                                        <Option value="LA">Louisiana</Option>
                                        <Option value="ME">Maine</Option>
                                        <Option value="MD">Maryland</Option>
                                        <Option value="MA">Massachusetts</Option>
                                        <Option value="MI">Michigan</Option>
                                        <Option value="MN">Minnesota</Option>
                                        <Option value="MS">Mississippi</Option>
                                        <Option value="MO">Missouri</Option>
                                        <Option value="MT">Montana</Option>
                                        <Option value="NE">Nebaraska</Option>
                                        <Option value="NV">Nevada</Option>
                                        <Option value="NH">New Hampshire</Option>
                                        <Option value="NJ">New Jersey</Option>
                                        <Option value="NM">New Mexico</Option>
                                        <Option value="NY">New York</Option>
                                        <Option value="NC">North Carolina</Option>
                                        <Option value="ND">North Dakota</Option>
                                        <Option value="OH">Ohio</Option>
                                        <Option value="OK">Oklahoma</Option>
                                        <Option value="OR">Oregon</Option>
                                        <Option value="PA">Pennsylvania</Option>
                                        <Option value="RI">Rhode Island</Option>
                                        <Option value="SC">South Carolina</Option>
                                        <Option value="SD">South Dakota</Option>
                                        <Option value="TN">Tennessee</Option>
                                        <Option value="TX">Texas</Option>
                                        <Option value="UT">Utah</Option>
                                        <Option value="VT">Vermont</Option>
                                        <Option value="VA">Virginia</Option>
                                        <Option value="WA">Washington</Option>
                                        <Option value="WV">West Virginia</Option>
                                        <Option value="WI">Wisconsin</Option>
                                        <Option value="WY">Wyoming</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="zip"
                                    label="Zip"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                    {
                                        required: true, type: 'email'
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <h5>Mailing Address</h5>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="mailingaddress1"
                                    label="Address 1"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="mailingaddress2"
                                    label="Address 2"
                                    rules={[
                                    {
                                        required: false,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="mailingcity"
                                    label="City"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="mailingstate"
                                    label="State"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Select
                                    placeholder=" "
                                    disabled
                                    allowClear
                                    >
                                        <Option value="AL">Alabama</Option>
                                        <Option value="AK">Alaska</Option>
                                        <Option value="AZ">Arizona</Option>
                                        <Option value="AR">Arkansas</Option>
                                        <Option value="CA">California</Option>
                                        <Option value="CO">Colorado</Option>
                                        <Option value="CT">Connecticut</Option>
                                        <Option value="DE">Delaware</Option>
                                        <Option value="DC">District of Columbia</Option>
                                        <Option value="FL">Florida</Option>
                                        <Option value="GA">Georgia</Option>
                                        <Option value="HI">Hawaii</Option>
                                        <Option value="ID">Idaho</Option>
                                        <Option value="IL">Illinois</Option>
                                        <Option value="IN">Indiana</Option>
                                        <Option value="IA">Iowa</Option>
                                        <Option value="KS">Kansas</Option>
                                        <Option value="KY">Kentucky</Option>
                                        <Option value="LA">Louisiana</Option>
                                        <Option value="ME">Maine</Option>
                                        <Option value="MD">Maryland</Option>
                                        <Option value="MA">Massachusetts</Option>
                                        <Option value="MI">Michigan</Option>
                                        <Option value="MN">Minnesota</Option>
                                        <Option value="MS">Mississippi</Option>
                                        <Option value="MO">Missouri</Option>
                                        <Option value="MT">Montana</Option>
                                        <Option value="NE">Nebaraska</Option>
                                        <Option value="NV">Nevada</Option>
                                        <Option value="NH">New Hampshire</Option>
                                        <Option value="NJ">New Jersey</Option>
                                        <Option value="NM">New Mexico</Option>
                                        <Option value="NY">New York</Option>
                                        <Option value="NC">North Carolina</Option>
                                        <Option value="ND">North Dakota</Option>
                                        <Option value="OH">Ohio</Option>
                                        <Option value="OK">Oklahoma</Option>
                                        <Option value="OR">Oregon</Option>
                                        <Option value="PA">Pennsylvania</Option>
                                        <Option value="RI">Rhode Island</Option>
                                        <Option value="SC">South Carolina</Option>
                                        <Option value="SD">South Dakota</Option>
                                        <Option value="TN">Tennessee</Option>
                                        <Option value="TX">Texas</Option>
                                        <Option value="UT">Utah</Option>
                                        <Option value="VT">Vermont</Option>
                                        <Option value="VA">Virginia</Option>
                                        <Option value="WA">Washington</Option>
                                        <Option value="WV">West Virginia</Option>
                                        <Option value="WI">Wisconsin</Option>
                                        <Option value="WY">Wyoming</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="mailingzip"
                                    label="Zip"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <h5>This System Was</h5>
                        <Form.Item name="saleoption" label=""
                            rules={[
                                {
                                    required: true,
                                },
                                ]}>
                            <Radio.Group
                                size="large"
                                buttonStyle="solid"
                                className="ant-row ant-row-space-between"
                            >
                                <Radio.Button value="Purchased" className="text-black rebate-card-confirm ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0" disabled>
                                        <span>
                                            <h5 className="pt-4">{formValues.saleoption} <span className="float-end">${formValues.rebateAmt}</span></h5>
                                        </span>
                                </Radio.Button>                         
                            </Radio.Group>
                        </Form.Item>
                        <Divider />
                        <h5>Upload Required Documents</h5>
                        {
                            Object.keys(formValues.userUploadedFiles).map((oneKey,i)=>{
                                if (uploadFiles.includes(oneKey)) {
                                    return (
                                        <p>
                                        <span className="text-secondary"><PaperClipOutlined /> {formValues.userUploadedFiles[oneKey]} </span>
                                        </p>
                                    )
                                }
                                
                            })
                        }
                        <Divider />
                        <h5>Select your Payment Method</h5>
                        <Form.Item name="reward" label=""
                            rules={[
                                {
                                    required: true,
                                },
                                ]}>
                            <Radio.Group
                                size="large"
                                buttonStyle="solid"
                                className="ant-row ant-row-space-between"
                            >
                                {formValues.reward == 'visareward' ? 
                                    <Radio.Button value="visareward" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4" disabled>
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Visa Rewards Card**</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive a physical card by mail
                                                        </li>
                                                    </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                <img src="/images/SP-CardRedesignG.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button>  
                                    
                                : 
                                    null
                                }
                                {formValues.reward == 'virtualvisa' ?
                                    <Radio.Button value="Virtual" className="text-black reward-card-confirm ant-col ant-col-lg-11 ant-col-sm-24 p-4" disabled>
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Virtual Visa Rewards Card**</h5>
                                                    <ul className="lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive e-code by email
                                                        </li>
                                                        <li>
                                                            For online purchases only
                                                        </li>
                                                    </ul>
                                            </span>
                                            <span className="ant-col ant-col-6">
                                                    <img src="/images/SP-CardRedesignB.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button>
                                    : null
                                }
                                {formValues.reward == 'check' ?
                                    <Radio.Button value="check" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4">
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Check</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive a physical check by mail
                                                        </li>
                                                    </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                <img src="/images/SP-Check.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button> 
                                    : null
                                }
                                
                                      
                            </Radio.Group>
                        </Form.Item>
                        <Divider />
                        <h5>Survey</h5>
                        <p className="fs-6 fw-normal">Help us make our rebates even better by answering a few questions.</p>
                        <p className="fs-6">1. How did you hear about SunPower?</p> 
                        <Form.Item name="referral-type" label="" className="fs-4 fw-normal">
                            <Radio.Group
                                disabled
                                defaultValue={formValues.how}
                            >
                                
                                <Radio value="Print">
                                        Print Advertisment
                                </Radio>
                                <Radio value="Digital">
                                        Digital Advertisment
                                </Radio>
                                <Radio value="Referral">
                                        Family or friend referral
                                </Radio>
                                <Radio value="Other">
                                        Other
                                </Radio>                            
                            </Radio.Group>
                        </Form.Item>
                        <p className="fs-6">2. Have you purchased SunPower products before?</p> 
                        <Form.Item name="previous-purchase" label="" className="fs-4 fw-normal">
                            <Radio.Group
                                disabled
                                defaultValue={formValues.have}
                            >
                                
                                <Radio value="Yes-previous">
                                        Yes
                                </Radio>
                                <Radio value="No-previous">
                                        No
                                </Radio>                       
                            </Radio.Group>
                        </Form.Item>
                        <p className="fs-6">3. Were you motivated to purchase because of this rebate?</p> 
                        <Form.Item name="motivated" label="" className="fs-4 fw-normal">
                            <Radio.Group
                                disabled
                                defaultValue={formValues.where}
                            >
                                
                                <Radio value="Yes-motivated">
                                        Yes
                                </Radio>
                                <Radio value="No-motivated">
                                        No
                                </Radio>                       
                            </Radio.Group>
                        </Form.Item>
                        <Divider />
                        <h5>Form Certification</h5>
                        <Form.Item
                            name="certification"
                            label="I am the:"
                            className="fs-4 fw-normal"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Select
                            showSearch
                            disabled
                            allowClear
                            optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                            <Option value="homeowner">Homeowner</Option>
                            <Option value="dealeradmin">Dealer Admin</Option>
                            <Option value="SunPower Admin">SunPower Admin Team</Option>
                            </Select>
                        </Form.Item>
                        {
                        formValues.certification == 'SunPower Admin' ?
                        <Row>
                        <Col lg={6}>
                        <Form.Item
                                name="certFname"
                                label="Certifying Person First Name"
                                rules={[
                                {
                                    required: formValues.certification == 'SunPower Admin',
                                    message: 'Certifying Person First Name'
                                },
                                ]}
                            >
                                <Input disabled />
                        </Form.Item>
                        </Col>
                        <Col lg={6}>
                        <Form.Item
                            name="certLname"
                            label="Certifying Person Last Name"
                            rules={[
                            {
                                required: formValues.certification == 'SunPower Admin',
                                message: 'Certifying Person Last Name'
                            },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                        </Col>
                        </Row>
                        : null
                    }
                    <Divider />
                    <Row>
                        <Col className="p-4">
                            <p>By clicking "SUBMIT," I consent to SunPower contacting me to provide information on my SunPower rebate. SunPower can call or text me (including SMS or MMS) (including via prerecorded messages and/or automated technology belonging to or hosted by third parties) at the telephone number I provided above even if it is on a state or Federal Do Not Call List. My consent is not a condition of purchase. Please see our <a href="https://global.sunpower.com/privacy-policy" target="_blank" className="blue-link">Privacy Statement</a> and <a href="https://global.sunpower.com/sunpower-terms-use-agreement" target="_blank" className="blue-link">Terms of Use</a>.</p>
                        </Col>
                    </Row>
                        <Form.Item {...tailLayout}>
                            <Button className="me-4" onClick={handleEdit}>Edit</Button>
                            <Button type="primary" htmlType="submit" className="btn-orange mt-5">
                            Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Container>
        </LoadingOverlay>
    );
}

export default ConfirmRebateContent;