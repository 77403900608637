import React, {Fragment} from 'react';
import ErrorMessageContent from './ErrorMessageContent';
import Footer from './Footer';

const ErrorMessaging = () => {
    return (
        <Fragment>
            <ErrorMessageContent />
            <Footer />
        </Fragment>
    );
}

export default ErrorMessaging;