import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import FoundationCheckContent from './FoundationCheckContent';
import Footer from './Footer';

const FoundationCheck = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <FoundationCheckContent />
            <Footer />
        </Fragment>
    );
}

export default FoundationCheck;