import React, {Fragment} from 'react';
import Navigation from './Navigation';
import ContactUsHeader from './ContactUsHeader';
import ContactUsContent from './ContactUsContent';
import Footer from './Footer';

const ContactUs = () => {
    return (
        <Fragment>
            <Navigation />
            <ContactUsHeader />
            <ContactUsContent />
            <Footer />
        </Fragment>
    );
}

export default ContactUs;