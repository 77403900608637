import React, { useState } from 'react';
import { Modal, Form, Input, Checkbox, Collapse, Button } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import './PopupForm.css';
import axios from 'axios';

const PopupForm = ({visible, title, okText, onSaveResend, onCancel, fieldValues, token}) => {
    const [form] = Form.useForm();
    const { Panel } = Collapse;
    const [disclaimerCheck, setDisclaimerCheck] = useState(0);
    const [checked, setChecked] = useState(false);
    const [checkBoxValidation, setCheckBoxValidation] = useState(fieldValues.promoDisclaimer  ||  fieldValues.monthlyPaymentFlag);
    const hawaiiList = ['hawaiian airlines miles', 'hawaiian airlines 75000'];

    const captureCheck = (e) => {
        //console.log(e);
        setDisclaimerCheck(e.target.checked ? 1 : 0);
    }

    const onCheckboxChange = async (e) => {
        captureCheck(e);
        await setChecked(e.target.checked);
    }

    const validation = (rule, value, callback) => {
        if(checked || !checkBoxValidation) {
            return callback();
        }
        return callback("Please accept the terms and conditions");
    }

    const familyValidation = (rule, value, callback) => {
      let pattern = /^\b[A-Z0-9._%+-]+@sunpower.com\b$/i;
      let result = pattern.test(form.getFieldValue('empemail'));
      
      if (result) {
        let formValues = {empfname: form.getFieldValue('empfname'), emplname: form.getFieldValue('emplname'), empemail: form.getFieldValue('empemail')}
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateFamilyRebate`,{formValues}, {
          headers: {
              "Authorization" : `Bearer ${token}`
          }})
        .then(response => {
            console.log(response);
            if(response.data.error) {
              return callback(response.data.errorMsg);
            } else {
              return callback();
            }
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
      } else {
        return callback();
      }
      //return callback();
    }

    const CustomEmailValidator = (rule, value, callback) => {
      if (!value) {
        callback();
        return;
      }
    
      const forbiddenDomains = ['maxeon.com', 'totalenergies.com'];
    
      const domain = value.split('@')[1];
      
      if (forbiddenDomains.includes(domain)) {
        callback('Emails from maxeon.com and totalenergies.com are not allowed.');
      } else {
        callback();
      }
    };

    const handleOk = (values) => {
        form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onSaveResend(values, disclaimerCheck);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
    }

    return (
        <Modal
          visible={visible}
          className="popup-style"
          closeIcon=""
          title={title}
          okText={okText}
          maskClosable={false}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onSaveResend(values, disclaimerCheck);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
          footer={[
              <Button key="submit" type="primary" onClick={handleOk}> {okText}</Button>
          ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item
                    name="empfname"
                    style={fieldValues.empfname  ? {display: "block"} : {display: 'none'}} 
                    rules={[
                        {
                          required: fieldValues.empfname,
                          message: 'First name required'
                        },
                      ]}
                >
                    <Input placeholder="SunPower Employee First Name" />
                </Form.Item>
                <Form.Item
                    name="emplname"
                    style={fieldValues.emplname  ? {display: "block"} : {display: 'none'}} 
                    rules={[
                        {
                          required: fieldValues.emplname,
                          message: 'Last name required'
                        },
                      ]}
                >
                    <Input placeholder="SunPower Employee Last Name" />
                </Form.Item>
                <Form.Item
                    name="empemail"
                    style={fieldValues.empemail  ? {display: "block"} : {display: 'none'}} 
                    rules={[
                        {
                          required: fieldValues.empemail,
                          message: 'Email is required'
                        },
                        {
                          validator: CustomEmailValidator,
                        },
                        {/*{
                          pattern:/^\b[A-Z0-9._%+-]+@(sunpower.com|maxeon.com|totalenergies.com)\b$/i,
                          message: `Email should be in the format abc@sunpower.com or abc@maxeon.com or @totalenergies.com`
                        } */}
                        
                        
                      ]}
                >
                    <Input placeholder="SunPower Employee Email" />
                </Form.Item>
                <Form.Item
                    name="empphone"
                    style={fieldValues.empphone ? {display: "block"} : {display: 'none'}} 
                    rules={[
                        {
                          required: fieldValues.empphone,
                          message: 'Phone is required'
                        },
                      ]}
                >
                    <Input placeholder="SunPower Employee Phone" />
                </Form.Item>
                <Form.Item
                    name="milesId"
                    style={fieldValues.milesId  ? {display: "block"} : {display: 'none'}} 
                    rules={[
                        {
                          required: fieldValues.milesId,
                          message: 'Miles ID is required'
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (hawaiiList.includes(fieldValues.promoCode)) {
                              const pattern = /^\d{9}$/;
                              if(!value.match(pattern)) {
                                return Promise.reject(new Error('No special characters or letters allowed. Must be 9 digits'));
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                >
                    <Input placeholder="Enter miles" />
                </Form.Item>
                {
                    fieldValues.monthlyPaymentFlag ?
                    <div><h4>Enter Monthly Payment Amount</h4>
                    **Please note this amount is automatically multiplied by 6 once the disclaimer is accepted.
                    </div>
                    : null
                }
                <Form.Item
                    name="monthlyPayment"
                    style={fieldValues.monthlyPaymentFlag ||  fieldValues.justMonthlyPaymentFlag ? {display: "block"} : {display: 'none'}} 
                    rules={[
                        {
                          required: fieldValues.monthlyPaymentFlag,
                          message: 'Monthly payment is required'
                        },
                      ]}
                >
                    <Input placeholder="Please enter your monthly payment for your SunPower product." />
                </Form.Item>
                {fieldValues.promoDisclaimer ||  fieldValues.monthlyPaymentFlag ?
                    <Collapse>
                        <Panel header="Promotion Disclaimer" key="1">
                        {ReactHtmlParser(fieldValues.disclaimer)}
                        </Panel>
                    </Collapse>
                    :
                    null
                }
                <Form.Item
                    name="promoDisclaimerCheck"
                    rules={[{validator: validation}]}
                    style={fieldValues.promoDisclaimer  ||  fieldValues.monthlyPaymentFlag ? {display: "block"} : {display: 'none'}} 
                >
                    <Checkbox checked={checked} onChange={onCheckboxChange}>I accept the disclaimer as stated.</Checkbox>
                </Form.Item>
                {
                    fieldValues.spwrExcepAmountFlag ?
                    <div><h4>Enter Rebate Amount</h4>
                    </div>
                    : null
                }
                <Form.Item
                    name="spwrExcepAmount"
                    style={fieldValues.spwrExcepAmountFlag  ? {display: "block"} : {display: 'none'}} 
                    rules={[
                        {
                          required: fieldValues.spwrExcepAmountFlag,
                          message: 'Rebate amount is required'
                        },
                      ]}
                >
                    <Input placeholder="Rebate Amount" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default PopupForm;