import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './Header.css';

const ContactUsHeader = () => {

    return (
        <Container className="bg-gray pe-0 text-left">
            <Row className="align-items-center">
                <Col lg={12} xl={6} className="ps-lg-5 text-lg-center text-xl-start">
                    <img src="images/ContactUsHeader-Mobile.png" className="img-fluid d-xs-block d-xl-none"></img>
                    <h2 className="text-white display-4">Contact Us</h2>
                    <p className="text-white fs-5">How can we help?</p>
                    <span className="border-bottom d-block d-lg-none w-50 mx-auto pt-4" />
                    <span className="text-white d-block d-lg-none w-50 mx-auto pt-2">
                    <p className="pt-4 text-white fs-5">Contact by phone:</p>
                    <p>
                        800 - 409 - 8960
                    </p>
                    <p>
                        Mon - Fri  |  8:00 AM - 4:00PM PST
                    </p>
                    </span>
                </Col>
                <Col lg={12} xl={6}>
                    <img src="images/ContactUsHeader.png" className="img-fluid float-end d-none d-xl-block"></img>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactUsHeader;