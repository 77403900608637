import React, {Fragment} from 'react';
import Navigation from './Navigation';
import DealerEmailHeader from './DealerEmailHeader';
import DealerApproveEmailContent from './DealerApproveEmailContent';
import Footer from './Footer';

const DealerApproveEmail = () => {
    return (
        <Fragment>
            <Navigation />
            <DealerEmailHeader />
            <DealerApproveEmailContent />
            <Footer />
        </Fragment>
    );
}

export default DealerApproveEmail;