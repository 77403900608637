import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Input, Button, Alert } from 'antd';
import 'antd/dist/antd.css';
import './CompleteRebateContent.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
  
const ReferralPageSuccessContent = () => {
    const history = useHistory();
    
    return (
        <Container className="py-5">
            <Row>
                <Col>
                    <h5 className="display-6">Your Referral payment has been submitted!</h5>
                </Col>
            </Row>
            
        </Container>
    );
}

export default ReferralPageSuccessContent;