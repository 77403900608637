import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router-dom';
import { Alert } from 'antd';
import LoadingOverlay from 'react-loading-overlay-ts';
import axios from 'axios';
import queryString from 'query-string'

const REDEEM_MUTATION = (token) => `
  mutation {
    virtualCardRedeem(token: "${token}") {
        redeemUrl
        redeemToken
    }
}
`
const axiosGraphQL = axios.create({
    baseURL: process.env.REACT_APP_SYSTEM_URL,
  });

  
const RebateSuccessContent = (props) => {
    const values = queryString.parse(window.location.search);
    const token = values.token;
    console.log(token);
    const [showSpinner, setShowSpinner] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [showMessage1, setShowMessage1] = useState(false);

    const mqSiteUrl = process.env.REACT_APP_MQ_URL

    useEffect(() => {
        if (mqSiteUrl) {
        // Redirect to the specified URL if REACT_APP_MQ_URL is present
        const baseUrl = process.env.REACT_APP_MQ_URL
        window.location.replace(`${baseUrl}/create-account?redemptionToken=${token}&type=virtualvisa`)
        }
    }, [values.token, mqSiteUrl])

    //const [redeemToken] = useMutation(REDEEM_MUTATION)
    //useEffect(() => {
        /*redeemToken({
            variables: { token }
            }).then(
            res => {
                console.log('res: ',res);
                
            },
            err => {
                console.log(err); 
            }
            );*/
            /*try {
                axiosGraphQL
                .post('', {
                query: REDEEM_MUTATION(token),
                })
                .then(result => {
                    console.log(result.data.data.virtualCardRedeem);
                    if (result.data.data.virtualCardRedeem.redeemToken === 'queued') {
                        setShowMessage(true);
                        setShowSpinner(false);
                    } else if(result.data.data.virtualCardRedeem.redeemToken === 'error') {
                        setShowMessage1(true);
                        setShowSpinner(false);
                    } else {
                        window.location = result.data.data.virtualCardRedeem.redeemUrl;
                    }
                });
            } catch (err) {
                console.log(err);
                console.log('failed');
            }
      }, []);*/



    return (
        <LoadingOverlay
                active={showSpinner}
                spinner
                text="Retrieving your virtual code.."
                >
            <Fragment>
            <Container fluid className="pt-5">
                    <div className="text-center">
                        <div className="row justify-content-center">
                            <div className="col-10 p-5">
                                <p style={{ display: ((showMessage) ? 'block' : 'none') }}>This request is taking longer than expected. You will receive an email with your virtual card in the next 2-3 hours.</p>
                                <p style={{ display: ((showMessage) ? 'block' : 'none') }}>We apologize for the inconvenience.</p>
                                <p style={{ display: ((showMessage1) ? 'block' : 'none') }}>here was a problem with the request. Please contact Customer Service by visiting <a href='/contactus'>Contact Us</a>. </p>
                                <p style={{ display: ((showMessage1) ? 'block' : 'none') }}>We apologize for the inconvenience.</p>
                            </div>
                        </div>
                    </div>
            </Container>
            </Fragment>
        </LoadingOverlay>
    );
}

export default RebateSuccessContent;