import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col, Modal } from 'react-bootstrap';
import { Button, Divider } from 'antd';
import './SelectRebateFormTypeContent.css';  
  

const SelectRebateFormTypeContent = () => {


    return (
        <Container className="py-5">
            <Row>
                <Col>
                    <h4 className="display-6">Has your solar system been installed?</h4>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="px-5 py-xs-5 border-lg-end">
                    <h5 className="p-4">
                        My solar panels have already been installed.
                    </h5>
                    <Button type="link" className="btn-orange" href="/startrebate">
                        Start Rebate
                    </Button>
                    <div className="mt-5 d-block d-md-none stacked-border mx-auto"></div>
                </Col>
                <Col md={6} className="px-5 py-xs-5">
                    <h5 className="p-4">
                        My solar panels have not been installed yet.
                    </h5>
                    <Button type="link" className="btn-orange" href="/rebateregistration">
                        Start Rebate Registration
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default SelectRebateFormTypeContent;