import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import RebateRegistrationContent from './RebateRegistrationContent';
import Footer from './Footer';

const RebateRegistration = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <RebateRegistrationContent />
            <Footer />
        </Fragment>
    );
}

export default RebateRegistration;