import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col, Table} from 'react-bootstrap';
import { Input, Button, Tag, Space } from 'antd';
import 'antd/dist/antd.css';
import './TrackRebateContent.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const { Search } = Input;


  
const TrackRebateContent = () => {
    const history = useHistory();
    const [declineFlag, setDeclineFlag] = useState(false);
    const [approveFlag, setApproveFlag] = useState(false);
    const [pendingFlag, setPendingFlag] = useState(false);
    const [pendingDocUploadFlag, setPendingDocUploadFlag] = useState(false);
    const [pendingSWRApprovalFlag, setPendingSWRApprovalFlag] = useState(false);
    const [registeredFlag, setRegisteredFlag] = useState(false);
    const [name, setName] = useState('');
    const [tracking, setTracking] = useState('');
    const [shipStatus, setShipStatus] = useState('');
    const [shipDate, setShipDate] = useState('');

    const getTrackingInfo = (value) => {
        let data = {trackingNo: value};
        setTracking(value);
        setApproveFlag(false);
        setDeclineFlag(false);
        setPendingFlag(false);
        setPendingSWRApprovalFlag(false);
        setPendingDocUploadFlag(false);
        setRegisteredFlag(false);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/index3.php`,{data})
        .then(response => {
            //console.log(response.data);
            setName(response.data[0]['fname']+' '+response.data[0]['lname']);
            setShipDate(response.data[0]['shipDate']);
            setShipStatus(response.data[0]['shipStatus']);
            switch(response.data[0]['status']) {
                case 'Approved':
                    setApproveFlag(true);
                    break;
                case 'Declined':
                    setDeclineFlag(true);
                    break;
                case 'In Process':
                    setPendingFlag(true);
                    break;
                case 'Pending SPWR Approval':
                    setPendingSWRApprovalFlag(true);
                    break;
                case 'Pending Document Upload':
                    setPendingDocUploadFlag(true);
                    break;
                case 'Rebate Registered':
                    localStorage.setItem('rebateTrackingNo', parseInt(value));
                    setRegisteredFlag(true);
                    break;
            }
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    useEffect(() => {
        //console.log(history.location.state);
        if (history.location.state && history.location.state.rebateTrackingNo) {
            getTrackingInfo(history.location.state.rebateTrackingNo);
        }
        
    }, []);

    const onSearch = value => {
        getTrackingInfo(value);
    }

    const completeRebateReg = () => {
        //history.push("/startrebate");
        console.log(tracking);
        history.push({
            pathname: '/startrebate',
            search: tracking,
            state: {trackingNo: tracking}
        })
    }

    return (
        <Container className="py-5">
            <Row>
                <Col>
                    <h4 className="display-6">Track your rebate status</h4>
                    <p className="mb-0">Enter your Rebate Status Tracking Number to see the status of your rebate.</p>
                    <p>Your Rebate Status Tracking Number is provided in the confirmation email you received for submitting your rebate.</p>
                </Col>
            </Row>
            <Row>
                <Col className="ant-col ant-col-lg-12 ant-col-lg-offset-6 py-5">
                    <Search
                        placeholder="Rebate Status Tracking Number"
                        allowClear
                        enterButton="Submit"
                        size="large"
                        className="btn-orange"
                        onSearch={onSearch}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {declineFlag ? 
                        <Table borderless responsive className="border">
                            <thead className="bg-light">
                                <tr>
                                <th>Status</th>
                                <th></th>
                                <th>Shipping Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><Tag color="red" className="text-uppercase">declined</Tag></td>
                                <td>
                                    <p>{name}</p>
                                    <p>Your rebate has been declined.</p>
                                    <p>Check your email for more information.</p>
                                    <p>You can add <a href="#" className="blue-link">sunpowersupport@bestpayment.solutions</a> to your address book to ensure your approval email is not marked as spam.</p>
                                </td>
                                <td>No Shipping Information Available</td>
                                </tr>
                            </tbody>
                        </Table>
                    : null}
                    {approveFlag ? 
                        <Table borderless responsive className="border">
                            <thead className="bg-light">
                                <tr>
                                <th>Status</th>
                                <th></th>
                                <th>Shipping Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><Tag color="green" className="text-uppercase">approved</Tag></td>
                                <td>
                                    <p>{name}</p>
                                    <p>Your rebate has been approved.</p>
                                    <p>Check your email for your approval email.</p>
                                    <p>You can add <a href="#" className="blue-link">sunpowersupport@bestpayment.solutions</a> to your address book to ensure your approval email is not marked as spam.</p>
                                </td>
                                <td>{shipStatus} : {shipDate}</td>
                                </tr>
                            </tbody>
                        </Table>
                    : null}
                    {pendingFlag ? 
                        <Table borderless responsive className="border">
                            <thead className="bg-light">
                                <tr>
                                    <th>Status</th>
                                    <th></th>
                                    <th>Shipping Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><Tag color="blue" className="text-uppercase">pending</Tag></td>
                                    <td>
                                        <p>{name}</p>
                                        <p>Your rebate is pending dealer approval.</p>
                                        <p>You will receive an email when your rebate has been approved.</p>
                                        <p>You can add <a href="#" className="blue-link">sunpowersupport@bestpayment.solutions</a> to your address book to ensure your approval email is not marked as spam.</p>
                                    </td>
                                    <td>No Shipping Information Available</td>
                                </tr>
                            </tbody>
                        </Table>
                    : null}
                    {pendingDocUploadFlag ? 
                        <Table borderless responsive className="border">
                            <thead className="bg-light">
                                <tr>
                                <th>Status</th>
                                <th></th>
                                <th>Shipping Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><Tag color="blue" className="text-uppercase">pending document upload</Tag></td>
                                <td>
                                    <p>{name}</p>
                                    <p>Your rebate is pending until the dealer uploads a copy of the signed contract.</p>
                                    <p>You will receive an email when your rebate has been approved.</p>
                                    <p>You can add <a href="#" className="blue-link">sunpowersupport@bestpayment.solutions</a> to your address book to ensure your approval email is not marked as spam.</p>
                                </td>
                                <td>No Shipping Information Available</td>
                                </tr>
                            </tbody>
                        </Table>
                    : null}
                    {pendingSWRApprovalFlag ?
                        <Table borderless responsive className="border">
                            <thead className="bg-light">
                                <tr>
                                <th>Status</th>
                                <th></th>
                                <th>Shipping Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><Tag color="blue" className="text-uppercase">pending spwr approval</Tag></td>
                                <td>
                                    <p>{name}</p>
                                    <p>Your seasonal rebate is pending SunPower review</p>
                                    <p>You will receive an email when your rebate has been approved.</p>
                                    <p>You can add <a href="#" className="blue-link">sunpowersupport@bestpayment.solutions</a> to your address book to ensure your approval email is not marked as spam.</p>
                                </td>
                                <td>No Shipping Information Available</td>
                                </tr>
                            </tbody>
                        </Table>
                    : null}
                    {registeredFlag ?
                        <Table borderless responsive className="border">
                            <thead className="bg-light">
                                <tr>
                                <th>Status</th>
                                <th></th>
                                <th>Shipping Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><Tag color="gold" className="text-uppercase">registered</Tag></td>
                                <td>
                                    <p>{name}</p>
                                    <p>Your rebate has been registered.</p>
                                    <p>Once your system has been installed, <a href="#" onClick={completeRebateReg} className="blue-link">click here</a> to complete your rebate.</p>
                                    <p>You can add <a href="#"  className="blue-link">sunpowersupport@bestpayment.solutions</a> to your address book to ensure your approval email is not marked as spam.</p>
                                </td>
                                <td>No Shipping Information Available</td>
                                </tr>
                            </tbody>
                        </Table>
                    : null}
                    
                </Col>
            </Row>
        </Container>
    );
}

export default TrackRebateContent;