import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router-dom';
import { Alert } from 'antd';
  
const RebateRegistrationSuccessContent = () => {
    const history = useHistory();   
    const [showMsg, setSHowMsg] = useState(false);
    useEffect(() => {
        //console.log(history.location.state);
        if (history.location.state && history.location.state.referralMsg) {
            if (history.location.state.referralMsg.length > 0) {
                setSHowMsg(true);
            }
        }
        
    }, []);

    return (
        <Container className="p-5">
            <Row className="p-5">
                <Col>
                    <h4 className="display-6">Your rebate has been registered!</h4>
                    {showMsg ? 
                    <Alert message="Your rebate registration will be processed however the referral payment will not be processed as the employee has reached their maximum limit for this year." type="info" />
                    : null}
                    <p>Congratulations, you SunPower rebate registration has been successfully submitted. You will receive a confirmation email shortly with your rebate status tracking number.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default RebateRegistrationSuccessContent;