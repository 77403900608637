import React, {Fragment, useState, useEffect, useRef} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Select, Alert, DatePicker, AutoComplete, Spin, Divider, Radio , Tooltip} from 'antd';
import 'antd/dist/antd.css';
import './RebateRegistrationContent.css';
import { BsCaretDownFill } from "react-icons/bs";
import {Icon, InboxOutlined} from '@ant-design/icons';
import axios from 'axios';
import PopupForm from './PopupForm';
import LoadingOverlay from 'react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader';
import { useHistory } from 'react-router-dom';
import { css } from "@emotion/react";
import ReferralPageConfirmContent from './ReferralPageConfirmContent';
import Collapsible from 'react-collapsible';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const layout = {
    labelCol: {
        span: 24,
        },
        wrapperCol: {
        span: 24,
        },
    };

  const tailLayout = {
    wrapperCol: {
      span: 16,
      offset: 11,
    },
  };

const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;
  
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const ReferralPageContent = () => {

    const [form] = Form.useForm();
    const history = useHistory();
    
    const [section1, setSection1] = useState(true);
    const [section2, setSection2] = useState(false);
    const [section3, setSection3] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Next');
    const [buttonDisable, setButtonDisable] = useState(false);
    const [token, setToken] = useState('');
    const [promoInfo, setPromoInfo] = useState({});
    const [certification, setCertification] = useState('');
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [purchaseRebateAmt, setPurchaseRebateAmt] = useState(0);
    const [leaseRebateAmt, setLeaseRebateAmt] = useState(0);
    const [loanRebateAmt, setLoanRebateAmt] = useState(0);
    const [dealerCode, setDealerCode] = useState('');
    const [promoValue, setPromoValue] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalOkText, setModalOkText] = useState('');
    const [modalFieldValues, setModalFieldValues] = useState({empfname: false, emplname: false, empemail: false, empphone: false,
        milesId: false, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '',
        spwrExcepAmountFlag: false
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [systemSelection, setSystemSelection] = useState('');
    const [moduleFlag, setModuleFlag] = useState(true);
    const [showConfirmPage, setShowConfirmPage] = useState(false);
    const [options, setOptions] = useState([]);
    const [addressOptions, setAddressOptions] = useState([]);
    const employeeInfo = ['friends & family lease', 'friends & family purchase', 'spwr friends & family', 'sunpower friends & family'];
    const airlinesList = ['alaska air', 'hawaiian airlines miles', 'hawaiian airlines'];
    const promoArr = ['springsix', 'DRTV 6 Months', 'Web 6 Months'];
    const promoTitle = {'springsix': 'Spring Six Promotion', 'DRTV 6 Months': 'DRTV 6 Months', 'Web 6 Months': 'Web 6 Months'};
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [spinnerActive2, setSpinnerActive2] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading your content...');
    const [loadingText2, setLoadingText2] = useState('Submitting your Referral details...');
    const [cardType, setCardType] = useState('');
    const [formValues, setFormValues] = useState({
        fname: '', lname: '', address1: '', address2: '', city: '', state: '', zip: '', email: '', phone: '', systemId: ''
    });
    const [rebateAmt, setRebateAmt] = useState(0);
    const [userUploadedFiles, setUserUploadedFiles] = useState({});
    const [rebateSubmitted, setRebateSubmitted] = useState(false);
    const [rebateFileList, setRebateFileList] = useState([]);
    const [redeemed, setRedeemed] = useState(false);
    const [showEmployeeId, setShowEmployeeId] = useState(false);
    const [employeeIds, setEmployeeIds] = useState([]);
    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    
    useEffect(() => {
        setSpinnerActive2(true);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let urlToken = params.get('token');
        axios.get(process.env.REACT_APP_BACKEND_URL+'/createJWTToken.php')
        .then(response => {
            setToken(response.data);
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
        //console.log(urlRoken)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/referralinfo.php`,{urlToken})
        .then(response => {
            setSpinnerActive2(false);
            setLoadingText2('');
            let formValuesCopy = formValues;
            setRedeemed(response.data.notRedeemed);
            formValuesCopy['fname'] = response.data.fname;
            formValuesCopy['lname'] = response.data.lname;
            formValuesCopy['email'] = response.data.email;
            formValuesCopy['phone'] = response.data.phone;
            formValuesCopy['systemId'] = response.data.systemId;
            if (response.data.employeeIdReqd) {
                setShowEmployeeId(true);
            }
            if (response.data.employeeIds) {
                setEmployeeIds(response.data.employeeIds);
            }
            setFormValues(formValuesCopy);
            form.setFieldsValue({
                fname: response.data.fname,
            });
            form.setFieldsValue({
                lname: response.data.lname,
            });
            form.setFieldsValue({
                email: response.data.email,
            });
            form.setFieldsValue({
                phone: response.data.phone,
            });
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
        
    }, []);

    const validateEmployeeId = async (rule, value) => {
        return Promise.resolve();
        /*if (value && employeeIds.indexOf(value.trim()) < 0) {
          return Promise.reject('This is not a valid Employee ID.');
        } else {
          return Promise.resolve();
        }*/
        /*try {
          const response = await fetch('YOUR_API_ENDPOINT', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ value }),
          });
    
          const data = await response.json();
    
          if (data.valid) {
            // Validation successful
            return Promise.resolve();
          } else {
            // Validation failed
            return Promise.reject('Validation failed');
          }
        } catch (error) {
          // Handle API error
          console.error('API error:', error);
          return Promise.reject('API error');
        }*/
      };

    const onFinish = (values) => {
        //console.log(values);
        values['dealerCode'] = dealerCode;
        values['systemId'] = formValues['systemId'];
        setFormValues(values);
        //console.log(formValues);
        setShowConfirmPage(true);
        executeScroll();
        //validateRebateForRestrictions(values);
    };
    
    const handleRebateEdit = () => {
        setShowConfirmPage(false);
    }

    const handleRebateSubmission = () => {
        if (!rebateSubmitted) {
            //console.log(formValues);
            setSpinnerActive(true);
            setLoadingText('Submitting your Referral details...');
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/referralconfirm.php`,{formValues})
                .then(response => {
                    setRebateSubmitted(true);
                    //console.log(response.data);
                    setSpinnerActive2(false);
                    setLoadingText2('');
                    history.push("/referralpagesuccess");
                })
                .catch(error => {
                    console.log(error);
                    //alert(JSON.stringify(error));
                })
        }
    }

    const onSelectAddress = (val, option) => {
        //console.log(val)
        //console.log(option);
        const addressParts = val.split(",");
        form.setFieldsValue({
            address1: addressParts[0],
        });
        form.setFieldsValue({
            city: addressParts[1],
        });
        form.setFieldsValue({
            state: addressParts[2],
        });
        form.setFieldsValue({
            zip: addressParts[3],
        });
        
    }

    const onSearchAddress = val => {
        if (val && val.length > 2) {
            setAutoCompleteLoading(true);
            axios.get(process.env.REACT_APP_BACKEND_URL+"/api/addressautocomplete/"+encodeURI(val), {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                }})
            .then(response => {
                setAutoCompleteLoading(false);
                let dataSource = response.data;
                console.log(dataSource);
                let filtered = dataSource.filter(
                    obj =>
                      obj.value
                        .toString()
                        .toLowerCase()
                        .includes(val.toLowerCase())
                  );
                setAddressOptions(filtered);
                //console.log(response);
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        setToken('');
                    }
                  }
            });
        }
    }

    const addressPasteHandle = (val, type) => {
        //console.log(val);
        //console.log(typeof val);
        if (typeof val == 'string') {
            onSearchAddress(val);
        }
    }


    return (
        <>
        {showConfirmPage ? 
            <ReferralPageConfirmContent 
            formValues={formValues} 
            onFinalSubmit={handleRebateSubmission}
            handleEdit={handleRebateEdit}
            showEmployeeId={showEmployeeId}
            myRef={myRef}
            /> 
            : 
        <LoadingOverlay
            active={spinnerActive || spinnerActive2}
            text={loadingText || loadingText2}
            spinner={<ClockLoader color="#ffffff" css={cssoverride} />}
          >
              {!redeemed ? 
                <Container className="py-5">
                    <Row>
                        <Col>
                            <h5 className="display-6">This referral has already been redeemed.</h5>
                        </Col>
                    </Row>
                
                 </Container>
              :
            
            
        <Container className="text-start py-5">
            <Row>
                <Col className="text-center">
                    <h4 className="display-6">Referral Form</h4>
                    
                    {
                        errorMessage.length > 0 ?
                        <Alert message={errorMessage} type="error" />
                        : null
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} requiredMark={'optional'} >
                        
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="fname"
                                    label="First Name"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'First Name is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="lname"
                                    label="Last Name"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Last Name is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="address1"
                                    label="Address 1"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Address 1 is required'
                                    },
                                    ]}
                                >
                                    <AutoComplete
                                        options={addressOptions}
                                        onSelect={(val, option) => onSelectAddress(val, option)}
                                        onSearch={onSearchAddress}
                                        notFoundContent={autoCompleteLoading ? <Spin /> : 'No matches'}
                                        onChange={(e) => addressPasteHandle(e, 'install')}
                                    >
                                        <Input />
                                    </AutoComplete>
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="address2"
                                    label="Address 2"
                                    rules={[
                                    {
                                        required: false,
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'City is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'State is required'
                                    },
                                    ]}
                                >
                                    <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        <Option value="AL">Alabama</Option>
                                        <Option value="AK">Alaska</Option>
                                        <Option value="AZ">Arizona</Option>
                                        <Option value="AR">Arkansas</Option>
                                        <Option value="CA">California</Option>
                                        <Option value="CO">Colorado</Option>
                                        <Option value="CT">Connecticut</Option>
                                        <Option value="DE">Delaware</Option>
                                        <Option value="DC">District of Columbia</Option>
                                        <Option value="FL">Florida</Option>
                                        <Option value="GA">Georgia</Option>
                                        <Option value="HI">Hawaii</Option>
                                        <Option value="ID">Idaho</Option>
                                        <Option value="IL">Illinois</Option>
                                        <Option value="IN">Indiana</Option>
                                        <Option value="IA">Iowa</Option>
                                        <Option value="KS">Kansas</Option>
                                        <Option value="KY">Kentucky</Option>
                                        <Option value="LA">Louisiana</Option>
                                        <Option value="ME">Maine</Option>
                                        <Option value="MD">Maryland</Option>
                                        <Option value="MA">Massachusetts</Option>
                                        <Option value="MI">Michigan</Option>
                                        <Option value="MN">Minnesota</Option>
                                        <Option value="MS">Mississippi</Option>
                                        <Option value="MO">Missouri</Option>
                                        <Option value="MT">Montana</Option>
                                        <Option value="NE">Nebaraska</Option>
                                        <Option value="NV">Nevada</Option>
                                        <Option value="NH">New Hampshire</Option>
                                        <Option value="NJ">New Jersey</Option>
                                        <Option value="NM">New Mexico</Option>
                                        <Option value="NY">New York</Option>
                                        <Option value="NC">North Carolina</Option>
                                        <Option value="ND">North Dakota</Option>
                                        <Option value="OH">Ohio</Option>
                                        <Option value="OK">Oklahoma</Option>
                                        <Option value="OR">Oregon</Option>
                                        <Option value="PA">Pennsylvania</Option>
                                        <Option value="RI">Rhode Island</Option>
                                        <Option value="SC">South Carolina</Option>
                                        <Option value="SD">South Dakota</Option>
                                        <Option value="TN">Tennessee</Option>
                                        <Option value="TX">Texas</Option>
                                        <Option value="UT">Utah</Option>
                                        <Option value="VT">Vermont</Option>
                                        <Option value="VA">Virginia</Option>
                                        <Option value="WA">Washington</Option>
                                        <Option value="WV">West Virginia</Option>
                                        <Option value="WI">Wisconsin</Option>
                                        <Option value="WY">Wyoming</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="zip"
                                    label="Zip"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Zip is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: "Email is required" },
                                        {/*{
                                            pattern: /^\b[A-Z0-9._%+-]+@sunpower.com\b$/i,
                                            message: "Email should be in the format abc@sunpower.com"
                                        },*/}
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[
                                    {
                                        required: true,
                                        min: 10,
                                        max: 15,
                                        message: 'Phone is required'
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                {showEmployeeId && (
                                <Form.Item
                                    name="employeeId"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Employee ID',
                                    },
                                    {
                                        pattern: /^0\d{5}$/, // Pattern for 6 digits with leading zero
                                        message: 'Please enter 6 digits with a leading zero!',
                                    },
                                    {
                                        validator: validateEmployeeId, // Remote API call validation
                                    },
                                    ]}
                                    /*extra={
                                    <Tooltip title="Please refer to your ADP self service portal to obtain your employee ID. This is accessible by current and former SunPower employees.">
                                        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                    }*/
                                    wrapperCol={{ sm: 24 }} 
                                    style={{ width: "60%", marginRight: 0 }}
                                >
                                    <Input placeholder="Sunpower Employee ID"
                                        suffix={
                                            <Tooltip title="Please refer to your ADP self service portal to obtain your employee ID. This is accessible by current and former SunPower employees.">
                                              <QuestionCircleOutlined style={{ color: 'gray' }} />
                                            </Tooltip>
                                          }
                                    
                                    />
                                </Form.Item>
                                )}
                            </Col>
                        </Row>
                        
                
            <Form.Item name="reward" label=""
                        rules={[
                            {
                                required: true,
                                message: 'Card selection is required'
                            },
                            ]}>
                        
                            
                                <Radio.Group
                                size="large"
                                buttonStyle="solid"
                                className="ant-row ant-row-space-between"
                                >
                                    
                                    <Radio.Button value="virtualvisa" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4">
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Virtual Visa Rewards Card**</h5>
                                                    <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive e-code by email
                                                        </li>
                                                        <li>
                                                            For online purchases only
                                                        </li>
                                                    </ul>
                                            </span>
                                            <span className="ant-col ant-col-6">
                                                    <img src="/images/SP-CardRedesignB.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button>
                                        
                                    
                                    <Radio.Button value="visareward" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4">
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Visa Rewards Card**</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive a physical card by mail
                                                        </li>
                                                    </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                <img src="/images/SP-CardRedesignG.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button> 
                                    <Radio.Button value="check" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4">
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Check</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive a physical check by mail
                                                        </li>
                                                    </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                <img src="/images/SP-Check.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button>  
                                    </Radio.Group>
                            
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" className="btn-orange mt-5">
                            Next
                            </Button>
                        </Form.Item>
                    </Form>
                    </Col>
            </Row>
        </Container>
              }
        </LoadingOverlay>
        }
        </>
    );
}

export default ReferralPageContent;