import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState, useEffect, useRef} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Select, Radio, Image, Checkbox, AutoComplete, DatePicker,  Upload, Spin, Divider, Alert } from 'antd';
import 'antd/dist/antd.css';
import './StartRebateContent.css';
import Collapsible from 'react-collapsible';
import { BsCaretDownFill } from "react-icons/bs";
import AWS from "aws-sdk";
import {Icon, InboxOutlined} from '@ant-design/icons';
import axios from 'axios';
import PopupForm from './PopupForm';
import LoadingOverlay from 'react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader';
import ConfirmRebateContent from './ConfirmRebateContent';
import { useHistory } from 'react-router-dom';
import { css } from "@emotion/react";
import moment from 'moment';
import queryString from "query-string"

const { Option } = Select;
const { Dragger } = Upload;

const layout = {
    labelCol: {
        span: 24,
        },
        wrapperCol: {
        span: 24,
        },
    };

  const tailLayout = {
    wrapperCol: {
      span: 16,
      offset: 11,
    },
  };

const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const StartRebateContent = () => {
    const queryParams = queryString.parse(window.location.search);
    const trackingNoUrl = (queryParams.trackingNo) ? queryParams.trackingNo : '';
    const [form] = Form.useForm();
    const history = useHistory();
    const [section1, setSection1] = useState(true);
    const [section2, setSection2] = useState(false);
    const [section3, setSection3] = useState(false);
    const [section4, setSection4] = useState(false);
    const [section5, setSection5] = useState(false);
    const [section6, setSection6] = useState(false);
    const [section7, setSection7] = useState(false);
    const [section8, setSection8] = useState(false);
    const [section9, setSection9] = useState(false);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [buttonLabel, setButtonLabel] = useState('Next');
    const [buttonDisable, setButtonDisable] = useState(false);
    const [token, setToken] = useState('');
    const [promoInfo, setPromoInfo] = useState({});
    const [systemSelection, setSystemSelection] = useState('');
    const [certification, setCertification] = useState('');
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [purchaseRebateAmt, setPurchaseRebateAmt] = useState(0);
    const [leaseRebateAmt, setLeaseRebateAmt] = useState(0);
    const [loanRebateAmt, setLoanRebateAmt] = useState(0);
    const [dealerCode, setDealerCode] = useState('');
    const [promoValue, setPromoValue] = useState('');
    const [rebateCode, setRebateCode] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalOkText, setModalOkText] = useState('');
    const [showCards, setShowCards] = useState(true);
    const [showCheck, setShowCheck] = useState(false);
    const [modalFieldValues, setModalFieldValues] = useState({empfname: false, emplname: false, empemail: false, empphone: false,
        milesId: false, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '',
        spwrExcepAmountFlag: false, justMonthlyPaymentFlag: false
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [moduleFlag, setModuleFlag] = useState(true);
    const [showConfirmPage, setShowConfirmPage] = useState(false);
    const [options, setOptions] = useState([]);
    const [addressOptions, setAddressOptions] = useState([]);
    const [mailAddressOptions, setMailAddressOptions] = useState([]);
    const employeeInfo = ['friends & family lease', 'friends & family purchase', 'spwr friends & family', 'sunpower friends & family'];
    const airlinesList = ['alaska air', 'hawaiian airlines miles', 'hawaiian airlines', 'hawaiian airlines 75000', 'hawaiian airlines 100k'];
    const promoArr = ['springsix', 'DRTV 6 Months', 'Web 6 Months', 'marchmania24'];
    const promoTitle = {'springsix': 'Spring Six Promotion', 'DRTV 6 Months': 'DRTV 6 Months', 'Web 6 Months': 'Web 6 Months', 'marchmania24': 'Marchmania24'};
    const exceptionPrograms = {'spwr admin exception': 'SPWR Admin Exception', 'spd admin exception': 'SPD Admin Exception'}
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [spinnerActive2, setSpinnerActive2] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading your content...');
    const [loadingText2, setLoadingText2] = useState('Submitting your Rebate code details...');
    const [cardType, setCardType] = useState('');
    const [disclaimerAccept, setDisclaimerAccept] = useState('');
    const [spwrEmpValues, setSPWREmpValues] = useState({empfname: '', emplname: '', empemail: '', empphone: ''});
    const [formValues, setFormValues] = useState({
        fname: '', lname: '', address1: '', address2: '', city: '', state: '', zip: '', email: '', phone: '', mailingaddress1: '',
        mailingaddress2: '', mailingcity: '', mailingstate: '', mailingzip: '', invdate: '', contractDate: '', module: '', system: '',
        saleoption: '', how: '', have: '', where: '', refFname: '', refLname: '', refEmail: '', refPhone: '', refAddress1: '', refAddress2: '',
        refCity: '', refState: '', refZip: '', reward: '', dealer: '', milesId: '', promocode: '', empfname: '', emplname: '',
        empemail: '', empphone: '', certification: '', certFname: '', certLname: '', splPromoAmount: 0, trackingNo: '', disclaimer_accept: '', 
        uploadFiles: [], dealerCode: '', rebateAmt: 0, userUploadedFiles: {}, dealerName: '', spwrExcepAmount: 0
    });
    const [rebateAmt, setRebateAmt] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [userUploadedFiles, setUserUploadedFiles] = useState({});
    const [rebateSubmitted, setRebateSubmitted] = useState(false);
    const [rebateFileList, setRebateFileList] = useState([]);
    const [highlightFields1, setHighlightFields1] = useState(false);
    const [highlightFields2, setHighlightFields2] = useState(false);
    const [highlightFields3, setHighlightFields3] = useState(false);
    const [highlightFields4, setHighlightFields4] = useState(false);
    const [highlightFields5, setHighlightFields5] = useState(false);
    const [highlightFields6, setHighlightFields6] = useState(false);
    const [highlightFields7, setHighlightFields7] = useState(false);
    const [highlightFields8, setHighlightFields8] = useState(false);
    const [highlightFields9, setHighlightFields9] = useState(false);
    const [highlightFields10, setHighlightFields10] = useState(false);
    const [highlightFields11, setHighlightFields11] = useState(false);
    const [highlightFields12, setHighlightFields12] = useState(false);
    const [highlightFields13, setHighlightFields13] = useState(false);
    const [showVirtual, setShowVirtual] = useState(true);
    const [showRebateReg, setShowRebateReg] = useState(false);
    const [autoCompleteMailLoading, setAutoCompleteMailLoading] = useState(false);
    const [errorMailMessage, setErrorMailMessage] = useState('')
    const [validationMessage, setValidationMessage] = useState('');
    const [confirmSpinnerActive, setConfirmSpinnerActive] = useState(false);
    const [confirmLoadingText, setConfirmLoadingText] = useState('Submitting your rebate...');
    const [promoText, setPromoText] = useState('');
    const [showMiles, setShowMiles] = useState(false);
    const [checkFAQS, setCheckFAQS] = useState(false);
    const [showHAMsg, setShowHAMsg] = useState('');
    const airlinePattern = /hawaiian/i;
    /*
    <Option value="A-Series">A-Series</Option>
                                    <Option value="X-Series">X-Series</Option>
                                    <Option value="E-Series">E-Series</Option>
                                    <Option value="P-Series">P-Series</Option>
                                    <Option value="M-Series">M-Series</Option>
                                    <Option value="U-Series">U-Series</Option>
    */
    const [moduleOptions, setModuleOptions] = useState(
        [
            { value: 'A-Series', name: 'A-Series' },
            { value: 'X-Series', name: 'X-Series' },
            { value: 'E-Series', name: 'E-Series' },
            { value: 'P-Series', name: 'P-Series' },
            { value: 'M-Series', name: 'M-Series' },
            { value: 'U-Series', name: 'U-Series' },
            { value: 'Other', name: 'Other' }           
        ]
    );

    const moduleRebateCodeList = ['springsolar23', 'marchmania23', 'marchmania24'];

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    const certoptions = [
        { label: 'System Solar Panels', value: 'System Solar Panels' },
        { label: 'SunVault Battery Storage', value: 'SunVault Battery Storage' },
        { label: 'Solar Panels Plus Battery Storage', value: 'Solar Panels Plus Battery Storage' },
      ];

    useEffect(() => {
        //console.log(history.location.state);
        if (history.location.state && history.location.state.cardType == 'check') {
            setShowCheck(true);
            setShowCards(false);
            setCheckFAQS(true);
        } 
        if (token.length == 0) {
            axios.get(process.env.REACT_APP_BACKEND_URL+'/createJWTToken.php')
            .then(res => {
                //console.log(res.data);
                setToken(res.data);
                //let rebateTrackingNo = (localStorage.getItem('rebateTrackingNo') !== null) ? localStorage.getItem('rebateTrackingNo') : 0;
                
                //console.log(rebateTrackingNo);
                //console.log(history.location);
                let rebateTrackingNo = 0;
                if (history.location.search) {
                    rebateTrackingNo = history.location.search.substring(1);
                }
                if (trackingNoUrl && trackingNoUrl.length > 0) {
                    rebateTrackingNo = trackingNoUrl;
                }
                if (history.location.state && history.location.state.rebateTrackingNo || rebateTrackingNo != 0) {
                    setShowRebateReg(true);
                    rebateTrackingNo = (rebateTrackingNo == 0) ? history.location.state.rebateTrackingNo : rebateTrackingNo;
                    //let rebateTrackingNo = trackingNo.replace(/\D/g,'');
                    let funcCall = {1:setHighlightFields1, 2:setHighlightFields2,3:setHighlightFields3,4:setHighlightFields4,5:setHighlightFields5,
                                    6:setHighlightFields6,7:setHighlightFields7,8:setHighlightFields8,9:setHighlightFields9,10:setHighlightFields10,
                                    11:setHighlightFields11,12:setHighlightFields12, 13: setHighlightFields13};
                    for (let i = 1; i < 14; i++) {
                        funcCall[i](true);
                    }
                    
                    setSpinnerActive2(true);
                    setLoadingText2('Retrieving your Rebate code details...');
                    axios.get(process.env.REACT_APP_BACKEND_URL+'/getReservationInfo.php?tracking='+rebateTrackingNo)
                    .then(res => {
                        if (res.data['promoCode'].toLowerCase() == 'summerloan22') {
                            setDisabled(true);
                        }
                        for (const key in res.data) {
                            form.setFieldsValue({
                                [key]: res.data[key],
                            });
                            if (key == 'dealercode') {
                                setDealerCode(res.data[key]);
                            } else if (key == 'dealerName') {
                                let formValuesCopy = formValues;
                                formValuesCopy['dealerName'] = res.data[key];
                                setFormValues(formValuesCopy);
                            } else if (key == 'promoCode') {
                                setPromoValue(res.data[key]);
                                setRebateCode(res.data[key]);
                            }
                            setSpinnerActive2(false);
                            setLoadingText2('');
                            const match = (res.data['systemSelection'] == 'SunVault Battery Storage') ? false : true;
                            //setModuleFlag(match);
                            if (moduleRebateCodeList.includes(res.data['promoCode'].toLowerCase()) && match) {
                                setModuleFlag(true);
                            } else {
                                setModuleFlag(false);
                            }
                            setSystemSelection(res.data['systemSelection']);
                        }
                    });
                }
            })
        }
        
    });

    const onFinish =  (values) => {
        if (uploadFiles.length == 0) {
            setErrorMessage('You have not uploaded any contract for the rebate');
        } else {
            setValidationMessage('');
            //console.log(values);
            values['reward'] = cardType;
            values['dealerCode'] = dealerCode;
            values['uploadFiles'] = uploadFiles;
            setFormValues(values);
            validateRebateForRestrictions(values);
        }
    };

    /*const onFinish = async (values) => {
        let systemSize = await validateSystemSize();
        if (systemSize) {
            console.log(systemSize);
            if (systemSize.error) {
                setErrorMessage(systemSize.errorMsg);
            }
            else if (uploadFiles.length == 0) {
                setErrorMessage('You have not uploaded any contract for the rebate');
            } else {
                //console.log(values);
                values['dealerCode'] = dealerCode;
                values['uploadFiles'] = uploadFiles;
                setFormValues(values);
                validateRebateForRestrictions(values);
            }
        }
    };*/

    const handleRebateEdit = () => {
        let rebateFileListCopy = [];
        let ctr = 1;
        uploadFiles.map(item => {
            rebateFileListCopy.push({
                uid: ctr,
                name: userUploadedFiles[item],
                status: 'done',
            });
            ctr++;
        })
        setRebateFileList(rebateFileListCopy);
        setShowConfirmPage(false);
    }

    const handleRebateSubmission = () => {
        if (!rebateSubmitted) {
            //let rebateTrackingNo = (localStorage.getItem('rebateTrackingNo') !== null) ? localStorage.getItem('rebateTrackingNo') : 0;
            
            setSpinnerActive2(true);
            setConfirmSpinnerActive(true);
            setLoadingText2('Submitting your Rebate code details...');
            let rebateTrackingNo = 0;
            if (history.location.search) {
                rebateTrackingNo = history.location.search.substring(1);
            }
            
            formValues['empfname'] = (formValues['empfname'] && formValues['empfname'].length > 0) ? formValues['empfname'] : spwrEmpValues['empfname'];
            formValues['emplname'] = (formValues['emplname'] && formValues['emplname'].length > 0) ? formValues['emplname'] : spwrEmpValues['emplname'];
            formValues['empemail'] = (formValues['empemail'] && formValues['empemail'].length > 0) ? formValues['empemail'] : spwrEmpValues['empemail'];
            formValues['empphone'] = (formValues['empphone'] && formValues['empphone'].length > 0) ? formValues['empphone'] : spwrEmpValues['empphone'];
            
            if (history.location.state && history.location.state.rebateTrackingNo || rebateTrackingNo != 0) {
                rebateTrackingNo = (rebateTrackingNo == 0) ? history.location.state.rebateTrackingNo : rebateTrackingNo;
                //const rebateTrackingNo = history.location.state.rebateTrackingNo;
                formValues[uploadFiles] = uploadFiles;
                formValues['trackingNo'] = rebateTrackingNo;
                formValues['disclaimer_accept'] = disclaimerAccept; 
                
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/updateExistingRebate.php`,{formValues}, {
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }})
                .then(response => {
                    //localStorage.removeItem('rebateTrackingNo');
                    setRebateSubmitted(true);
                    //console.log(response.data);
                    setSpinnerActive2(false);
                    setLoadingText2('');
                    //history.push("/rebatesuccess");
                    history.push({
                        pathname: '/rebatesuccess',
                        state: { referralMsg: response.referralMsg }
                    })
                })
                .catch(error => {
                    console.log(error);
                    //alert(JSON.stringify(error));
                })
            } else {
                formValues[uploadFiles] = uploadFiles;
                formValues['disclaimer_accept'] = disclaimerAccept;
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/index2.php`,{formValues}, {
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }})
                .then(response => {
                    //console.log(response);
                    setRebateSubmitted(true);
                    //console.log(response.data);
                    setSpinnerActive2(false);
                    setLoadingText2('');
                    //history.push("/rebatesuccess");
                    history.push({
                        pathname: '/rebatesuccess',
                        state: { referralMsg: response.data.referralMsg }
                    })
                })
                .catch(error => {
                    console.log(error);
                    //alert(JSON.stringify(error));
                })
            }
        }
        
    }

    const formatDate = () => {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        const hr = d.getHours();
        const min = d.getMinutes();
        const sec = d.getSeconds();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day, hr, min, sec].join('');
    }

    const handleFileRemove = (file) => {
        //console.log(file.name);
        let uploadFilesCopy = uploadFiles;
        //console.log(uploadFilesCopy);
        const index = uploadFilesCopy.findIndex(element => element.includes(file.name));
        if (index > -1) {
            uploadFilesCopy.splice(index, 1);
        }
        //console.log(uploadFilesCopy);
        setUploadFiles(uploadFilesCopy);
    }

    /*const handleRebateCode = (e) => {
        axios.get(process.env.REACT_APP_BACKEND_URL+"/api/promoinfo/"+encodeURI(e.target.value.trim()), {
            headers: {
                "Authorization" : `Bearer ${token}`,
                'Accept' : 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }})
        .then(response => {
            console.log(response);
            setPromoInfo(response.data);
        }).catch(error => {
            if (error.response) {
                if (error.response.data.error == "token_expired") {
                    setToken('');
                }
              }
        });
    }*/

    const propsUpload = {
        multiple: true,
        onRemove: handleFileRemove,
        defaultFileList: rebateFileList,
        listType: "picture",
        customRequest({
          action,
          data,
          file,
          filename,
          headers,
          onError,
          onProgress,
          onSuccess,
          withCredentials
        }) {
          /*AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY
          });*/
          setButtonLabel('Please wait..');
          setButtonDisable(true);
          //const S3 = new AWS.S3();
          //console.log("DEBUG filename", file.name);
          //console.log("DEBUG file type", file.type);
          let cleanedFileName =  file.name.replace(/[^a-zA-Z0-9\.]/g, '');
          //console.log(cleanedFileName);
          let finalFileName = formatDate()+'_'+cleanedFileName;
          let uploadFilesCopy = uploadFiles;
          uploadFilesCopy.push(finalFileName)
          let userUploadedFilesCopy = userUploadedFiles;
          userUploadedFilesCopy[finalFileName] = file.name;
          
          //setImageUrl('https://sunpowerdocs.s3.us-west-2.amazonaws.com/'+finalFileName);
        /*const objParams = {
            ACL: 'public-read',
            Bucket: "sunpowerdocs",
            Key: finalFileName,
            Body: file,
            ContentType: file.type 
        };
      
        S3.putObject(objParams)
            .on("httpUploadProgress", function({ loaded, total }) {
              onProgress(
                {
                  percent: Math.round((loaded / total) * 100)
                },
                file
              );
            })
            .send(function(err, data) {
              if (err) {
                onError();
                //console.log("Something went wrong");
                //console.log(err.code);
                //console.log(err.message);
              } else {
                onSuccess(data.response, file);
                setUploadFiles(uploadFilesCopy);
                setButtonLabel('Next');
                setButtonDisable(false);
                tabToNextSection(null, setSection7)
                setUserUploadedFiles(userUploadedFilesCopy);
                setHighlightFields10(false);
                if (highlightFields11 || highlightFields12) {
                    setSection7(true);
                } else if (highlightFields13) {
                    setSection9(true);
                }
                //console.log("SEND FINISHED");
              }
            });*/
            const formData = new FormData();
            formData.append('finalFileName', finalFileName);
            formData.append('contract', file);
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/contractUploadConsumer`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': "multipart/form-data; charset=utf-8",
                'X-Requested-With': 'XMLHttpRequest'
                //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
            }
            })
            .then(result => {
                console.log('response: ', result);
                if (result) {
                    onSuccess(result.status, file);
                    setUploadFiles(uploadFilesCopy);
                    setButtonLabel('Next');
                    setButtonDisable(false);
                    tabToNextSection(null, setSection7)
                    setUserUploadedFiles(userUploadedFilesCopy);
                    setHighlightFields10(false);
                    if (highlightFields11 || highlightFields12) {
                        setSection7(true);
                    } else if (highlightFields13) {
                        setSection9(true);
                    }
                }
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })
        }
    };

    const handleOnFinishFailed = ({values, errorFields, outOfDate}) => {
        //console.log(values);
        //console.log(cardType);
        values['reward'] = cardType;
        /*console.log(values);
        console.log(errorFields);
        console.log(uploadFiles);*/
        if (uploadFiles.length == 0) {
            setErrorMessage('You have not uploaded any contract for the rebate');
        }
        const reqdFields = [['promoCode'], ['dealerName', 'contractDate', 'installDate', 'moduleType', 'systemSize'], 
            ['fname', 'lname', 'address1', 'city', 'state', 'zip', 'email', 'phone'], 
            ['mailingaddress1', 'mailingcity', 'mailingstate', 'mailingzip'], ['saleoption'], ['ignore'], ['reward'],
            ['ignore'], ['certification']
        ];
        const fieldCollapse = {0 : setSection1, 1: setSection2, 2: setSection3, 3: setSection4, 4: setSection5,
            5: setSection6, 6: setSection7, 7: setSection8, 8:setSection9
        };
        if (uploadFiles.length == 0) {
            setSection6(true);
        }
        if (values) {
            for (let i = 0; i < reqdFields.length; i++) {
                for (let j = 0; j < reqdFields[i].length; j++) {
                    //console.log(reqdFields[i][j]);
                    //console.log(values[reqdFields[i][j]]);
                    if (reqdFields[i][j] != 'ignore' && !values[reqdFields[i][j]]) {
                        //console.log(i);
                        switch(i) {
                            case 0:
                                setSection1(true);
                                break;
                            case 1:
                                //console.log(section2);
                                setSection2(true);
                                break;
                            case 2:
                                //console.log(section3);
                                setSection3(true);
                                break;
                            case 3:
                                setSection4(true);
                                break;
                            case 4:
                                setSection5(true);
                                break;
                            case 5:
                                setSection6(true);
                                break;
                            case 6:
                                setSection7(true);
                                break;
                            case 7:
                                setSection8(true);
                                break;
                            case 8:
                                setSection9(true);
                                break;
                            default:
                                break;
                        }
                        //fieldCollapse[i](true);
                        //break;
                    } 
                }
            }
        } else {
            for (let i = 0; i < reqdFields.length; i++) {
                for (let j = 0; j < reqdFields[i].length; j++) {
                    if (i != 7) {
                        fieldCollapse[i](true);
                        break;
                    }
                }
            }
        }
        setValidationMessage('All the required fields highlighted below need to be filled out.');
        executeScroll();
    }

    const tabToNextSection = (e, funcCall) => {
        //console.log(funcCall);
        funcCall(true);
    }

    const onCertChange = (value) => {
        setCertification(value);
        setHighlightFields13(false);
    };

    const captureChange = (e) => { console.log(e.target.value); }

    const captureChangeMotivation = (e) => {
        setSection9(true);
    }

    const captureSaleType = (e) => {
        //console.log(e.target.value);
        getRebateAmountInfo(form.getFieldValue('address1'), e.target.value, form.getFieldValue('state'), form.getFieldValue('moduleType'));
        tabToNextSection(e, setSection6);
        setHighlightFields7(false);
        setHighlightFields8(false);
        setHighlightFields9(false);
    }

    const captureCardType = (e) => {
        setCardType(e.target.value);
        tabToNextSection(e, setSection8);
        setHighlightFields11(false);
        setHighlightFields12(false);
    }

    const validateInstallDate = (rule, value, callback) => {
        const today = new Date().getTime();
        const idate = new Date(value).getTime();
        const contractTime = new Date(form.getFieldValue('contractDate')).getTime();
        if ((today - idate) < 0) {
            return callback('Install date cannot be in future');
        } else if ((idate < contractTime)) {
            return callback('Install date cannot be before the contract date');
        }
        let row = {};
        //row['promocode'] = form.getFieldValue('promoCode');
        row['promocode'] = rebateCode;
        row['installDate'] = value;
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validateinstallDate.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            if (!response.data) {
                //console.log('error');
                callback('You can submit a rebate within 290 days of install date only');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validateSunpowerEmail = (rule, value, callback) => {
        let row = {};
        //row['promocode'] = form.getFieldValue('promoCode');
        row['promocode'] = rebateCode;
        row['email'] = value;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validateSunpowerEmail.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            if (!response.data.status) {
                //console.log('error');
                callback(response.data.errorMsg);
                //return Promise.reject('The email needs to end in @sunpower.com');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validateReward = (rule, value, callback) => {
        let row = {};
        //row['promocode'] = form.getFieldValue('promoCode');
        row['promocode'] = rebateCode;
        row['saletype'] = form.getFieldValue('saleoption');
        row['reward'] = value;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validatereward.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            if (!response.data) {
                //console.log('error');
                callback('You cannot select virtual visa for rebate greater than 1000');
                //return Promise.reject('The email needs to end in @sunpower.com');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }   

    const validateContractDate = (rule, value, callback) => {
        let row = {};
        //row['promocode'] = form.getFieldValue('promoCode');
        row['promocode'] = rebateCode;
        row['contractDate'] = value;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validatecontractdate.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            if (!response.data) {
                console.log('error');
                callback('Rebate code is invalid based on contract signed date. Contact the dealer who sold/leased you your system.');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validatePromo = (rule, value, callback) => {
        let row = {};
        row['promocode'] = (value && value !== undefined) ? value : rebateCode;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validatepromo.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            if (!response.data) {
                console.log('error');
                callback('Please enter a valid Promotional Code');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const validateDealer = (rule, value, callback) => {
        if (dealerCode.length > 1) {
            let row = {};
            row['dealercode'] = dealerCode;
            
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/validatedealer.php`,{row}, {
                headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(response => {
                //console.log(response.data);
                if (!response.data) {
                    console.log('error');
                    callback('Please enter a valid Dealer Name');
                }
                return callback();
            })
            .catch(error => {
                console.log(error);
                //alert(JSON.stringify(error));
            })
        } else {
            return callback('Please enter a valid Dealer Name');
        }
        
    }

    const validateInstallAddressFill= (rule, value, callback) => {
        const address1 = form.getFieldValue('address1');
        if (!address1) {
            //console.log('address1 error');
            callback('Please enter an install address first');
        }
        return callback();
    }

    const validateSaleType =   (rule, value, callback) => {
        const dealerNameValue = form.getFieldValue('dealerName');
        if (!dealerNameValue) {
            //console.log('address1 error');
            callback('Dealership Name must be entered before selecting the Sale Type');
        }
        return callback();
    }

    /*const validateSystemSize =  (rule, value, callback) => {
        let row = {};
        row['moduleType'] = form.getFieldValue('moduleType');
        row['promocode'] = form.getFieldValue('promoCode');
        row['state'] = form.getFieldValue('state');
        row['systemSize'] = value;

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateSystemSize`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            //console.log(response.data.errorMsg);
            if (response.data && response.data.error) {
                //console.log(response.data.error);
                callback(response.data.errorMsg);
                //callback('The System Size is smaller than one required for this rebate code');
            } 
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }*/

    const validateSystemSize =  () => {
        let row = {};
        row['moduleType'] = form.getFieldValue('moduleType');
        row['promocode'] = form.getFieldValue('promoCode');
        row['state'] = form.getFieldValue('state');
        row['systemSize'] = form.getFieldValue('systemSize');

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateSystemSize`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const onChangeSystemSelect = (checkedValues) => {
        //console.log('checked = ', checkedValues);
        setSystemSelection(checkedValues.target.value);
        //console.log('flag:'+checkedValues.indexOf("Solar Panels"));
        //console.log(checkedValues.some(substring=>yourBigString.includes("Solar Panels")));
        //const match = checkedValues.find(element => element.includes("Solar Panels"));
        const match = (checkedValues.target.value == 'SunVault Battery Storage') ? false : true;
        if (moduleRebateCodeList.includes(form.getFieldValue('promoCode').trim().toLowerCase()) && match) {
            setModuleFlag(true);
        } else {
            setModuleFlag(false);
        }
        //setModuleFlag(match);
        //console.log(match !== undefined)
    }

    const addressCopy = (e) => {
        if (e.target.checked) {
            if (form.getFieldValue('address1') && form.getFieldValue('address1').length > 0) {
                setErrorMailMessage('');
                form.setFieldsValue({
                    mailingaddress1: form.getFieldValue('address1'),
                });
                form.setFieldsValue({
                    mailingcity: form.getFieldValue('city'),
                });
                form.setFieldsValue({
                    mailingstate: form.getFieldValue('state'),
                });
                form.setFieldsValue({
                    mailingzip: form.getFieldValue('zip'),
                });
                setSection5(true);
                setHighlightFields3(false);
                setHighlightFields4(false);
                setHighlightFields5(false);
                setHighlightFields6(false);
            } else {
                setErrorMailMessage('Install address needs to be entered first');
            }
        } 
    }

    const onSearch = val => {
        if (val && val.length > 4) {
            axios.get(process.env.REACT_APP_BACKEND_URL+'/dealerlist.php')
            .then(res => {
                let dataSource = res.data;
                //console.log(dataSource);
                let filtered = dataSource.filter(
                    obj =>
                      obj.key !== 0 &&
                      obj.value
                        .toString()
                        .toLowerCase()
                        .includes(val.toLowerCase())
                  );
                setOptions(filtered);
            })
        }
    };

    const onSelect = (val, option) => {
        //console.log(val)
        //console.log(option);
        setDealerCode(option.key);
        form.setFieldsValue({dealerName: option.value});
        let formValuesCopy = formValues;
        formValuesCopy['dealerName'] = option.value;
        setFormValues(formValuesCopy);
    };

    const getRebateAmountInfo = (data, saleType, state, moduleType) => {
        let amt = 0;
        if (formValues['splPromoAmount'] != 0) {
            amt = formValues['splPromoAmount'];
        } else if (formValues['spwrExcepAmount'] != 0) {
            amt = formValues['spwrExcepAmount'];
        }
        if (amt != 0) {
            setRebateAmt(amt);
            if (saleType == 'Purchased') {
                setPurchaseRebateAmt(amt);
                setLeaseRebateAmt('');
                setLoanRebateAmt('');
            } else if (saleType == 'Leased') {
                setLeaseRebateAmt(amt);
                setPurchaseRebateAmt('');
                setLoanRebateAmt('');
            } else {
                setLoanRebateAmt(amt);
                setPurchaseRebateAmt('');
                setLeaseRebateAmt('');
            }
            if (amt > 1000) {
                setShowVirtual(false);
            }
            if (amt > 5000) {
                setShowCards(false);
                setShowCheck(true);
                
            }
        } else {
            let row = {};
            row['dealercode'] = dealerCode;
            row['address1'] = data;
            row['state'] = state;
            row['moduleType'] = moduleType;
            row['promocode'] = promoValue
            let rebateTrackingNo = 0;
            rebateTrackingNo = (history.location.state && history.location.state.rebateTrackingNo) ? history.location.state.rebateTrackingNo : rebateTrackingNo;
            row['rebateTrackingNo'] = rebateTrackingNo;
            
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/getPromoAmounts`,{row}, {
                headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(response => {
                //console.log(response.data);
                if (saleType == 'Purchased') {
                    const flag1 = (response.data.purchaseAmount > 1000 ) ? setShowVirtual(false) : setShowVirtual(true);
                    /*if (response.data.purchaseAmount > 2500 ) {
                        setShowCards(false);
                        setShowCheck(true); 
                     } else {
                         setShowVirtual(true);
                         setShowCards(true);
                     }*/
                    const purchaseAmount = airlinePattern.test(rebateCode) ? ~~response.data.purchaseAmount : response.data.purchaseAmount;
                    setPurchaseRebateAmt(purchaseAmount);
                    setLeaseRebateAmt('');
                    setLoanRebateAmt('');
                    setRebateAmt(purchaseAmount);
                    if (response.data.purchaseAmount > 2500) {
                        setShowCards(false);
                        setShowCheck(true);
                    }
                } else if (saleType == 'Leased') {
                    const flag2 = (response.data.leaseAmount > 1000 ) ? setShowVirtual(false) : setShowVirtual(true);
                    const leaseAmount = airlinePattern.test(rebateCode) ? ~~response.data.leaseAmount : response.data.leaseAmount;
                    setLeaseRebateAmt(leaseAmount);
                    setPurchaseRebateAmt('');
                    setLoanRebateAmt('');
                    setRebateAmt(leaseAmount);
                    if (response.data.leaseAmount >2500) {
                        setShowCards(false);
                        setShowCheck(true);
                    }
                } else {
                    const flag3 = (response.data.loanAmount > 1000 ) ? setShowVirtual(false) : setShowVirtual(true);
                    const loanAmt = airlinePattern.test(rebateCode) ? ~~response.data.loanAmount : response.data.loanAmount;
                    setLoanRebateAmt(loanAmt);
                    setPurchaseRebateAmt('');
                    setLeaseRebateAmt('');
                    setRebateAmt(loanAmt);
                    if (response.data.loanAmount > 2500) {
                        setShowCards(false);
                        setShowCheck(true);
                    }
                }
                if (airlinePattern.test(rebateCode)) {
                    setShowCards(false);
                    setShowCheck(false);
                    setShowMiles(true);
                }
            })
            .catch(error => {
                console.log(error);
                //alert(JSON.stringify(error));
            })
        }
        
    }

    const validateRebateForRestrictions = (values) => {
        values['reward'] = cardType;
        setSpinnerActive(true);
        setLoadingText('Validating Rebate code details...');
        let row = {};
        row['promocode'] = promoValue
        row['dealer'] = dealerCode;
        row['address1'] = form.getFieldValue('address1');
        row['city'] = form.getFieldValue('city');
        row['state'] = form.getFieldValue('state');
        row['zip'] = form.getFieldValue('zip');
        row['mailingaddress1'] = form.getFieldValue('mailingaddress1');
        row['mailingcity'] = form.getFieldValue('mailingcity');
        row['mailingstate'] = form.getFieldValue('mailingstate');
        row['mailingzip'] = form.getFieldValue('mailingzip');
        row['promocode'] = promoValue;
        row['systemSize'] = form.getFieldValue('systemSize');
        row['contractDate'] = form.getFieldValue('contractDate');
        row['moduleType'] = form.getFieldValue('moduleType');
        row['trackingNo'] = 0;
        let rebateTrackingNo = 0;
        if (history.location.search) {
            rebateTrackingNo = history.location.search.substring(1);
        }
        if (history.location.state && history.location.state.rebateTrackingNo || rebateTrackingNo != 0) {
            row['trackingNo'] = (rebateTrackingNo == 0) ? history.location.state.rebateTrackingNo : rebateTrackingNo;
        }
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateRebateSmarty`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            //console.log(response.data);
            if (response.data.error) {
                setErrorMessage(response.data.errorMsg);
                setSpinnerActive(false);
                setLoadingText('');
                executeScroll();
            } else {
                console.log(spwrEmpValues);
                if (response.data.correctedMailingAddr.length > 0) {
                    form.setFieldsValue({
                        mailingaddress1: response.data.correctedMailingAddr,
                    });
                    values['mailingaddress1'] = response.data.correctedMailingAddr;
                }
                if (response.data.correctedInstallAddr.length > 0) {
                    form.setFieldsValue({
                        address1: response.data.correctedInstallAddr,
                    });
                    values['address1'] = response.data.correctedInstallAddr;
                }
                //const formValues = {certification_confirm: 'SunPower Admin', showPhysical: true};
                let formValuesCopy = formValues;
                for (let i = 0; i < uploadFiles.length; i++) {
                    formValuesCopy['userfile'+i] = uploadFiles[i];
                }
                const module = (values['moduleType']) ? values['moduleType'] : '';
                const certFname = (values['certFname']) ? values['certFname'] : '';
                const certLname = (values['certLname']) ? values['certLname'] : '';
                
                let formValueDup = formValues;
                //console.log('going to confirm');
                //console.lo ag(formValueDup);
                formValuesCopy = {fname: values['fname'], lname: values['lname'], address1: values['address1'], address2: values['address2'], city: values['city'], 
                state: values['state'], zip: values['zip'], email: values['email'], phone: values['phone'], mailingaddress1: values['mailingaddress1'],
                mailingaddress2: values['mailingaddress2'], mailingcity: values['mailingcity'], mailingstate: values['mailingstate'], mailingzip: values['mailingzip'], 
                invdate: values['installDate'], contractDate: values['contractDate'], module, system: values['systemSize'],
                saleoption: values['saleoption'], how: values['referral-type'], have: values['previous-purchase'], where: values['motivated'], refFname: '', refLname: '', refEmail: '', refPhone: '', refAddress1: '', refAddress2: '',
                refCity: '', refState: '', refZip: '', reward: values['reward'], dealer: dealerCode, promocode: promoValue, certification: values['certification'], certFname, certLname, trackingNo: 0,
                systemSelection: systemSelection, rebateAmt, userUploadedFiles, dealerName: formValueDup['dealerName'],
                splPromoAmount: formValueDup['splPromoAmount'], milesId: formValueDup['milesId'], empfname: formValueDup['empfname'],
                emplname: formValueDup['emplname'], empemail: formValueDup['empemail'], empphone: formValueDup['empphone'], 
                spwrExcepAmount: formValueDup['spwrExcepAmount'], disclaimer_accept: formValueDup['disclaimer_accept'], uploadFiles
                };
                setFormValues(formValuesCopy);
                setSpinnerActive(false);
                setLoadingText('');
                setErrorMessage('');
                setShowConfirmPage(true);
                setValidationMessage('');
                executeScroll();
            }
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    const onSelectAddress = (val, option) => {
        //console.log(val)
        //console.log(option);
        const addressParts = val.split(",");
        form.setFieldsValue({
            address1: addressParts[0],
        });
        form.setFieldsValue({
            city: addressParts[1],
        });
        form.setFieldsValue({
            state: addressParts[2],
        });
        form.setFieldsValue({
            zip: addressParts[3],
        });
        setErrorMailMessage('');
    }

    const onSearchAddress = val => {
        if (val && val.length > 2) {
            //console.log('val:',val);
            setAutoCompleteLoading(true);
            axios.get(process.env.REACT_APP_BACKEND_URL+"/api/addressautocompletesmarty/"+encodeURIComponent(window.btoa(val))+"/"+rebateCode, {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                }})
            .then(response => {
                setAutoCompleteLoading(false);
                let dataSource = response.data;
                //console.log(dataSource);
                let filtered = dataSource.filter(
                    obj =>
                      obj.value
                        .toString()
                        .toLowerCase()
                        .includes(val.toLowerCase())
                  );
                //console.log(filtered);
                setAddressOptions(filtered);
                //console.log(response);
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        setToken('');
                    }
                  }
            });
        }
    }

    const addressPasteHandle = (val, type) => {
        //console.log(val);
        //console.log(typeof val);
        if (typeof val == 'string') {
            if (type == 'install') {
                onSearchAddress(val);
            } else {
                onSearchMailAddress(val);
            }
        }
    }

    const onSelectMailAddress = (val, option) => {
        //console.log(val)
        //console.log(option);
        const addressParts = val.split(",");
        form.setFieldsValue({
            mailingaddress1: addressParts[0],
        });
        form.setFieldsValue({
            mailingcity: addressParts[1],
        });
        form.setFieldsValue({
            mailingstate: addressParts[2],
        });
        form.setFieldsValue({
            mailingzip: addressParts[3],
        });
        setHighlightFields3(false);
        setHighlightFields4(false);
        setHighlightFields5(false);
        setHighlightFields6(false);
        if (highlightFields7 || highlightFields8 ||  highlightFields9) {
            setSection5(true);
        } else if (highlightFields10) {
            setSection6(true);
        } else if (highlightFields11 || highlightFields12) {
            setSection7(true);
        } else if (highlightFields13) {
            setSection9(true);
        }
    }

    const onSearchMailAddress = val => {
        const address1 = form.getFieldValue('address1');
        if (!address1) {
            form.setFields([
                {
                  name: 'mailingaddress1',
                  errors: ['Install address needs to be filled out first'],
                },
             ]);
        }
        else if (val && val.length > 2) {
            setAutoCompleteMailLoading(true);
            axios.get(process.env.REACT_APP_BACKEND_URL+"/api/addressautocompletesmarty/"+encodeURI(window.btoa(val)), {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                }})
            .then(response => {
                setAutoCompleteMailLoading(false);
                let dataSource = response.data;
                //console.log(dataSource);
                let filtered = dataSource.filter(
                    obj =>
                      obj.value
                        .toString()
                        .toLowerCase()
                        .includes(val.toLowerCase())
                  );
                setMailAddressOptions(filtered);
                //console.log(response);
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        setToken('');
                    }
                  }
            });
        }
    }

    const isInArray = (value, array) => {
        return array.indexOf(value) > -1;
    }

    const handleRebateCode = (e) => {
        if (e.target && e.target.value && e.target.value.trim().toLowerCase() == 'summerloan22') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        if (e.target && e.target.value && airlinePattern.test(e.target.value)) {
            setShowHAMsg('The name entered should be the same name that is listed on the Hawaiian Airlines account.');
        } else {
            setShowHAMsg('');
        }
        if (e.target && e.target.value && (e.target.value.trim().toLowerCase() == 'marchmania23')) {
            setDisabled(true);            
            setPromoText('Rebate amount will be determined after contract validation.');
            setModuleOptions(
                [
                    { value: 'M-Series', name: 'M-Series' },
                    { value: 'U-Series', name: 'U-Series' },
                    { value: 'Other', name: 'Other' }                  
                ]
            );
            
        } else if (e.target && e.target.value && (e.target.value.trim().toLowerCase() == 'marchmania24')) {
            setDisabled(true);            
            setModuleOptions(
                [
                    { value: 'M-Series', name: 'M-Series' },
                    { value: 'U-Series', name: 'U-Series' }               
                ]
            );
            
        } else {
            setPromoText('');
            setDisabled(false);
            setModuleOptions(
                [
                    { value: 'A-Series', name: 'A-Series' },
                    { value: 'X-Series', name: 'X-Series' },
                    { value: 'E-Series', name: 'E-Series' },
                    { value: 'P-Series', name: 'P-Series' },
                    { value: 'M-Series', name: 'M-Series' },
                    { value: 'U-Series', name: 'U-Series' },
                    { value: 'Other', name: 'Other' }                  
                ]
            );
        }
        setRebateCode(e.target.value.trim());
        setPromoValue(e.target.value.trim());
        setSpinnerActive(true);
        setLoadingText('Retrieving your Rebate code details...');
        let row = {};
        row['promo'] = e.target.value.trim()
        if (moduleRebateCodeList.includes(e.target.value.trim().toLowerCase())) {
            setModuleFlag(true);
        } else {
            setModuleFlag(false);
        }
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/promodisclaimer.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setSpinnerActive(false);
            setLoadingText('');
            //console.log(response.data);
            let disclaimer = response;
            let discstr = '';
            //console.log(disclaimer);
            if (disclaimer.data.present == 1) {
                
                disclaimer.data.data.forEach(function (item, index) {
                    //console.log(item);
                    //console.log(index);
                    item = item.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    item = item.replace(/^["'](.+(?=["']$))["']$/, '$1');
                    discstr = (discstr.length > 0 ) ? discstr + '<p>'+item+'</p>' : '<p>'+item+'</p>';
                });
                //console.log(discstr);
                if (!isInArray(e.target.value.trim(), promoArr) && e.target.value.trim().toLowerCase() != 'springsix') {
                    setModalTitle(promoTitle[e.target.value.toLowerCase().trim()]);
                    setModalOkText('Save changes');
                    
                    setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                        milesId: false, monthlyPaymentFlag: false, promoDisclaimer: true, disclaimer: discstr, 
                        spwrExcepAmountFlag: false, justMonthlyPaymentFlag: false
                    });
                    setModalVisible(true);
                } 
            }
            //console.log(discstr);
            if (isInArray(e.target.value.trim().toLowerCase(), promoArr) || e.target.value.trim().toLowerCase() == 'springsix') {
                //if (e.target.value.toLowerCase() == 'springsix' || e.target.value.toLowerCase() == 'drtv6months') {
                const promoDisclaimer = (e.target.value.trim().toLowerCase() == 'marchmania24') ? false : true;
                const justMonthlyPaymentFlag = (e.target.value.trim().toLowerCase() == 'marchmania24') ? true : false;
                const monthlyPaymentFlag = (e.target.value.trim().toLowerCase() == 'marchmania24') ? false : true;
                console.log(promoDisclaimer);
                setModalTitle(promoTitle[e.target.value.trim().toLowerCase()]);
                setModalOkText('Save changes');
                setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                    milesId: false, monthlyPaymentFlag, promoDisclaimer, disclaimer: discstr, 
                    spwrExcepAmountFlag: false, justMonthlyPaymentFlag
                });
                setModalVisible(true);
            }
            if (airlinesList.indexOf(e.target.value.toLowerCase()) > -1) {
                setShowCheck(false);
                setShowCards(false);
                setShowMiles(true);
                setModalTitle('Please give your miles Id');
                setModalOkText('Save changes');
                setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                    milesId: true, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '', 
                    spwrExcepAmountFlag: false, promoCode: e.target.value.toLowerCase(), justMonthlyPaymentFlag: false
                });
                setModalVisible(true);
            } else {
                console.log('checkFAQS:'+checkFAQS);
                if (checkFAQS) {
                    setShowCheck(true);
                } else {
                    setShowCards(true);
                }
                
                setShowMiles(false);
            }
            if (employeeInfo.indexOf(e.target.value.trim().toLowerCase()) > -1) {
                setModalTitle('SunPower Employee Information');
                setModalOkText('Save changes');
                setModalFieldValues({empfname: true, emplname: true, empemail: true, empphone: true,
                    milesId: false, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '', 
                    spwrExcepAmountFlag: false, justMonthlyPaymentFlag: false
                });
                setModalVisible(true);
            }
            if (exceptionPrograms.hasOwnProperty(e.target.value.trim().toLowerCase())) {
            //if (e.target.value.trim().toLowerCase() == 'spwr admin exception') {
                setModalTitle(exceptionPrograms[e.target.value.trim().toLowerCase()]);
                setModalOkText('Save changes');
                setModalFieldValues({empfname: false, emplname: false, empemail: false, empphone: false,
                    milesId: false, monthlyPaymentFlag: false, promoDisclaimer: false, disclaimer: '', 
                    spwrExcepAmountFlag: true, justMonthlyPaymentFlag: false
                });
                setModalVisible(true);
            }
                
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
        //e.stopPropagation();
        //console.log(e.target.value);
        
        /*if (!isInArray(e.target.value.trim(), promoArr) && e.target.value.trim().toLowerCase() != 'springsix' && e.target.value.trim().toLowerCase() != 'spwr admin exception') {
            //if (e.target.value.toLowerCase() != 'springsix' && e.target.value.toLowerCase() != 'drtv6months') {
                $.post("promo.php", data, function(result){
                    var promoval = JSON.parse(result);
                    purchaseAmount = promoval.purchaseAmount;
                    leaseAmount = promoval.leaseAmount;
                    loanAmount = promoval.loanAmount;
                    
                    
                });
        } */
    
    }

    const handleModalPopUp = (values, disclaimerCheck) => {
        //console.log(values);
        //console.log(disclaimerCheck)
        setModalVisible(false);
        let formValuesCopy = formValues;
        let spwrEmpValues = {empfname: '', emplname: '', empemail: '', empphone: ''};
        if (isInArray(promoValue.trim().toLowerCase(), promoArr) || promoValue.trim().toLowerCase() == 'springsix') {
            formValuesCopy['splPromoAmount'] = 6*parseFloat(values['monthlyPayment']);
            formValuesCopy['splPromoAmount'] = formValuesCopy['splPromoAmount'].toFixed(2);
        }
        if (airlinesList.indexOf(promoValue.toLowerCase()) > -1) {
            formValuesCopy['milesId'] = values['milesId'];
        }
        if (employeeInfo.indexOf(promoValue.trim().toLowerCase()) > -1) {
            formValuesCopy['empfname'] = values['empfname'];
            formValuesCopy['emplname'] = values['emplname'];
            formValuesCopy['empemail'] = values['empemail'];
            formValuesCopy['empphone'] = values['empphone'];
            spwrEmpValues['empfname'] = values['empfname'];
            spwrEmpValues['emplname'] = values['emplname'];
            spwrEmpValues['empemail'] = values['empemail'];
            spwrEmpValues['empphone'] = values['empphone'];
            setSPWREmpValues(spwrEmpValues);
        }
        if (exceptionPrograms.hasOwnProperty(promoValue.trim().toLowerCase())) {
        //if (promoValue.trim().toLowerCase() == 'spwr admin exception') {
            formValuesCopy['spwrExcepAmount'] = values['spwrExcepAmount'];
        }
        
        formValuesCopy['disclaimer_accept'] = (disclaimerCheck) ? disclaimerCheck : 0;
        setDisclaimerAccept(formValuesCopy['disclaimer_accept']);
        //console.log(formValuesCopy);
        setFormValues(formValuesCopy);
    }

    const onCancel = () => {
        setModalVisible(false);
    }

    const onSubjectChange = (value) => {
        //console.log(e.target.value);
        console.log(value);
    }

    const onModuleChange = (value) => {
        //console.log(e.target.value);
        console.log(value);
        if (form.getFieldValue('promoCode').toLowerCase() == 'marchmania23' && form.getFieldValue('address1') && form.getFieldValue('state')) {
            getRebateAmountInfo(form.getFieldValue('address1'), "Loaned", form.getFieldValue('state'), value);
        }
    }

    const handleEnter = (e, sectionNo) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            e.preventDefault();
            switch(sectionNo) {
                case 2:
                    setSection2(true);
                    break;
                case 3:
                    setSection3(true);
                    break;
                case 4:
                    setSection4(true);
                    break;
                case 5:
                    setSection5(true);
                    break;
                case 6:
                    setSection6(true);
                    break;
                case 7:
                    setSection7(true);
                    break;
                case 8:
                    setSection8(true);
                    break;
                case 9:
                    setSection9(true);
                    break;
                default:
                    break;
            }
        }
    }

    const handleDateChange = (e, dateField) => {
        e.preventDefault();
        //console.log(e);
        if (e.target.value.length >= 10 || e.key === "Tab") {
            //console.log(e.target.value);
            const date = new Date(e.target.value);
            const curr_date = date.getDate();
            const curr_month = date.getMonth() + 1; //Months are zero based
            const curr_year = date.getFullYear();
            //console.log(curr_date + "-" + curr_month + "-" + curr_year);
            const dateVal = curr_month+'/'+curr_date+'/'+curr_year;
            switch(dateField) {
                case 'contractDate':
                    form.setFieldsValue({
                        contractDate: moment(dateVal,"mm/dd/YYYY")
                    });
                    setHighlightFields1(false);
                    break;
                case 'installDate':
                    form.setFieldsValue({
                        installDate: moment(dateVal,"mm/dd/YYYY")
                    });
                    setHighlightFields2(false);
                    break;
            }
        }
    }

    const handleSelection = (e, sectionNo) => {
        //e.preventDefault();
        switch (sectionNo) {
            case 1:
                setHighlightFields1(false);
                break;
            case 2:
                setHighlightFields2(false);
                if (highlightFields3 || highlightFields4 || highlightFields5 || highlightFields6) {
                    setSection4(true);
                } else if (highlightFields7 || highlightFields8 ||  highlightFields9) {
                    setSection5(true);
                } else if (highlightFields10) {
                    setSection6(true);
                } else if (highlightFields11 || highlightFields12) {
                    setSection7(true);
                } else if (highlightFields13) {
                    setSection9(true);
                }
                break;
        }
    }

    const greaterThan5000 = (input) => {
        // Convert input to numeric value (assuming input is string)
        const sizeInWatts = parseFloat(input);

        // Check if input is a number and greater than or equal to 5000 watts
        if (!isNaN(sizeInWatts) && sizeInWatts >= 5000) {
            return true;
        } else {
            return false;
        }
    }

    const validateSystemSizeInput = (_, value) => {
        console.log(value);
        if (!value) {
          return Promise.reject(new Error('Please input system size'));
        }

        if (!greaterThan5000(value) && rebateCode.trim().toLocaleLowerCase() == 'marchmania24') {
          return Promise.reject(new Error('System size must be a minimum 5000 watts'));
        }
        return Promise.resolve();
      };

    return (
        <>
        {showConfirmPage ? 
            <ConfirmRebateContent 
            formValues={formValues} 
            onFinalSubmit={handleRebateSubmission}
            handleEdit={handleRebateEdit}
            uploadFiles={uploadFiles}
            myRef={myRef}
            confirmSpinnerActive={confirmSpinnerActive} 
            confirmLoadingText={confirmLoadingText}
            /> 
            : 
        <LoadingOverlay
            active={spinnerActive || spinnerActive2}
            text={loadingText || loadingText2}
            spinner={<ClockLoader color="#ffffff" css={cssoverride} />}
          >
        <Container className="text-start py-5">
        <Row>
            <Col className="text-center">
                {showRebateReg ?
                    <h4 className="display-6">Complete a Rebate Registration</h4>
                :
                    <h4 className="display-6">Start a rebate request</h4>
                }
                
                <p className="mb-0 pb-0">Complete the form to submit your rebate request.</p>
                <p>Upon completion, you will receive an email from us providing you a tracking number.</p>
                {
                    errorMessage.length > 0 ?
                    <Alert message={errorMessage} type="error" />
                    : null
                }
                <div ref={myRef}>
                {
                    validationMessage.length > 0 ?
                    <Alert message={validationMessage} type="error" />
                    : null
                }
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} requiredMark={'optional'}
                    onFinishFailed={handleOnFinishFailed}
                >
                <Collapsible trigger={[ <BsCaretDownFill />, "  Rebate Information"]}  open={section1} onTriggerClosing={() => {setSection1(false)}}>
                    <Form.Item
                        name="promoCode"
                        label="Rebate Code"
                        tooltip="If you do not know your rebate code contact the dealer who sold/leased you your system"
                        rules={[
                        {
                            required: true,
                            message: 'Rebate code is required'
                        },
                        {
                            validator: validatePromo
                        },
                        ]}
                    >
                        <Input onKeyDown={(e) => handleEnter(e, 2)} onBlur={(e) => {handleRebateCode(e); setSection2(true)}}  />
                        
                    </Form.Item>
                    {promoText}
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  System Information']}  open={section2} onTriggerClosing={() => {setSection2(false)}}>
                    <Form.Item
                        name="systemSelection"
                        label=""
                        rules={[
                        {
                            required: true,
                            message: 'You need to select one of the options'
                        },
                        ]}
                    >
                        <Radio.Group options={certoptions} onChange={onChangeSystemSelect} />
                    </Form.Item>
                    <Form.Item
                        name="dealerName"
                        label="Dealer Name"
                        rules={[
                        {
                            required: true,
                            message: 'Dealer Name is required'
                        },
                        {
                            validator: validateDealer
                        },
                        ]}
                    >
                        <AutoComplete
                            options={options}
                            onSelect={(val, option) => onSelect(val, option)}
                            onSearch={onSearch}
                        >
                            <Input />
                        </AutoComplete>
                    </Form.Item>
                        <Row className="finish-form">
                            <Col lg={6}>
                                <Form.Item 
                                    name="contractDate"
                                    label="Contract Signed Date"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Contract Signed Date is required'
                                    },
                                    {
                                        validator: validateContractDate
                                    },
                                    ]}
                                    >
                                    <DatePicker format="MM/DD/YYYY" placeholder="" className={highlightFields1 ? 'highlight-class input-w-100' : 'input-w-100'} onKeyDown={(e) => handleDateChange(e, 'contractDate')} onChange={(e) => handleSelection(e, 1)} 
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                          }} onCopy={(e)=>{
                                            e.preventDefault()
                                            return false;
                                          }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item 
                                    name="installDate"
                                    label="Install Date"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Install Date is required'
                                    },
                                    {
                                        validator: validateInstallDate
                                    },
                                    ]}
                                    >
                                    <DatePicker format="MM/DD/YYYY" placeholder="" className={highlightFields2 ? 'highlight-class input-w-100' : 'input-w-100'}  onKeyDown={(e) => handleDateChange(e, 'contractDate')} onChange={(e) => handleSelection(e, 2)} 
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                          }} onCopy={(e)=>{
                                            e.preventDefault()
                                            return false;
                                          }}
                                    />
                                </Form.Item>
                        </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="moduleType"
                                    label="Module Type"
                                    rules={[
                                    {
                                        required: moduleFlag ,
                                    },
                                    ]}
                                    style={moduleFlag  ? { display: "block" } : { display: "none" }}
                                >
                                    <Select
                                    showSearch
                                    onChange={onModuleChange}
                                    allowClear
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {moduleOptions.map((e, key) => {
                                            return <Option key={e.value} value={e.value}>{e.name}</Option>;
                                        })}
                                    
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="systemSize"
                                    label="System Size (Watts)"
                                    rules={[{ required: true, message: "System Size is required" }, {validator: validateSystemSizeInput }]}
                                >
                                    <Input onKeyDown={(e) => handleEnter(e, 3)} onBlur={(e) => tabToNextSection(e, setSection3)} />
                                </Form.Item>
                        </Col>
                        </Row>
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  HomeOwner Information']}  open={section3} onTriggerClosing={() => {setSection3(false)}}>
                    <p className="fs-6 fw-normal">Must be address solar system was installed</p>
                    <Row>
                        <Col lg={6}>
                            <Form.Item
                                name="fname"
                                label="First Name"
                                rules={[
                                {
                                    required: true,
                                    message: 'First Name is required'
                                },
                                ]}
                            >
                                <Input disabled={showRebateReg} />
                            </Form.Item>
                            {showHAMsg}
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                name="lname"
                                label="Last Name"
                                rules={[
                                {
                                    required: true,
                                    message: 'Last Name is required'
                                },
                                ]}
                            >
                                <Input disabled={showRebateReg} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Item
                                name="address1"
                                label="Address 1"
                                rules={[
                                {
                                    required: true,
                                    message: 'Address 1 is required'
                                },
                                ]}
                            >
                                {
                                    showRebateReg ?
                                    <Input disabled={showRebateReg} />
                                :
                                    <AutoComplete
                                        options={addressOptions}
                                        onSelect={(val, option) => onSelectAddress(val, option)}
                                        onSearch={onSearchAddress}
                                        notFoundContent={autoCompleteLoading ? <Spin /> : 'No matches'}
                                        onChange={(e) => addressPasteHandle(e, 'install')}
                                        onPaste={(e)=>{
                                            e.preventDefault()
                                            return false;
                                            }} 
                                        onCopy={(e)=>{
                                            e.preventDefault()
                                            return false;
                                            }}
                                    >
                                        <Input  />
                                    </AutoComplete>
                                    
                                }
                                
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                name="address2"
                                label="Address 2"
                                rules={[
                                {
                                    required: false,
                                },
                                ]}
                            >
                                <Input disabled={showRebateReg} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Item
                                name="city"
                                label="City"
                                rules={[
                                {
                                    required: true,
                                    message: 'City is required'
                                },
                                ]}
                            >
                                <Input disabled={showRebateReg} />
                            </Form.Item>
                        </Col>
                        <Col lg={3}>
                            <Form.Item
                                name="state"
                                label="State"
                                rules={[
                                {
                                    required: true,
                                    message: 'State is required'
                                },
                                ]}
                            >
                                <Select
                                showSearch
                                onChange={onSubjectChange}
                                allowClear
                                optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                disabled={showRebateReg} 
                                >
                                    <Option value="AL">Alabama</Option>
                                    <Option value="AK">Alaska</Option>
                                    <Option value="AZ">Arizona</Option>
                                    <Option value="AR">Arkansas</Option>
                                    <Option value="CA">California</Option>
                                    <Option value="CO">Colorado</Option>
                                    <Option value="CT">Connecticut</Option>
                                    <Option value="DE">Delaware</Option>
                                    <Option value="DC">District of Columbia</Option>
                                    <Option value="FL">Florida</Option>
                                    <Option value="GA">Georgia</Option>
                                    <Option value="HI">Hawaii</Option>
                                    <Option value="ID">Idaho</Option>
                                    <Option value="IL">Illinois</Option>
                                    <Option value="IN">Indiana</Option>
                                    <Option value="IA">Iowa</Option>
                                    <Option value="KS">Kansas</Option>
                                    <Option value="KY">Kentucky</Option>
                                    <Option value="LA">Louisiana</Option>
                                    <Option value="ME">Maine</Option>
                                    <Option value="MD">Maryland</Option>
                                    <Option value="MA">Massachusetts</Option>
                                    <Option value="MI">Michigan</Option>
                                    <Option value="MN">Minnesota</Option>
                                    <Option value="MS">Mississippi</Option>
                                    <Option value="MO">Missouri</Option>
                                    <Option value="MT">Montana</Option>
                                    <Option value="NE">Nebaraska</Option>
                                    <Option value="NV">Nevada</Option>
                                    <Option value="NH">New Hampshire</Option>
                                    <Option value="NJ">New Jersey</Option>
                                    <Option value="NM">New Mexico</Option>
                                    <Option value="NY">New York</Option>
                                    <Option value="NC">North Carolina</Option>
                                    <Option value="ND">North Dakota</Option>
                                    <Option value="OH">Ohio</Option>
                                    <Option value="OK">Oklahoma</Option>
                                    <Option value="OR">Oregon</Option>
                                    {rebateCode.toLowerCase() == 'marchmania24' ? null : <Option value="PA">Pennsylvania</Option>}
                                    <Option value="RI">Rhode Island</Option>
                                    <Option value="SC">South Carolina</Option>
                                    <Option value="SD">South Dakota</Option>
                                    <Option value="TN">Tennessee</Option>
                                    <Option value="TX">Texas</Option>
                                    <Option value="UT">Utah</Option>
                                    <Option value="VT">Vermont</Option>
                                    <Option value="VA">Virginia</Option>
                                    <Option value="WA">Washington</Option>
                                    <Option value="WV">West Virginia</Option>
                                    <Option value="WI">Wisconsin</Option>
                                    <Option value="WY">Wyoming</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={3}>
                            <Form.Item
                                name="zip"
                                label="Zip"
                                rules={[
                                {
                                    required: true,
                                    message: 'Zip is required'
                                },
                                ]}
                            >
                                <Input disabled={showRebateReg} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    { required: true, message: "Email is required" },
                                    {
                                        pattern: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}\b$/i,
                                        message: "Email is not valid"
                                    },
                                    {
                                        validator: validateSunpowerEmail
                                    },
                                ]}
                            >
                                <Input onBlur={(e) => tabToNextSection(e, setSection4)} disabled={showRebateReg} />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                name="phone"
                                label="Phone"
                                rules={[
                                {
                                    required: true,
                                    min: 10,
                                    max: 15,
                                    message: 'Phone is required'
                                },
                                ]}
                            >
                                <Input onKeyDown={(e) => handleEnter(e, 4)} disabled={showRebateReg} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  Mailing Address']}  open={section4} onTriggerClosing={() => {setSection4(false)}}>
                    {
                        errorMailMessage.length > 0 ?
                        <Alert message={errorMailMessage} type="error" />
                        : null
                    }
                    <Checkbox className="py-4" onChange={(e) => addressCopy(e)} >
                        Use same address as above
                    </Checkbox>
                    <Row>
                        <Col lg={6}>
                            <Form.Item
                                name="mailingaddress1"
                                label="Mailing Address 1"
                                rules={[
                                {
                                    required: true,
                                    message: 'Mailing Address 1 is required'
                                },
                                {
                                    validator: validateInstallAddressFill
                                },
                                ]}
                            >
                                <AutoComplete
                                    options={mailAddressOptions}
                                    onSelect={(val, option) => onSelectMailAddress(val, option)}
                                    onSearch={onSearchMailAddress}
                                    onChange={(e) => addressPasteHandle(e, 'mailing')}
                                    notFoundContent={autoCompleteMailLoading ? <Spin /> : 'No matches'}
                                >
                                    <Input className={highlightFields3 ? 'highlight-class' : ''} />
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                name="mailingaddress2"
                                label="Mailing Address 2"
                                rules={[
                                {
                                    required: false,
                                },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Item
                                name="mailingcity"
                                label="Mailing City"
                                rules={[
                                {
                                    required: true,
                                    message: 'Mailing City is required'
                                },
                                ]}
                            >
                                <Input className={highlightFields4 ? 'highlight-class' : ''} />
                            </Form.Item>
                        </Col>
                        <Col lg={3}>
                            <Form.Item
                                name="mailingstate"
                                label="Mailing State"
                                rules={[
                                {
                                    required: true,
                                    message: 'Mailing State is required'
                                },
                                ]}
                            >
                                <Select
                                placeholder=" "
                                onChange={onSubjectChange}
                                allowClear
                                className={highlightFields5 ? 'highlight-class' : ''}
                                >
                                    <Option value="AL">Alabama</Option>
                                    <Option value="AK">Alaska</Option>
                                    <Option value="AZ">Arizona</Option>
                                    <Option value="AR">Arkansas</Option>
                                    <Option value="CA">California</Option>
                                    <Option value="CO">Colorado</Option>
                                    <Option value="CT">Connecticut</Option>
                                    <Option value="DE">Delaware</Option>
                                    <Option value="DC">District of Columbia</Option>
                                    <Option value="FL">Florida</Option>
                                    <Option value="GA">Georgia</Option>
                                    <Option value="HI">Hawaii</Option>
                                    <Option value="ID">Idaho</Option>
                                    <Option value="IL">Illinois</Option>
                                    <Option value="IN">Indiana</Option>
                                    <Option value="IA">Iowa</Option>
                                    <Option value="KS">Kansas</Option>
                                    <Option value="KY">Kentucky</Option>
                                    <Option value="LA">Louisiana</Option>
                                    <Option value="ME">Maine</Option>
                                    <Option value="MD">Maryland</Option>
                                    <Option value="MA">Massachusetts</Option>
                                    <Option value="MI">Michigan</Option>
                                    <Option value="MN">Minnesota</Option>
                                    <Option value="MS">Mississippi</Option>
                                    <Option value="MO">Missouri</Option>
                                    <Option value="MT">Montana</Option>
                                    <Option value="NE">Nebaraska</Option>
                                    <Option value="NV">Nevada</Option>
                                    <Option value="NH">New Hampshire</Option>
                                    <Option value="NJ">New Jersey</Option>
                                    <Option value="NM">New Mexico</Option>
                                    <Option value="NY">New York</Option>
                                    <Option value="NC">North Carolina</Option>
                                    <Option value="ND">North Dakota</Option>
                                    <Option value="OH">Ohio</Option>
                                    <Option value="OK">Oklahoma</Option>
                                    <Option value="OR">Oregon</Option>
                                    <Option value="PA">Pennsylvania</Option>
                                    <Option value="RI">Rhode Island</Option>
                                    <Option value="SC">South Carolina</Option>
                                    <Option value="SD">South Dakota</Option>
                                    <Option value="TN">Tennessee</Option>
                                    <Option value="TX">Texas</Option>
                                    <Option value="UT">Utah</Option>
                                    <Option value="VT">Vermont</Option>
                                    <Option value="VA">Virginia</Option>
                                    <Option value="WA">Washington</Option>
                                    <Option value="WV">West Virginia</Option>
                                    <Option value="WI">Wisconsin</Option>
                                    <Option value="WY">Wyoming</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={3}>
                            <Form.Item
                                name="mailingzip"
                                label="Mailing Zip"
                                rules={[
                                {
                                    required: true,
                                    message: 'Mailing Zip is required'
                                },
                                ]}
                            >
                                <Input onBlur={(e) => tabToNextSection(e, setSection2)} onChange={(e) => tabToNextSection(e, setSection5)} className={highlightFields6 ? 'highlight-class' : ''} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  This System was']}  open={section5} onTriggerClosing={() => {setSection5(false)}}>
                    <Form.Item name="saleoption" label=""
                        rules={[
                            {
                                required: true,
                                message: 'System sale type is required'
                            },
                            {
                                validator: validateSaleType
                            },
                            ]}>
                        <Radio.Group
                            size="large"
                            buttonStyle="solid"
                            className="ant-row ant-row-space-between"
                            onChange={captureSaleType}
                        >
                            <Radio.Button value="Purchased" style={disabled ? { display: 'none'} : {display: 'block'}} className={highlightFields7 ? "highlight-class text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0" : "text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0"}>
                                    <span>
                                        <h5 className="border-bottom py-4">Purchased <span className="float-end">{ airlinePattern.test(rebateCode) ? `${purchaseRebateAmt}` : `$${purchaseRebateAmt}`}</span></h5>
                                        <p className="mb-0 lh-base fw-normal">Documents required for verification:</p>
                                    <ul className="border-bottom pb-4 lh-sm fw-light">
                                        <li>
                                            Dealer Installation Agreement/ Contract
                                        </li>
                                        <li>
                                            Invoice not accepted
                                        </li>
                                    </ul>
                                    <p className="mb-0 fw-normal">Sample Documents</p>
                                    <Image.PreviewGroup>
                                        <Image width={100} src="/images/Purchase Agreement Pg 1.png" className="sample-img" />
                                        <Image width={100} src="/images/Purchase Agreement Pg 2.png" className="sample-img" />
                                    </Image.PreviewGroup>
                                    </span>
                            </Radio.Button>
                            <Radio.Button value="Leased" style={disabled ? { display: 'none'} : {display: 'block'}} className={highlightFields8 ? "highlight-class text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0" : "text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0"}>
                                    <span>
                                        <h5 className="border-bottom py-4">SunPower Lease <span className="float-end">{ airlinePattern.test(rebateCode) ? `${leaseRebateAmt}` : `$${leaseRebateAmt}`}</span></h5>
                                        <p className="mb-0 fw-normal lh-base">Documents required for verification:</p>
                                    <ul className="border-bottom pb-4 lh-sm fw-light">
                                        <li>
                                            Page 1 of lease agreement
                                        </li>
                                        <li>
                                            Exhibit D of lease agreement
                                        </li>
                                    </ul>
                                    <p className="mb-0 fw-normal">Sample Documents</p>
                                    <Image.PreviewGroup>
                                        <Image width={100} src="/images/Lease Page 1.png" className="sample-img" />
                                        <Image width={100} src="/images/Lease, Exhibit D.png" className="sample-img" />
                                    </Image.PreviewGroup>
                                    </span>
                            </Radio.Button>
                            <Radio.Button value="Loaned" className={highlightFields9 ? "highlight-class text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0" : "text-black rebate-card ant-col ant-col-lg-7 ant-col-sm-24 p-4 pt-0"}>
                                    <span>
                                        <h5 className="border-bottom py-4">SunPower Loan <span className="float-end">{ airlinePattern.test(rebateCode) ? `${loanRebateAmt}` : `$${loanRebateAmt}`}</span></h5>
                                        <p className="mb-0 lh-base fw-normal">Documents required for verification:</p>
                                    <ul className="border-bottom pb-4 lh-sm fw-light">
                                        <li>
                                        Page 1, 2 & customer signature page of Solar Energy System Home Improvement Contract
                                        </li>
                                    </ul>
                                    <p className="mb-0 fw-normal">Sample Documents</p>
                                    <Image.PreviewGroup>
                                        <Image width={100} src="/images/Loan PNG 1.png" className="sample-img" />
                                        <Image width={100} src="/images/Loan PNG 2.png" className="sample-img" />
                                    </Image.PreviewGroup>
                                    </span>
                            </Radio.Button>                            
                        </Radio.Group>
                    </Form.Item>
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  Upload Required Documents']}  open={section6} onTriggerClosing={() => {setSection6(false)}}>
                    <p className="fs-6 fw-normal">Acceptable image formats are: jpg, png, pdf. Size must be less than 10MB. If you are unable to upload your Signed Contract, <a href="/contactus"  target="_blank" className="blue-link">Contact Us</a> for assistance.</p>
                    <Dragger {...propsUpload}>
                        
                            <p className="ant-upload-drag-icon" >
                            <InboxOutlined />
                            </p>
                            <p className={highlightFields10 ? 'highlight-class ant-upload-text' : 'ant-upload-text'} >Click or drag file to this area to upload</p>
                    </Dragger>
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  Select your Payment Method']}  open={section7} onTriggerClosing={() => {setSection7(false)}}>
                    <Form.Item name="reward" label=""
                        rules={[
                            {
                                required: true,
                                message: 'Card selection is required'
                            },
                            {
                                validator: validateReward
                            },
                            ]}>
                        
                            {showCards ?
                                <Radio.Group
                                size="large"
                                buttonStyle="solid"
                                className="ant-row ant-row-space-between"
                                onChange={captureCardType}
                                >
                                    <Radio.Button value="visareward" className={highlightFields12 ? "highlight-class text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4" : "text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4"}>
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Visa Rewards Card**</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive a physical card by mail
                                                        </li>
                                                    </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                <img src="/images/SP-CardRedesignG.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button>
                                    {
                                        showVirtual ?
                                            <Radio.Button value="virtualvisa" className={highlightFields11 ? "highlight-class text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4" : "text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4"}>
                                                <span className="ant-row ant-row-space-between">
                                                    <span className="ant-col ant-col-17">
                                                        <h5>SunPower Virtual Visa Rewards Card**</h5>
                                                            <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                                <li>
                                                                    Receive e-code by email
                                                                </li>
                                                                <li>
                                                                    For online purchases only
                                                                </li>
                                                            </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                            <img src="/images/SP-CardRedesignB.png" className="img-fluid" />
                                                    </span>
                                                </span>
                                            </Radio.Button>
                                        : null
                                    }
                                    
                                     
                                    </Radio.Group>
                            :   
                                showCheck ?
                                    <Radio.Group
                                    size="large"
                                    buttonStyle="solid"
                                    className="ant-row ant-row-space-between"
                                    onChange={captureCardType}
                                    >
                                        <Radio.Button value="check" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4">
                                            <span className="ant-row ant-row-space-between">
                                                <span className="ant-col ant-col-17">
                                                    <h5>SunPower Check</h5>
                                                    <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                            <li>
                                                                Receive a physical check by mail
                                                            </li>
                                                        </ul>
                                                        </span>
                                                        <span className="ant-col ant-col-6">
                                                    <img src="/images/SP-Check.png" className="img-fluid" />
                                                </span>
                                            </span>
                                        </Radio.Button>  
                                    </Radio.Group>
                                : 
                                    showMiles ?

                                
                                <Radio.Group
                                size="large"
                                buttonStyle="solid"
                                className="ant-row ant-row-space-between"
                                onChange={captureCardType}
                                >
                                    <Radio.Button value="miles" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4">
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>Airline Miles</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                    <li>
                                                    Miles will be applied to your Hawaiian AIrlines Account
                                                    </li>
                                                </ul>
                                            </span>
                                        </span>
                                    </Radio.Button>  
                                </Radio.Group>
                                : null
                            }
                    </Form.Item>
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  Survey (optional)']}  open={section8} onTriggerClosing={() => {setSection8(false)}}>
                    <p className="fs-6 fw-normal">Help us make our rebates even better by answering a few questions.</p>
                    <p className="fs-6">1. How did you hear about SunPower?</p> 
                    <Form.Item name="referral-type" label="" className="fs-4 fw-normal">
                        <Radio.Group
                            onChange={captureChange}
                        >
                            
                            <Radio value="Print">
                                    Print Advertisment
                            </Radio>
                            <Radio value="Digital">
                                    Digital Advertisment
                            </Radio>
                            <Radio value="Referral">
                                    Family or friend referral
                            </Radio>
                            <Radio value="Other">
                                    Other
                            </Radio>                            
                        </Radio.Group>
                    </Form.Item>
                    <p className="fs-6">2. Have you purchased SunPower products before?</p> 
                    <Form.Item name="previous-purchase" label="" className="fs-4 fw-normal">
                        <Radio.Group
                            onChange={captureChange}
                        >
                            
                            <Radio value="Yes-previous">
                                    Yes
                            </Radio>
                            <Radio value="No-previous">
                                    No
                            </Radio>                       
                        </Radio.Group>
                    </Form.Item>
                    <p className="fs-6">3. Were you motivated to purchase because of this rebate?</p> 
                    <Form.Item name="motivated" label="" className="fs-4 fw-normal">
                        <Radio.Group
                            onChange={captureChangeMotivation}
                        >
                            
                            <Radio value="Yes-motivated">
                                    Yes
                            </Radio>
                            <Radio value="No-motivated">
                                    No
                            </Radio>                       
                        </Radio.Group>
                    </Form.Item>
                </Collapsible>
                <Collapsible trigger={[ <BsCaretDownFill />, '  Form Certification']}  open={section9} onTriggerClosing={() => {setSection9(false)}}>
                    <Form.Item
                        name="certification"
                        label="I am the:"
                        className="fs-4 fw-normal"
                        rules={[
                        {
                            required: true,
                            message: 'Certification is required'
                        },
                        ]}
                    >
                        <Select
                        className={highlightFields13 ? 'highlight-class' : ''}
                        showSearch
                        onChange={onCertChange}
                        allowClear
                        optionFilterProp="children"
                            filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                        <Option value="homeowner">Homeowner</Option>
                        <Option value="dealeradmin">Dealer Admin</Option>
                        <Option value="SunPower Admin">SunPower Admin Team</Option>
                        </Select>
                    </Form.Item>
                    {
                        certification == 'SunPower Admin' ?
                        <Row>
                        <Col lg={6}>
                        <Form.Item
                                name="certFname"
                                label="Certifying Person First Name"
                                rules={[
                                {
                                    required: certification == 'SunPower Admin',
                                    message: 'Certifying Person First Name'
                                },
                                ]}
                            >
                                <Input />
                        </Form.Item>
                        </Col>
                        <Col lg={6}>
                        <Form.Item
                            name="certLname"
                            label="Certifying Person Last Name"
                            rules={[
                            {
                                required: certification == 'SunPower Admin',
                                message: 'Certifying Person Last Name'
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        </Col>
                        </Row>
                        : null
                    }
                </Collapsible>
            
            <Row>
                <Col className="p-4">
                    <p>Have questions about this rebate form? Check out the <a href="/faq"  target="_blank" className="blue-link">FAQs</a>, or <a href="/contactus"  target="_blank" className="blue-link">Contact Us</a> for assistance.</p>

                    <p><span className="fw-bold">Note:</span> This rebate cannot be combined with other SunPower offers.</p>

                    <p><span className="fw-bold">Eligibility Requirements:</span> Must own your home, have your system installed, and submit this Rebate Form according to Rebate Terms below.</p>

                    <p><span className="fw-bold">* Rebate Terms:</span> Before rebate costs will vary, depending on system specifications. Check with your SunPower installation contractor to confirm participation with this offer. Only available to customers who purchase a new, complete SunPower system, excluding customers who purchase a new home with existing solar installed. Rebate may not be applied to quotes on existing SunPower proposals or past purchases. Rebate form must be completed and submitted to SunPower within 90 days of the installation date, along with required documents. Allow 8-10 weeks for processing. Late submissions and those submitted without proper documentation and signatures will be subject to delay or cancellation. Void where prohibited.</p>

                    <p><span className="fw-bold">** Reward Card:</span> Card/Virtual card is issued by Pathward, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Card can be used everywhere Visa debit cards are accepted. Virtual card can be used everywhere Visa debit cards are accepted online, or phone/mail orders. Card/Virtual card valid for up to 6 months; unused funds will forfeit after the valid thru date. Go to <a href="https://bpscardaccount.com" target="_blank" className="blue-link">https://bpscardaccount.com</a> to activate your card and to check your card balance. For lost or stolen cards, please contact VISA customer support at 1-866-849-4838. Other Terms and conditions apply. </p>
                </Col>
            </Row>
                <Divider />
            <Row>
                <Col className="p-4">
                    <p>By clicking "SUBMIT," I consent to SunPower contacting me to provide information on my SunPower rebate. SunPower can call or text me (including SMS or MMS) (including via prerecorded messages and/or automated technology belonging to or hosted by third parties) at the telephone number I provided above even if it is on a state or Federal Do Not Call List. My consent is not a condition of purchase. Please see our <a href="https://global.sunpower.com/privacy-policy" target="_blank" className="blue-link">Privacy Statement</a> and <a href="https://global.sunpower.com/sunpower-terms-use-agreement" target="_blank" className="blue-link">Terms of Use</a>.</p>
                </Col>
            </Row>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" className="btn-orange mt-5" disabled={buttonDisable}>
                        {buttonLabel}
                        </Button>
                    </Form.Item>
                </Form>
                {modalVisible ? 
                    <PopupForm
                    visible={true}
                    onSaveResend={handleModalPopUp}
                    onCancel={onCancel}
                    fieldValues={modalFieldValues}
                    title={modalTitle}
                    okText={modalOkText}
                    token={token}
                    /> : null
                }
            </Col>
        </Row>
    </Container>
    </LoadingOverlay>
        }
       </> 
        
    );
}

export default StartRebateContent;