import React, {Fragment} from 'react';
import { Menu } from 'antd';
import 'antd/dist/antd.css';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { withRouter } from "react-router";
import './Navigation.css';

const { SubMenu } = Menu;

const NavigationLinks = (props) => {
    const current = null;
    const { location } = props;
    const handleClick = e => {
        console.log('click ', e);
    };

    return (
            <Navbar bg="white" expand="lg">
              <Container className="border-bottom">
                <Navbar.Brand href="/"><img src="images/SunPower.png" className="logo"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto" activeKey={location.pathname}>
                    <Nav.Link href="/qualifyingproducts" className="nav-links">Qualifying Products</Nav.Link>
                    <Nav.Link href="/faq" className="nav-links">FAQ</Nav.Link>
                    <Nav.Link href="/contactus" className="nav-links">Contact Us</Nav.Link>
                    <Nav.Link href="/myvisacard" className="nav-links">My Visa Card</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
    );
}

const Navigation = withRouter(NavigationLinks);
export default Navigation;