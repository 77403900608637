import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './Header.css';

const QPHeader = () => {

    return (

        <Container className="bg-gray pe-0 text-left">
            <Row className="align-items-center">
                <Col lg={12} xl={6} className="ps-lg-5 text-lg-center text-xl-start">
                    <img src="images/QPHeader-Mobile.png" className="img-fluid d-xs-block d-xl-none"></img>
                    <h2 className="text-white display-4">Qualifying Products</h2>
                    <p className="text-white fs-5">Offer valid on purchase of any qualifying SunPower® products</p>
                </Col>
                <Col lg={12} xl={6}>
                    <img src="images/QPHeader.png" className="img-fluid float-end d-none d-xl-block"></img>
                </Col>
            </Row>
        </Container>
    );
}

export default QPHeader;