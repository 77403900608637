import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Input, Button, Alert } from 'antd';
import 'antd/dist/antd.css';
import './CompleteRebateContent.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import queryString from "query-string"

const { Search } = Input;
  
const CompleteRebateContent = () => {
    const queryParams = queryString.parse(window.location.search);
    //console.log(queryParams.trackingNo);
    const trackingNo = (queryParams.trackingNo) ? queryParams.trackingNo : '';
    const history = useHistory();
    const [showError, setShowError] = useState(false);

    const onSearch = value => {
        //console.log(value);
        let data = {trackingNo: value};
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validateTrackingNo.php`,{data})
        .then(response => {
            console.log(response.data);
            if (response.data.status == 'valid') {
                //localStorage.setItem('rebateTrackingNo', parseInt(value));
                history.push({
                    pathname: '/startrebate',
                    state: { rebateTrackingNo: parseInt(value) }
                })
                //history.push("/startrebate");
            } else if (response.data.status == 'not found') {
                setShowError(true);
            } else {
                history.push({
                    pathname: '/trackrebate',
                    state: { rebateTrackingNo: parseInt(value) }
                })
            }
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    return (
        <Container className="py-5">
            <Row>
                <Col>
                    <h4 className="display-6">Complete a rebate request</h4>
                    <p className="mb-0">Enter your Rebate Status Tracking Number to finalize your rebate form.</p>
                    <p>If you do not know your Tracking Number or if your Tracking Number is not working, <a href="/contactus" className="blue-link">Contact Us</a> for assistance.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        showError ?
                        <Alert message="The Rebate Status Tracking Number you entered is invalid" type="error" />
                        : null
                    }
                </Col>
            </Row>
            <Row>
                <Col className="ant-col ant-col-lg-12 ant-col-lg-offset-6 py-5">
                    <Search
                        placeholder="Rebate Status Tracking Number"
                        allowClear
                        enterButton="Submit"
                        size="large"
                        className="btn-orange"
                        onSearch={onSearch}
                        defaultValue={trackingNo}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default CompleteRebateContent;