import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Form, Input, Button, Select, Alert } from 'antd';
import 'antd/dist/antd.css';
import './ContactUsContent.css';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import { css } from "@emotion/react";
import ClockLoader from 'react-spinners/ClockLoader';

const { Option } = Select;

const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      span: 16,
    },
  };

const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const DealerApproveEmailContent = () => {

    const [form] = Form.useForm();
    const [showMessage, setSHowMessage] = useState('');
    const [messageType, setMessageType] = useState('success');
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [spinnerActive2, setSpinnerActive2] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading your content...');

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let urlToken = params.get('token');
        setSpinnerActive(true);
        axios.get(process.env.REACT_APP_BACKEND_URL+'/emailrebateprocess.php?token='+urlToken)
        .then(response => {
          //console.log(response);
            if (response.data == "This rebate has already been processed and can't be processed again") {
                setMessageType("error");
            }
            setSHowMessage(response.data);
            setSpinnerActive(false);
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }, []);


    return (

        <LoadingOverlay
            active={spinnerActive}
            text={loadingText}
            spinner={<ClockLoader color="#ffffff" css={cssoverride} />}
          >
        <Container className="text-start py-5">
            <Row>
                <Col lg={12} className="pe-5">
                    {showMessage.length > 0 ? 
                    <Alert message={showMessage} type={messageType} />
                    : null}
                    
                </Col>
                
            </Row>
        </Container>
        </LoadingOverlay>
    );
}

export default DealerApproveEmailContent;;