import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import SelectRebateFormTypeContent from './SelectRebateFormTypeContent';
import Footer from './Footer';

const SelectRebateFormType = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <SelectRebateFormTypeContent />
            <Footer />
        </Fragment>
    );
}

export default SelectRebateFormType;