import React, {Fragment} from 'react';
import Navigation from './Navigation';
import DealerEmailHeader from './DealerEmailHeader';
import DealerDeclineEmailContent from './DealerDeclineEmailContent';
import Footer from './Footer';

const DealerDeclineEmail = () => {
    return (
        <Fragment>
            <Navigation />
            <DealerEmailHeader />
            <DealerDeclineEmailContent />
            <Footer />
        </Fragment>
    );
}

export default DealerDeclineEmail;