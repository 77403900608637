import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col, Modal } from 'react-bootstrap';
import { Button } from 'antd';
import './Content.css';  
  

const Content = () => {


    return (
        <Container className="py-5">
            <Row>
                <Col>
                    <h4 className="display-6">Let's get started. How can we help?</h4>
                </Col>
            </Row>
            <Row className="g-5">
                <Col xl={4} className="px-5 py-sm-5">
                    <h5>
                        Start a rebate request
                    </h5>
                    <p>
                        I do not have a Rebate Status Tracking Number. 
                        Start my rebate request now.
                    </p>
                <Button type="link" className="btn-orange" href="/selectrebateformtype">
                    Start Rebate
                </Button>
                    <div className="mt-5 d-block d-xl-none stacked-border mx-auto"></div>
                </Col>
                <Col xl={4} className="px-5 py-sm-5">
                    <h5>
                        Complete a rebate request <div class="blue-tooltip"><Button shape="circle" size="small" className="tooltip-icons">?</Button><span class="blue-tooltiptext">If you do not have your tracking number, or if your tracking number is not working, click here to <a href="/contactus" className="white-link">contact us</a>.</span></div>
                    </h5>
                    <p>
                        I have a Rebate Status Tracking Number.
                        Complete my rebate request now.
                    </p>
                <Button type="link" className="btn-orange" href="/completerebate">
                    Complete Rebate
                </Button>
                    <div className="mt-5 d-block d-xl-none stacked-border mx-auto"></div>
                </Col>
                <Col xl={4} className="px-5 py-sm-5">
                    <h5>
                        Track rebate status <div class="blue-tooltip"><Button shape="circle" size="small" className="tooltip-icons">?</Button><span class="blue-tooltiptext">Rebate Status Tracking Number is provided in the confirmation email you received for submitting your rebate.</span></div>
                    </h5>
                    <p>
                        I have a Rebate Status Tracking Number.
                        Check the status of my rebate.
                    </p>
                <Button type="link" className="btn-orange" href="/trackrebate">
                    Track Rebate
                </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Content;