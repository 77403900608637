import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import ConfirmRebateRegistrationContent from './ConfirmRebateRegistrationContent';
import Footer from './Footer';

const ConfirmRebateRegistration = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <ConfirmRebateRegistrationContent />
            <Footer />
        </Fragment>
    );
}

export default ConfirmRebateRegistration;