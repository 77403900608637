import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './QPContent.css';
  
const QPContent = () => {
    return (
        <Container className="py-5">
            <Row className="align-items-center">
                <Col lg={4} className="px-5 py-sm-5">
                    <img src="images/Solar-Products.png" className="img-fluid"></img>
                </Col>
                <Col lg={8} className="px-5 py-sm-5 text-start">
                    <h3 className="pb-2">
                        Purchased, Leased or Loaned Solar Panels
                    </h3>
                    <div className="divider-orange" />
                    <p className="pt-4 fs-5">
                        Any genuine SunPower residential solar system leased or purchased and installed through a participating SunPower certified dealer or SunPower Direct representative.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default QPContent;