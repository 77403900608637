import React, {Fragment} from 'react';
import Navigation from './Navigation';
import QPHeader from './QPHeader';
import QPContent from './QPContent';
import Footer from './Footer';

const QualifyingProducts = () => {
    return (
        <Fragment>
            <Navigation />
            <QPHeader />
            <QPContent />
            <Footer />
        </Fragment>
    );
}

export default QualifyingProducts;