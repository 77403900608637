import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import 'antd/dist/antd.css';
import './ErrorMessageContent.css';

  
const ErrorMessageContent = () => {


    return (
        <Container className="p-5 min-vh-40">
            <Row>
                <Col >
                    <Image src="/images/SunPower.png" className="logo float-start" />
                </Col>
            </Row>
            <Row className="p-md-5 min-vh-60">
                <Col>
                    <p className="mb-0">This request is taking longer than usual. Please check your email within the next 2 hours to recieve your virutal visa promo code.</p>
                    <p>We appologize for the inconvenience.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default ErrorMessageContent;