import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col, Table} from 'react-bootstrap';
import { Form, Input, Button, Select, Alert } from 'antd';
import 'antd/dist/antd.css';
import './TrackRebateContent.css';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import { css } from "@emotion/react";
import ClockLoader from 'react-spinners/ClockLoader';


const { Option } = Select;

const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      span: 16,
    },
  };

const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;
  
const DealerDeclineEmailContent = () => {

    const [form] = Form.useForm();
    const [showMessage, setSHowMessage] = useState(false);
    const [messageType, setMessageType] = useState('success');
    const [processedMessage, setProcessedMessage] = useState('');
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let urlToken = params.get('token');
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [spinnerActive2, setSpinnerActive2] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading your content...');

    const onFinish = (values) => {
        //console.log(values);
        /*let data = {
            reason:values['reason'],fullName:values['name'],email:values['email'],message:values['message']
        };*/
        setSpinnerActive(true);
        axios.get(process.env.REACT_APP_BACKEND_URL+'/emailrebateprocess.php?token='+urlToken+'&reason='+values['reason'])
        .then(response => {
            if (response.data == "This rebate has already been processed and can't be processed again") {
                setMessageType("error");
                setProcessedMessage(response.data);
            }
            setSHowMessage(response.data);
            setSpinnerActive(false);
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
        
    };

    useEffect(() => {
        setSpinnerActive(true);
        axios.get(process.env.REACT_APP_BACKEND_URL+'/emaildeclinerebateprocess.php?token='+urlToken)
        .then(response => {
            if (response.data == "This rebate has already been processed and can't be processed again") {
                setMessageType("error");
                setProcessedMessage(response.data);
            }
            setSHowMessage(response.data);
            setSpinnerActive(false);
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }, []);
    
    const onSubjectChange = (value) => {
        
    };

    return (
        <LoadingOverlay
            active={spinnerActive}
            text={loadingText}
            spinner={<ClockLoader color="#ffffff" css={cssoverride} />}
          >
        <Container className="text-start py-5">
            <Row>
                {
                    processedMessage.length > 0 ?
                    <Alert message={processedMessage} type="error" /> :
                    <Col lg={12} className="pe-5">
                    
                    {showMessage ? 
                    <Alert message={showMessage} type={messageType} />
                    : 
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} requiredMark={'optional'}>
                        <Form.Item
                            name="reason"
                            label="Decline Reason"
                            rules={[
                            {
                                required: true, message: 'Deline Reason is required'
                            },
                            ]}
                        >
                            <Select
                            placeholder=""
                            onChange={onSubjectChange}
                            allowClear
                            >
                                <Option value="System has not been installed">System has not been installed</Option>
                                <Option value="Address doesn't match customer records">Address doesn't match customer records</Option>
                                <Option value="Rebate was not agreed upon before contract signing">Rebate was not agreed upon before contract signing</Option>
                                <Option value='Please contact dealer for more information'>Please contact dealer for more information</Option>
                                <Option value='Incorrect Dealer Name'>Incorrect Dealer Name</Option>
                                <Option value='Duplicate rebate submission'>Duplicate rebate submission</Option>
                            
                            </Select>
                        </Form.Item>
                        
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" className="btn-orange">
                            Submit
                            </Button>
                        </Form.Item>
                    </Form>
                    }
                    
                </Col>
                }
                
            </Row>
        </Container>
        </LoadingOverlay>
    );
}

export default DealerDeclineEmailContent;