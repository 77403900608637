import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './FAQHeader.css';

const FAQHeader = () => {
    return (
        <Container className="bg-gray ps-0 text-left">
            <Row className="align-items-center">
                <Col lg={12} xl={6}>
                    <img src="images/FAQHeader-Mobile.png" className="img-fluid d-xs-block d-xl-none"></img>
                    <img src="images/FAQHeader.png" className="img-fluid float-start d-none d-xl-block ps-0"></img>
                </Col>
                <Col lg={12} xl={6} className="py-5 pt-xl-4 pb-xl-0 ps-xs-2">
                    <h2 className="text-lg-center text-xl-start text-white display-5">Frequently Asked Questions</h2>
                </Col>
            </Row>
        </Container>
    );
}

export default FAQHeader;