import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Contin, Row, Col} from 'react-bootstrap';
import './Footer.css';

const Footer = () => {
    return (
        <Container fluid className="footer py-5">
            
            <p>
            SunPower Visa® Prepaid card is issued by Pathward® , N.A., Member FDIC, pursuant to a license from Visa ® U.S.A. Inc. Card can be used everywhere Visa debit cards are accepted.
            </p>
            <p>&copy;{(new Date().getFullYear())} SunPower Corporation. All Rights Reserved.</p>

            <a href="https://us.sunpower.com/privacy" target="_blank" class="btn btn-link text-white"><font color="white">PRIVACY POLICY</font></a>
            |<a href="https://us.sunpower.com/terms" target="_blank" class="btn btn-link text-white"><font color="white">TERMS</font></a>
            
        </Container>
    );
}

export default Footer;