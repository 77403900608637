import React, {Fragment, useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Select, Alert, DatePicker, AutoComplete, Spin, Divider, Radio } from 'antd';
import 'antd/dist/antd.css';
import './RebateRegistrationContent.css';
import { BsCaretDownFill } from "react-icons/bs";
import {Icon, InboxOutlined} from '@ant-design/icons';
import axios from 'axios';
import PopupForm from './PopupForm';
import LoadingOverlay from 'react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader';
import { useHistory } from 'react-router-dom';
import { css } from "@emotion/react";
import ConfirmRebateRegistrationContent from './ConfirmRebateRegistrationContent';
import Collapsible from 'react-collapsible';

const { Option } = Select;

const layout = {
    labelCol: {
        span: 24,
        },
        wrapperCol: {
        span: 24,
        },
    };

  const tailLayout = {
    wrapperCol: {
      span: 16,
      offset: 11,
    },
  };

const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;
  
const ReferralPageConfirmContent = ({formValues, onFinalSubmit, handleEdit, showEmployeeId, myRef}) => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [token, setToken] = useState('');
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
    const [dealerCode, setDealerCode] = useState('');
    
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmPage, setShowConfirmPage] = useState(false);
    const [addressOptions, setAddressOptions] = useState([]);
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [spinnerActive2, setSpinnerActive2] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading your content...');
    const [loadingText2, setLoadingText2] = useState('Submitting your Rebate code details...');
    const [cardType, setCardType] = useState('');
    
    const [rebateSubmitted, setRebateSubmitted] = useState(false);
    
    
    useEffect(() => {
        for (const key in formValues) {
            form.setFieldsValue({
                [key]: formValues[key],
            });
        }
        if (token.length == 0) {
            axios.get(process.env.REACT_APP_BACKEND_URL+'/createJWTToken.php')
            .then(res => {
                //console.log(res.data);
                setToken(res.data);
            })
        }
        
    }, []);

    
    const handleRebateEdit = () => {
        setShowConfirmPage(false);
    }

    const handleRebateSubmission = () => {
        if (!rebateSubmitted) {
            setSpinnerActive2(true);
            setLoadingText2('Submitting your Rebate code details...');
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/rebatereservationsave.php`,{formValues}, {
                    headers: {
                        "Authorization" : `Bearer ${token}`
                    }})
                .then(response => {
                    setRebateSubmitted(true);
                    //console.log(response.data);
                    setSpinnerActive2(false);
                    setLoadingText2('');
                    history.push("/rebateregistrationsuccess");
                })
                .catch(error => {
                    console.log(error);
                    //alert(JSON.stringify(error));
                })
        }
    }

    const onSelectAddress = (val, option) => {
        //console.log(val)
        //console.log(option);
        const addressParts = val.split(",");
        form.setFieldsValue({
            address1: addressParts[0],
        });
        form.setFieldsValue({
            city: addressParts[1],
        });
        form.setFieldsValue({
            state: addressParts[2],
        });
        form.setFieldsValue({
            zip: addressParts[3],
        });
        
    }

    const onSearchAddress = val => {
        if (val && val.length > 2) {
            setAutoCompleteLoading(true);
            axios.get(process.env.REACT_APP_BACKEND_URL+"/api/addressautocomplete/"+encodeURI(val), {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                }})
            .then(response => {
                setAutoCompleteLoading(false);
                let dataSource = response.data;
                console.log(dataSource);
                let filtered = dataSource.filter(
                    obj =>
                      obj.value
                        .toString()
                        .toLowerCase()
                        .includes(val.toLowerCase())
                  );
                setAddressOptions(filtered);
                //console.log(response);
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        setToken('');
                    }
                  }
            });
        }
    }

    const addressPasteHandle = (val, type) => {
        //console.log(val);
        //console.log(typeof val);
        if (typeof val == 'string') {
            onSearchAddress(val);
        }
    }

    const validateSunpowerEmail = (rule, value, callback) => {
        let row = {};
        row['promocode'] = form.getFieldValue('promoCode');
        row['email'] = value;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/validateSunpowerEmail.php`,{row}, {
            headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            console.log(response.data);
            if (!response.data) {
                console.log('error');
                callback('The email needs to end in @sunpower.com');
                //return Promise.reject('The email needs to end in @sunpower.com');
            }
            return callback();
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
    }

    return (
        <>
        <Container className="text-start py-5" ref={myRef}>
            <Row>
                <Col className="text-center">
                    <h4 className="display-6">Confirm and submit</h4>
                    <p>Review the form and submit your rebate.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form {...layout} form={form} name="control-hooks" onFinish={onFinalSubmit} requiredMark={'optional'}>
                        <h5>Referral Confirmation Form</h5>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="fname"
                                    label="First Name"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="lname"
                                    label="Last Name"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="address1"
                                    label="Address 1"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="address2"
                                    label="Address 2"
                                    rules={[
                                    {
                                        required: false,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Select
                                    showSearch
                                    allowClear
                                    disabled
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        <Option value="AL">Alabama</Option>
                                        <Option value="AK">Alaska</Option>
                                        <Option value="AZ">Arizona</Option>
                                        <Option value="AR">Arkansas</Option>
                                        <Option value="CA">California</Option>
                                        <Option value="CO">Colorado</Option>
                                        <Option value="CT">Connecticut</Option>
                                        <Option value="DE">Delaware</Option>
                                        <Option value="DC">District of Columbia</Option>
                                        <Option value="FL">Florida</Option>
                                        <Option value="GA">Georgia</Option>
                                        <Option value="HI">Hawaii</Option>
                                        <Option value="ID">Idaho</Option>
                                        <Option value="IL">Illinois</Option>
                                        <Option value="IN">Indiana</Option>
                                        <Option value="IA">Iowa</Option>
                                        <Option value="KS">Kansas</Option>
                                        <Option value="KY">Kentucky</Option>
                                        <Option value="LA">Louisiana</Option>
                                        <Option value="ME">Maine</Option>
                                        <Option value="MD">Maryland</Option>
                                        <Option value="MA">Massachusetts</Option>
                                        <Option value="MI">Michigan</Option>
                                        <Option value="MN">Minnesota</Option>
                                        <Option value="MS">Mississippi</Option>
                                        <Option value="MO">Missouri</Option>
                                        <Option value="MT">Montana</Option>
                                        <Option value="NE">Nebaraska</Option>
                                        <Option value="NV">Nevada</Option>
                                        <Option value="NH">New Hampshire</Option>
                                        <Option value="NJ">New Jersey</Option>
                                        <Option value="NM">New Mexico</Option>
                                        <Option value="NY">New York</Option>
                                        <Option value="NC">North Carolina</Option>
                                        <Option value="ND">North Dakota</Option>
                                        <Option value="OH">Ohio</Option>
                                        <Option value="OK">Oklahoma</Option>
                                        <Option value="OR">Oregon</Option>
                                        <Option value="PA">Pennsylvania</Option>
                                        <Option value="RI">Rhode Island</Option>
                                        <Option value="SC">South Carolina</Option>
                                        <Option value="SD">South Dakota</Option>
                                        <Option value="TN">Tennessee</Option>
                                        <Option value="TX">Texas</Option>
                                        <Option value="UT">Utah</Option>
                                        <Option value="VT">Vermont</Option>
                                        <Option value="VA">Virginia</Option>
                                        <Option value="WA">Washington</Option>
                                        <Option value="WV">West Virginia</Option>
                                        <Option value="WI">Wisconsin</Option>
                                        <Option value="WY">Wyoming</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={3}>
                                <Form.Item
                                    name="zip"
                                    label="Zip"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                    {
                                        required: true, type: 'email'
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                {showEmployeeId && (
                                <Form.Item
                                    name="employeeId"
                                    label='Sunpower Employee ID'
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                    >
                                        <Input disabled />
                                </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <Divider />
                        <h5>Select your Payment Method</h5>
                        <Form.Item name="reward" label=""
                            rules={[
                                {
                                    required: true,
                                },
                                ]}>
                            <Radio.Group
                                size="large"
                                buttonStyle="solid"
                                className="ant-row ant-row-space-between"
                            >
                                {formValues.reward == 'visareward' ? 
                                    <Radio.Button value="visareward" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4" disabled>
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Visa Rewards Card**</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive a physical card by mail
                                                        </li>
                                                    </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                <img src="/images/SP-CardRedesignG.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button>  
                                    
                                : 
                                    null
                                }
                                {formValues.reward == 'virtualvisa' ?
                                    <Radio.Button value="Virtual" className="text-black reward-card-confirm ant-col ant-col-lg-11 ant-col-sm-24 p-4" disabled>
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Virtual Visa Rewards Card**</h5>
                                                    <ul className="lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive e-code by email
                                                        </li>
                                                        <li>
                                                            For online purchases only
                                                        </li>
                                                    </ul>
                                            </span>
                                            <span className="ant-col ant-col-6">
                                                    <img src="/images/SP-CardRedesignB.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button>
                                    : null
                                }
                                {formValues.reward == 'check' ?
                                    <Radio.Button value="check" className="text-black reward-card ant-col ant-col-lg-11 ant-col-sm-24 p-4">
                                        <span className="ant-row ant-row-space-between">
                                            <span className="ant-col ant-col-17">
                                                <h5>SunPower Check</h5>
                                                <ul className="pb-4 lh-base fw-light mb-0 list-unstyled">
                                                        <li>
                                                            Receive a physical check by mail
                                                        </li>
                                                    </ul>
                                                    </span>
                                                    <span className="ant-col ant-col-6">
                                                <img src="/images/SP-Check.png" className="img-fluid" />
                                            </span>
                                        </span>
                                    </Radio.Button> 
                                    : null
                                }
                                
                                      
                            </Radio.Group>
                        </Form.Item>
                        <Divider />
                    
                        <Form.Item {...tailLayout}>
                            <Button className="me-4" onClick={handleEdit}>Edit</Button>
                            <Button type="primary" htmlType="submit" className="btn-orange mt-5">
                            Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default ReferralPageConfirmContent;