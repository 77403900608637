import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Input, Button, Alert } from 'antd';
import 'antd/dist/antd.css';
import './CompleteRebateContent.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import ClockLoader from 'react-spinners/ClockLoader';
import { css } from "@emotion/react";
  
const cssoverride = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const FoundationCheckContent = () => {
    const [message, setMessage] = useState(false);
    const history = useHistory();
    const [spinnerActive2, setSpinnerActive2] = useState(false);
    const [loadingText2, setLoadingText2] = useState('Submitting your request for donation to Sunpower Foundation...');
    const [redeemed, setRedeemed] = useState(false);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let urlToken = params.get('token');
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/foundationcheck.php`,{urlToken})
        .then(response => {
            setSpinnerActive2(false);
            setLoadingText2('');
            if (response.data) {
                setMessage(true);
            } else {
                setRedeemed(true);
            }
        })
        .catch(error => {
            console.log(error);
            //alert(JSON.stringify(error));
        })
        
    }, []);
    
    return (
        <LoadingOverlay
            active={spinnerActive2}
            text={loadingText2}
            spinner={<ClockLoader color="#ffffff" css={cssoverride} />}
          >
              {redeemed ? 
                <Container className="py-5">
                    <Row>
                        <Col>
                            <h5 className="display-6">This referral has already been redeemed.</h5>
                        </Col>
                    </Row>
                
                 </Container>
              :
        <Container className="py-5">
            <Row>
                <Col>
                    {message ? <h5 className="display-6">Your request for check to the Foundation has been submitted!</h5> : null}
                    
                </Col>
            </Row>
            
        </Container>
              }
        </LoadingOverlay>
    );
}

export default FoundationCheckContent;