import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import MyVisaCardContent from './MyVisaCardContent';
import MyVisaCardHeader from './MyVisaCardHeader';
import Footer from './Footer';

const MyVisaCard = () => {
    return (
        <Fragment>
            <Navigation />
            <MyVisaCardHeader />
            <MyVisaCardContent />
            <Footer />
        </Fragment>
    );
}

export default MyVisaCard;