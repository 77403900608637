import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import RebateSuccessContent from './RebateSuccessContent';
import Footer from './Footer';

const RebateSuccess = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <RebateSuccessContent />
            <Footer />
        </Fragment>
    );
}

export default RebateSuccess;