import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router-dom';
import { Alert } from 'antd';

  
const RebateSuccessContent = () => {
    const history = useHistory();   
    const [showMsg, setSHowMsg] = useState(false);
    useEffect(() => {
        console.log(history.location.state);
        if (history.location.state && history.location.state.referralMsg) {
            if (history.location.state.referralMsg.length > 0) {
                setSHowMsg(true);
            }
        }
        
    }, []);

    return (
        <Container className="p-5">
            <Row className="p-5">
                <Col className="px-5">
                    <h4 className="display-6">Your rebate has been submitted!</h4>
                    {showMsg ? 
                    <Alert message="Your rebate will be processed however the referral payment will not be processed as the employee has reached their maximum limit for this year." type="info" />
                    : null}
                    <p className="mb-0">Enter your Rebate Status Tracking Number to see the status of your rebate.</p>
                    <p>You will receive a confirmation email confirming your rebate submission and a <strong>Tracking Number</strong> will be provided. Please do not delete this email, you will need the tracking number to track your rebate.</p>
                    <p>If you selected a physical reward card, you will receive an address verification email to confirm your mailing address upon final approval from SunPower. The card will not be shipped until your mailing address has been confirmed.</p>
                    <p>You can track the status of your rebate by entering your Tracking Number on the <a href="/trackrebate" className="blue-link">Track Rebate</a> page.</p>
                </Col>
            </Row>
            <Row className="bg-light p-5">
                <Col className="px-5">
                    <h4>Important Notice</h4>
                    <p>When you receive your VISA Reward Card, use the website below to activate your card and to check your balance. There are many fraudulent sites on the Internet. This is the only website available to activate your card and check your balance. If you have questions, please <a href="/contactus" className="blue-link">Contact Us</a>.
                    </p>
                    <a href="https://bpsmyaccount.com" className="blue-link fs-4 fw-4">BPSmyaccount.com</a>
                </Col>
            </Row>
        </Container>
    );
}

export default RebateSuccessContent;