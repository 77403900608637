import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./Components/Home";
import QualifyingProducts from './Components/QualifyingProducts';
import FAQ from './Components/FAQ';
import ContactUs from './Components/ContactUs';
import StartRebate from './Components/StartRebate';
import CompleteRebate from './Components/CompleteRebate';
import TrackRebate from './Components/TrackRebate';
import RebateRegistration from './Components/RebateRegistration';
import ConfirmRebate from './Components/ConfirmRebate';
import RebateSuccess from './Components/RebateSuccess';
import ConfirmRebateRegistration from './Components/ConfirmRebateRegistration';
import RebateRegistrationSuccess from './Components/RebateRegistrationSuccess';
import ErrorMessage from './Components/ErrorMessage';
import SelectRebateFormType from './Components/SelectRebateFormType';
import MyVisaCard from './Components/MyVisaCard';
import ReferralPage from './Components/ReferralPage';
import ReferralPageSuccess from './Components/ReferralPageSuccess';
import FoundationCheck from './Components/FoundationCheck';
import DealerApproveEmail from './Components/DealerApproveEmail';
import DealerDeclineEmail from './Components/DealerDeclineEmail';
import RedeemVirtualCard from './Components/RedeemVirtualCard';

function App() {
  useEffect(() => {
    window.location.href = "https://us.sunpower.com/";
  }, []);

  return (
    <div className="App">
      <Router>
        <Route exact path = "/" component = {Home} />
        <Route exact path = "/qualifyingproducts" component = {QualifyingProducts} />
        <Route exact path = "/faq" component = {FAQ} />
        <Route exact path = "/contactus" component = {ContactUs} />
        <Route exact path = "/startrebate" component = {StartRebate} />
        <Route exact path = "/completerebate" component = {CompleteRebate} />
        <Route exact path = "/trackrebate" component = {TrackRebate} />
        <Route exact path = "/rebateregistration" component = {RebateRegistration} />
        <Route exact path = "/confirmrebate" component = {ConfirmRebate} />
        <Route exact path = "/rebatesuccess" component = {RebateSuccess} />
        <Route exact path = "/confirmrebateregistration" component = {ConfirmRebateRegistration} />
        <Route exact path = "/rebateregistrationsuccess" component = {RebateRegistrationSuccess} />
        <Route exact path = "/errormessage" component = {ErrorMessage} />
        <Route exact path = "/selectrebateformtype" component = {SelectRebateFormType} />
        <Route exact path = "/myvisacard" component = {MyVisaCard} />
        <Route exact path = "/referralpage" component = {ReferralPage} />
        <Route exact path = "/referralpagesuccess" component = {ReferralPageSuccess} />
        <Route exact path = "/foundationcheck" component = {FoundationCheck} />
        <Route exact path = "/approverebate" component = {DealerApproveEmail} />
        <Route exact path = "/declinerebate" component = {DealerDeclineEmail} />
        <Route exact path = "/redeemvirtualcard" component = {RedeemVirtualCard} />
      </Router>
    </div>
  );
}

export default App;
