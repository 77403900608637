import Column from 'rc-table/lib/sugar/Column';
import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './Header.css';

const Header = () => {
    return (
        <Container className="bg-blue pe-0 ps-4 text-left">
            <Row className="align-items-center">
                <Col lg={12} xl={6} className="ps-lg-5 pt-xl-1">
                    <img src="images/Home-Mobile.png" className="img-fluid d-xs-block d-xl-none"></img>
                    <h4 className="text-lg-center text-xl-start text-white text-uppercase fs-5">Visa Reward Card</h4>
                    <h2 className="text-lg-center text-xl-start text-white display-4">Submit Your Rebate</h2>
                    <p className="text-lg-center text-xl-start text-white fs-5">Offer valid on purchase of any qualifying SunPower® products</p>
                </Col>
                <Col lg={12} xl={6}>
                    <img src="images/VisaCard.png" className="img-fluid d-none d-xl-block visa-card"></img>
                    <img src="images/Home.png" className="img-fluid float-end d-none d-xl-block"></img>
                </Col>
            </Row>
        </Container>
    );
}

export default Header;