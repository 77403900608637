import React, {Fragment} from 'react';
import Navigation from './Navigation';
import FAQHeader from './FAQHeader';
import FAQContent from './FAQContent';
import Footer from './Footer';

const FAQ = () => {
    return (
        <Fragment>
            <Navigation />
            <FAQHeader />
            <FAQContent />
            <Footer />
        </Fragment>
    );
}

export default FAQ;