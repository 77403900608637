import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Image } from 'antd';
import 'antd/dist/antd.css';
    

const MyVisaCardContent = () => {
    return (

        <Container className="py-5">
            <Row>
                <Col>
                    <h4 className="display-6">Please check the url on the back of your card</h4>
                    <p className="mb-0">Follow the url on the back of your card to activate or check the balance of your Visa card.</p>
                </Col>
            </Row>
            <Row className="pt-5 justify-content-evenly text-center">
                <Col sm={4}>
                    <Image src="images/Card Balance - BPSmyaccount.png" alt="Card sample" width="220px" />
                    <a href="https://bpsmyaccount.com" className="blue-link fs-4 fw-4 d-block">BPSmyaccount.com</a>
                </Col>
                <Col sm={4}>
                    <Image src="images/bpscardaccount.png" alt="Card sample" width="220px" />
                    <a href="https://bpscardaccount.com" className="blue-link fs-4 fw-4 d-block">BPScardaccount.com</a>
                </Col>
            </Row>
        </Container>
    );
}

export default MyVisaCardContent;