import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import ConfirmRebateContent from './ConfirmRebateContent';
import Footer from './Footer';

const StartRebate = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <ConfirmRebateContent />
            <Footer />
        </Fragment>
    );
}

export default StartRebate;