import React, {Fragment} from 'react';
import Navigation from './Navigation';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';

const Home = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <Content />
            <Footer />
        </Fragment>
    );
}

export default Home;